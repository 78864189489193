import React from  'react';
import { Typography, Grid, Card} from '@mui/material';
import { styled } from '@mui/system';



export default function Opportunities(){
    
   
      const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
    return(
        <Card width='80%' >
          <Grid container padding='2% 4%'>
             <Grid item xs={6} lg={6} marginBottom='2%'>
                <Typography fontFamily='poppins' variant='h5'>Opportunities</Typography>
             </Grid>
             <Grid item xs={6} lg={6}>
             <div>
           
     
           </div>

             </Grid>
             <Grid item xs={12} lg={10}>
               <Typography fontFamily='poppins' color='#a5a5a5'>This section will be visible only to the recruiters</Typography>
             </Grid>
          </Grid>
        
        </Card>

    )
}




 
  
//   export  function UnstyledSwitches() {
//     const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
  
//     return (
//         <div>
//         <SwitchUnstyled component={Root} {...label} defaultChecked />

//       </div>
//     );
//   }