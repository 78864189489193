import React from "react";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram'; 
import TwitterIcon from '@mui/icons-material/Twitter'; 
import YouTubeIcon from '@mui/icons-material/YouTube';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { Box, Button, Card, Grid, Input, Link, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Frame3 from "./Frame3.png"




const Footer = () => {
  return (
    
        <Grid container sx={{ padding:"20px",marginTop:'20px',backgroundColor:"#202430",display:'flex', boxShadow: "none",width:'100%',textAlign:'left', zIndex: 1000}} >
            <Grid item xs={8} sm={3} sx={{marginTop:'20px'}}>
                   <Box ><img src={Frame3}  style={{width:'100px',height:'100px' }}alt="ewrt"/></Box>
               <Grid container sx={{marginTop:'15px'}}>
                    <Typography variant="h4" sx={{color:'#fff'}}><b>Crestbell Online</b></Typography></Grid>
                <Typography sx={{color:'#D6DDEB',marginTop:'15px'}}>Great platform for the job seeker that passionate about startups. Find your dream job easier.</Typography>
            </Grid>
            <Grid item xs={1.5}/>
         <Grid item xs={6} sm={2} className="abc" sx={{marginTop:'30px'}}>
          
          
          <Typography variant="h5" className="abc" sx={{color:'white',marginTop:'15px'}}><b>Quick Links</b></Typography>
          <Link  style={{textDecoration:'none'}}> <Typography className="abc" sx={{"&:hover":{color: '#26a4ff'},color:'#D6DDEB',marginTop:'15px',fontSize:'14px'}}><i>About</i></Typography></Link>
          <Link  style={{textDecoration:'none'}}> <Typography className="abc" sx={{"&:hover":{color: '#26a4ff'},color:'#D6DDEB',marginTop:'15px',fontSize:'14px'}}><i>Contact</i></Typography></Link>
          <Link  style={{textDecoration:'none'}}> <Typography className="abc" sx={{"&:hover":{color: '#26a4ff'},color:'#D6DDEB',marginTop:'15px',fontSize:'14px'}}><i>Terms and Condition</i></Typography></Link>
          <Link  style={{textDecoration:'none'}}> <Typography className="abc" sx={{"&:hover":{color: '#26a4ff'},color:'#D6DDEB',marginTop:'15px',fontSize:'14px'}}><i>Privacy Policy</i></Typography></Link>
          <Link  style={{textDecoration:'none'}}> <Typography className="abc" sx={{"&:hover":{color: '#26a4ff'},color:'#D6DDEB',marginTop:'15px',fontSize:'14px'}}><i>Refund Policy</i></Typography></Link>
  
        </Grid>
        
          
          <Grid item xs={6} sm={2} sx={{marginTop:'30px'}}>
            
           
            <Typography variant="h5" className="abc" sx={{color:'white',marginTop:'15px'}}><b>Other Links</b></Typography>
           <Link  style={{textDecoration:'none'}}>
              <Typography className="abc" sx={{"&:hover":{color: '#26a4ff'},color:'#D6DDEB',marginTop:'15px',fontSize:'14px'}}><i>Incubation</i></Typography> 
              </Link>
           <Link  style={{textDecoration:'none'}}> <Typography className="abc" sx={{"&:hover":{color: '#26a4ff'},color:'#D6DDEB',marginTop:'15px',fontSize:'14px'}}><i>Job Portal</i></Typography></Link>
            <Link  style={{textDecoration:'none'}}> <Typography className="abc" sx={{"&:hover":{color: '#26a4ff'},color:'#D6DDEB',marginTop:'15px',fontSize:'14px'}}><i>Updates</i></Typography></Link>
            <Link  style={{textDecoration:'none'}}> <Typography className="abc" sx={{"&:hover":{color: '#26a4ff'},color:'#D6DDEB',marginTop:'15px',fontSize:'14px'}}><i>Synergy</i></Typography></Link>
           
          
          </Grid>
          
          
          
          <Grid item xs={8} sm={3} sx={{textAlign:'left',marginTop:'30px'}}>
            
            <Typography variant="h5" sx={{color:'#fff',marginTop:'15px'}} ><b>	Get job Notification</b></Typography>
           
            <Typography sx={{color:'#D6DDEB',fontSize:'14px',marginTop:'15px',maxWidth:'250px'}}>Crestbell,Bathinda Online Certification Courses In Emerging Technologies.</Typography>

            <Grid sx={{display:'flex',marginTop:"25px"}}>
            
            <Input sx={{background:'#fff',color:'black'}} id="name" ></Input>
            <Button variant='contained' sx={{background:'#1A50B2',marginLeft:"10px",minWidth:'93px'}}>Subscribe</Button></Grid>
          </Grid>
          
          <Grid container sx={{diplay:'flex',marginTop:'40px'}}>
             <Grid xs={6} item> <Typography sx={{color:'#D6DDEB',fontSize:'12px',fontWeight:'500',textAlign:'left',marginBottom:'20px'}}>
             © Copyright Crestbell Online 2022, Powered by KEEWE | Technology Partner | Crestbell Support Pvt. Ltd.
              
              </Typography></Grid>
              <Grid xs={4.5} item sx={{marginBottom:'10px',textAlign:'right'}}>
            <Link  >
             
              <MailIcon sx={{"&:hover":{color: 'red'},fontSize:"20px" , color:"white"}}/>
                
              
            </Link>
            <Link   >
             
                <TwitterIcon sx={{"&:hover":{color: '#26a4ff'},fontSize:"20px",color:"white",marginLeft:'20px'}}/>
              
            </Link>
            <Link   >
              
               <YouTubeIcon sx={{"&:hover":{color: 'red'},fontSize:"20px" , color:"white",marginLeft:'20px'}}/>
              
            </Link>
            <Link  >
              
               <FacebookIcon sx={{"&:hover":{color: '#26a4ff'},fontSize:"20px" , color:"white",marginLeft:'20px'}}/>
              
            </Link>
            <Link  >
              
               <InstagramIcon sx={{"&:hover":{color: 'red'},fontSize:"20px" , color:"white",marginLeft:'20px'}}/>
             
            </Link> 
            </Grid>
              </Grid>
        </Grid>
    
  );
};
export default Footer;