import React, { createContext, useEffect, useState } from 'react';
import { Timer, WebLiveCapture } from './../../components';
import './exam.css';
import Mcq from './Mcq';
import axios from 'axios';
import Light from './light';

export const AssesmentContext = React.createContext({
	questions: [],
	responses: {},
	status: {},
	marked: {},
	setNewContext: () => { },
})
const Exam = ({
	userObj, courseId,
	examName = 'Crestbell - Final Assesment Test',
	duration = 60
}) => {
	function check() {
		if (window.innerHeight != window.screen.height) {
			setShowMessage(
				'Please go to full screen mode.'
			);
			disableForm(document.getElementById('overlay'), document.getElementById('form-blur'));
		}
		else {
			enableForm(document.getElementById('overlay'), document.getElementById('form-blur'));
		}
	}

	async function disableForm(overlay, formBlur) {
		if (overlay != null) {
			overlay.classList.remove('hide');
			overlay.classList.add('disable');
		}
		if (formBlur != null) {
			formBlur.classList.add('blur');
		}

	}

	async function enableForm(overlay, formBlur) {
		if (overlay != null) {
			overlay.classList.add('hide');
			overlay.classList.remove('disable');
		}
		if (formBlur != null) {
			formBlur.classList.remove('blur');
		}
	}


	const [showMessage, setShowMessage] = useState('');
	const [isDevToolsOpen, setIsDevToolsOpen] = useState(false);

	useEffect(() => {
		const handleContextmenu = e => {
			e.preventDefault()
		}
		document.addEventListener('contextmenu', handleContextmenu)
		return function cleanup() {
			document.removeEventListener('contextmenu', handleContextmenu)
		}
	}, [])

	// Full screen check
	// setInterval(check, 2000);

	const [assesmentDetails, setAssesmentDetails] = useState({
		questions: [],
		responses: {},
		status: {},
		marked:{},
		score: 0
	});

	useEffect(() => {
		axios({
			url: 'https://cms.keewesolutions.com/assessment',
			method: "POST",
			data: {
				userId: userObj.user.id,
				courseId: courseId
			}
		}).then(res => {
			let newAssesmentDetails = { ...assesmentDetails }
			newAssesmentDetails.questions = res.data.response.questions.questions
			setAssesmentDetails(newAssesmentDetails)
		}).catch(err => console.log(err))
	},[])

	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [value, setValue] = useState('');
	const [isChecked, setChecked] = useState(false)

	const handleNext = (e) => {
		let newAssesment = { ...assesmentDetails }
		let currentQuestionobject = newAssesment.questions[currentQuestionIndex]
		let id = currentQuestionobject.id
		newAssesment.responses[id] = value
		if(isChecked){newAssesment.marked[id] = true}
		else{newAssesment.marked[id] = false}
	
		if (value && currentQuestionobject["CORRECT OPTION"] == value) {
			newAssesment.status[id] = 1
		} else if (!value) {
			newAssesment.status[id] = 0
		} else {
			newAssesment.status[id] = -1
		}
		setAssesmentDetails(newAssesment)
		setValue("")
		if(currentQuestionIndex!=newAssesment.questions.length-1){
			setCurrentQuestionIndex(currentQuestionIndex + 1)
		}
		setChecked(false)
	} 
	

	const handlePrevious = (e) => {
		let newAssesment = { ...assesmentDetails }
		let currentQuestionobject = newAssesment.questions[currentQuestionIndex]
		let id = currentQuestionobject.id
		newAssesment.responses[id] = value

		if (value && currentQuestionobject["CORRECT OPTION"] == value) {
			newAssesment.status[id] = 1
		} else if (!value) {
			newAssesment.status[id] = 0
		} else {
			newAssesment.status[id] = -1
		}
	
		setAssesmentDetails(newAssesment)
		setValue("")
		setCurrentQuestionIndex(currentQuestionIndex - 1)
	}

	return (
		<div className="exam-container" id="exam">
			<AssesmentContext.Provider value={{
				questions: assesmentDetails.questions,
				responses: assesmentDetails.responses,
				status: assesmentDetails.status,
				score: assesmentDetails.score,
				marked:assesmentDetails.marked,
				setNewContext: setAssesmentDetails,
				currentQuestionIndex,
				handleNext, handlePrevious,
				value, setValue,
				isChecked, setChecked


			}}>
				<div className="left-column">

					<div className="image-capture">
						<WebLiveCapture />
					</div>
					<div className="exam-details">
						<h3 className="title-heading">Student Details</h3>

						<div className="details">
							<h4 className="student-id">Name: {userObj.user.name}</h4>
							<h4 className="student-email">
								Email: {userObj.user.email}
							</h4>
						</div>
					</div>
				</div>

				<div className="embedded-form">
					{/* add disable to class, remove hide */}
					<div className="hide" id="overlay">
						<h2>Message: {showMessage}</h2>
						<h1>Exam Paused</h1>
						<h3>Please contact your organization/admin.</h3>
						{window.innerHeight != window.screen.height ? <button onClick={() => {
							document.body.requestFullscreen();
						}}>Go Full Screen</button> : ""}

					</div>

					{/* add blur to class */}
					<div className="form" id="form-blur">
						<h2 className="title-heading">{examName}</h2>

						<Mcq userId={userObj.user.id} courseId={courseId} />

						<div className="responsive-message">
							<h1>
								Please join via a Laptop/PC for best performance
							</h1>
						</div>
					</div>
				</div>

				<div className="timer">
					<div >
						<Timer initialMinute={duration} />
					</div>
					<div>
						<Light setCurrentIndex={setCurrentQuestionIndex} />
					</div>
				</div>
			</AssesmentContext.Provider>
		</div>
	);
};

export default Exam;
