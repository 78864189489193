import { React, useContext, useState } from 'react';
import Card from '@mui/material/Card';
import { Container, Typography, Grid, Divider } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Light from './light';
import axios from 'axios';
import { AssesmentContext } from './Exam';
import { useNavigate } from 'react-router-dom';

export default function Mcq({ userId,courseId }) {
	const navigate=useNavigate();
	const {currentQuestionIndex,questions,
		responses,status,score,setNewContext,handleNext,handlePrevious,
		isChecked, setChecked,value, setValue,
	} = useContext(AssesmentContext);
	const handleChange = (event) => {
		setValue(event.target.value);

	};
	const FinalSubmit=async ()=>{
	      
		if(confirm("Are you sure you want to submit your assessment.")){
			handleNext()
			let count =0
			for (let i in status){
				if(status[i]=="1"){
					count+=1
				}
			}
			await axios({
			url:"https://web.deshbhagatonline.com/FinalAssesment",
			method:"POST",
			data:{
				userId,
				courseId,
				questions,
				responses,
				score:count,
				status,
				TimeStamp:Date.now(),
				Approve:0,
				Url:""
			}
		}).then(res=>{
			alert("Exam Submitted Successfully")
			window.location.replace("https://lms.Crestbellsupport.com/");
		})}
	}
	return (
		<>
			{
				questions.length != 0 ?
					(
						<div >
							{/* <Grid xs={4} style={{ borderRight: '1.5px solid #b3b3b3', paddingRight: '0.8%' }}>
								<Light setCurrentIndex={setCurrentQuestionIndex} />
							</Grid> */}
							<Grid xs={8} sx={{ margin: '5%', boxShadow: '5px 7px 12px #888888', padding: '5%', width: '100%',height:"80vh", borderRadius: '15px' }}>
								<FormControl sx={{ m: 0 }} variant="standard">
									<FormLabel
										sx={{
											fontSize: '24px',
											color: 'black',
											fontFamily: 'sans-serif'
										}}
									>
										{questions[currentQuestionIndex].QUESTION}
									</FormLabel>
									{responses[(questions[currentQuestionIndex].id)]!=undefined && value==''?
									<>{setValue(responses[(questions[currentQuestionIndex].id)])}</>:""}
									<RadioGroup size="small" value={value} name="quiz" onChange={handleChange}>
										{/* Option A */}
										<FormControlLabel
											sx={{ fontSize: '12px', color: 'grey' }}
											value={questions[currentQuestionIndex]["OPTION A"]}
											control={<Radio />}
											label={questions[currentQuestionIndex]["OPTION A"]}
										/>
										{/* Option B */}
										<FormControlLabel
											sx={{ fontSize: '12px', color: 'grey' }}
											value={questions[currentQuestionIndex]["OPTION B"]}
											control={<Radio />}
											label={questions[currentQuestionIndex]["OPTION B"]}
										/>
										{/* Option C */}
										<FormControlLabel
											sx={{ fontSize: '12px', color: 'grey' }}
											value={questions[currentQuestionIndex]["OPTION C"]}
											control={<Radio />}
											label={questions[currentQuestionIndex]["OPTION C"]}
										/>
										{/* Option D */}
										<FormControlLabel
											sx={{ fontSize: '12px', color: 'grey' }}
											value={questions[currentQuestionIndex]["OPTION D"]}
											control={<Radio />}
											label={questions[currentQuestionIndex]["OPTION D"]}
										/>
									</RadioGroup>
								</FormControl>
								<div>
									{currentQuestionIndex!=0?
									<Button size="small" onClick={handlePrevious} >Previous</Button>:""}
									{currentQuestionIndex==((questions).length-1)?
									<><Button size="small" onClick={FinalSubmit}>Submit</Button></>
									:<Button size="small" onClick={handleNext}>Next</Button>}
								</div>
								<div>
									<FormControlLabel control={<Checkbox checked={isChecked} onChange={() =>{ 
									   
										 setChecked(!isChecked)}}/>} label="Mark as preview" />
								</div>
								
							</Grid>
							</div>
					) :
					null
			}
		</>
	);
}
