
import Footer from '../Deepak/Footer';

import { Grid, Typography } from '@mui/material';
import Page2 from '../NehaBhagat1/new/Page1';
import Category1 from '../NehaBhagat1/new/Category';
import StartPosting from '../NehaBhagat1/new/StartPosting';
import LatestJobs from '../NehaBhagat1/new/LatestJobs';
import ButtonAppBar from '../Deepak/Nav';
import { useEffect, useState } from 'react';
import axios from 'axios';



export default function DiscoverJobs() {
    const [company, setcompany] = useState([{}]);

    const [value, setValue] = useState("")

    // const [companyid, setcompanyid] = useState([]);
    useEffect(() => {
        axios({
            method: "post",
            url: "https://po.keewesolutions.com/getcp",

        }).then((response) => {
            const result = response.data.Items;
           
            setcompany(result)
          

        })
    }, [])
   

    return (
        <Grid container>
            <Grid item lg={1}></Grid>
            <Typography
                sx={{
                    fontFamily: "Clash Display",
                    fontWeight: "600",
                    fontSize: "48px",
                    lineHeight: "110%",
                    color: "#25324B",
                }}
            >
                Jobs for You
            </Typography>


            {/* <ButtonAppBar/> */}
            <Category1 cdata={company} />
            {/* <StartPosting /> */}
            <Page2 value={value} setValue={setValue} />

            <LatestJobs cdata={company} value={value} />



        </Grid>
    )
}