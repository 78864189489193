import React, { useContext } from "react";
import { ResumeContext } from "ResumeContext";
import { Card,Box, Grid, Typography } from "@mui/material";

// const certi = [
//     {"title": "Machine learning specialization", "organization": 'Coursera', "startdate" : '04/01/2020',"enddate" : '05/12/2022',"location": 'Hyderabad', "description" : '(Description)'},
//     {"title": "Machine learning specialization", "organization": 'Coursera', "startdate" : '04/01/2020',"enddate" : '05/12/2022',"location": 'Hyderabad', "description" : '(Description)'},
//     {"title": "Machine learning specialization", "organization": 'Coursera', "startdate" : '04/01/2020',"enddate" : '05/12/2022',"location": 'Hyderabad', "description" : '(Description)'},
//   ]
function Training ({ certi}) {
  return (
    <div>
    <Card>
      <Grid container>
   {certi?.map((item)=>
  <Grid item lg={6} xs={12}>
    <Card sx={{padding:'2%',backgroundColor:'#f3f5fb',margin:'8%'}}>
    <Typography sx={{fontFamily:'poppins',fontSize:'20px',fontWeight:'500'}}> {item.title} at {item.organization}</Typography>
    <Typography sx={{fontFamily:'poppins',fontSize:'18px',fontWeight:'200',marginTop:'4%'}}>{item.locationcity}</Typography>
    <Typography sx={{fontFamily:'poppins',fontSize:'16px',fontWeight:'200',marginTop:'4%'}}>
      {item.start} - {item.end}
    </Typography>
    <Typography sx={{fontFamily:'poppins',fontSize:'16px',fontWeight:'200',marginTop:'4%'}}>{item.description}</Typography>
    </Card>
    </Grid>)}
    </Grid>
    </Card>
  </div>
  )
}

export default Training