import { React, useState,useEffect } from 'react';
import { Typography, Grid, TextField, Button, Avatar, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useContext } from 'react';
import iiii from "../images/Previeww.jpg";
import { ContactSupportOutlined, Preview } from '@mui/icons-material';
import { UserContext } from 'ContextFiles';
import axios from 'axios'
import imgs from './Previews.jpg';
import { ResumeContext } from 'ResumeContext';
import './Opencase.css';

const Input = styled('input')({
	display: 'none'
});


export default function BasicInfo({ hc, index, handleChange }){
	
	const { data, setData } = useContext(ResumeContext);
	
	useEffect(async()=>{
		await axios({
			method:"post",
			url:"https://cms.keewesolutions.com/opencase/get",
			data:{
				userId:data.userId
			}
		}).then((response)=>{
			const result=response.data.Item;
			

			if(result!=undefined){
				setData(result)
				// if(result.fname){
				// 	data.fname=result.fname;
				// }else{
				// 	data.fname='';
				// }

				// if(result.lname){
				// 	data.lname=result.lname;
				// }
				// if(result.headline){
				// 	data.headline=result.headline;
				// }
				// if(result.summary){
				// 	data.summary=result.summary;
				// }
				// if(result.email){
				// 	data.email=result.email;
				// }
				// if(result.imageFile){
				// 	data.imageFile=result.imageFile;
				// }
				
				// if(result.achivementsArr){
				// 	data.achivementsArr=result.achivementsArr;
				// }
				// if(result.hobbies){
				// 	data.hobbies=result.hobbies;
				// }
				// if(result.languageArr){
				// 	data.languageArr=result.languageArr;
				// }
				// if(result.trainingArr){
				// 	data.trainingArr=result.trainingArr;
				// }
				// if(result.imageFile){
				// 	data.imageFile=result.imageFile
				// }
			}
		})
	},[])

	const onNext=async ()=>{
		await axios({
			method:"post",
			url:"https://cms.keewesolutions.com/opencase/put",
			data:{...data
				// image:data.imageFile
			}
		}).then(async (response)=>{
			if((data.imageFile!="" && data.imageFile!=undefined)){
				const formData=new FormData();
				formData.append('userId',data.userId);
				formData.append('image',data.imageFile); 
				axios({
					method:"post",
					url:"https://cms.keewesolutions.com/opencase/putImage",
					data:formData
				}).then((response)=>{
					hc("", index + 1);
				})
			}
			if(data.video!="" && data.video!=undefined){
				const formData=new FormData();
				formData.append('userId',data.userId);
				formData.append('video',data.video)
				axios({
					method:"post",
					url:"https://cms.keewesolutions.com/opencase/putvideo",
					data:formData
				}).then((response)=>{
					hc("", index + 1);
				})
			}
				else{
					hc("",index + 1);
				}	
		})
		}

	const fileChangeVideo = event =>{
		let file = event.target.files[0];
		
		setData({...data,video:file})

	}	

  const fileChangedHandler = event => {
    let file = event.target.files[0];
	
	// setData({...data,imageFile:file})
    let reader = new FileReader();

    const fileExtension = file.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "png"];
    if (!allowedFileTypes.includes(fileExtension)) {
      window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
      return false;
    } else if (file.size > 1e6) {
      window.alert("Please upload a file smaller than 1 MB");
      return false;
    } else {
      
      reader.onload = function (e) {
        
		setData({...data, imageFile:file,imageUrl:e.target.result
		});
      };
	  
      reader.readAsDataURL(event.target.files[0]);
    }
  };
		
		

	return (
		<Grid container>
			<Grid className='n1' item xs={12} lg={12} md={12} sm={12} marginBottom={"1%"} marginTop={'1%'}>
				<Typography fontFamily="poppins" variant="h5" color="#23d366" fontWeight="bold">
					Basic Info
				</Typography>
			</Grid>
			<Grid item xs={12} lg={12} md={12} sm={12} >
				<Typography fontFamily="poppins" variant="h7" color="#4a4a4a">
					Let's begin with you telling us a little about yourself!
				</Typography>
			</Grid>
			<Grid item xs={12} lg={8} marginBottom="1%">
				
				<TextField className='n2'
					variant="outlined"
					label="First Name"
					type="text"
					fullWidth
					sx={{ marginTop: '2%' }}
					value={data.fname}
					onChange={(e) => setData({...data,fname:e.target.value})}
			
				/>
			</Grid>
			<Grid item xs={12} lg={8} marginBottom="1%">
				
				<TextField className='n2'
					variant="outlined"
					label="Last Name"
					type="text"
					fullWidth
					sx={{ marginTop: '2%' }}
					value={data.lname}
					onChange={(e) => setData({...data,lname:e.target.value})}
				/>
			</Grid>
			<Grid item xs={12} lg={8} marginBottom="1%">
				
				<TextField className='n2'
					variant="outlined"
					label=" Headline (eg: Software Engineer)"
					type="text"
					fullWidth
					sx={{ marginTop: '2%' }}
					value={data.headline}
					onChange={(e) => setData({...data,headline:e.target.value})}
				/>
			</Grid>
			<Grid container item xs={12} lg={12} md={12} sm={12}>
				
					<Grid xs={12} lg={4} md={6} sm={6}>
					<div>
						<Typography fontSize={'0.875rem'} sx={{ marginBottom: '12px' }}>
							Image
						</Typography>
						 {(data.imageUrl === "") ? (
							<img
								src={imgs}
								alt="max 200KB"
								style={{
									width: '150px',
									height: '100px',
									objectFit: 'contain'
								}}
							/>
						 ) : (
							<img
								src={imgs}							
								alt="max 200KB"
								style={{
									width: '150px',
									height: '100px',
									objectFit: 'contain'
								}}
							/>
						
						)}  
					</div>
					<div>
						<label>
							<input
								accept="image/*"
								type="file"
								style={{
									display: 'none'
								}}
								onChange={ (e)=>{
									
									fileChangedHandler(e)
								}
									
								}
							/>
							<Button
								
								sx={{
									marginTop: '10px',
									padding: '12px',
									borderRadius: '6px',
									width: '25px'
								}}
								component="span"
							>
								<Typography>Upload</Typography>
							</Button>
						</label>
					</div>
					</Grid>
					<Grid xs={12} lg={4} md={6} sm={6}> 
						<div>
						<Typography fontSize={'0.875rem'} sx={{ marginBottom: '12px' }}>
							Video
						</Typography>
						{(data.video === "") ? (
							<img
								src={imgs}
								alt="max 200KB"
								style={{
									width: '150px',
									height: '100px',
									objectFit: 'contain'
								}}
							/>
						 ) : (
							<img
								src={imgs}							
								alt="max 200KB"
								style={{
									width: '150px',
									height: '100px',
									objectFit: 'contain'
								}}
							/>
						
						)}  
						</div>
					<label>
							<input
								accept="video/*"
								type="file"
								style={{
									display: 'none'
								}}
								onChange={ (e)=>{
									
									fileChangeVideo(e)
								}
									
								}
							/>
							<Button
								
								sx={{
									marginTop: '10px',
									padding: '12px',
									borderRadius: '6px',
									width: '25px'
								}}
								component="span"
							>
								<Typography>Upload</Typography>
							</Button>
						</label>

					</Grid>
				
			</Grid>
			<Grid item xs={12} lg={8} marginBottom="3%">
				
				<TextField className='n2'
					variant="outlined"
					label="Profile Summmary"
					type="text"
					fullWidth
					value={data.summary}
					sx={{ marginTop: '2%', '& .MuiInputBase-input': { height: '100px' } }}
					onChange={(e) => setData({...data,summary:e.target.value})}
				/>
			</Grid>
			<Grid item xs={6} lg={7} />
			<Grid item xs={12} lg={4}>
				<Typography
					fontFamily="poppins"
					sx={{ cursor: 'pointer' }}
					
					onClick={onNext}
				>
					Save & Next
					<IconButton onClick={onNext}>
						<ArrowForwardIcon />
					</IconButton>
				</Typography>
			</Grid>
		</Grid>
	);
}
