import React, { useContext, useState } from 'react';
import logo from './../../assets/logofont.svg';
import human from './../../assets/human.svg';
import { CommonInput, CtaButton, WebcamCapture } from '../../components';
import './register.css';
import axios from 'axios'
import { ExamContext } from '../../App';

const inputField = [  'email', 'fullName'];

const Register = () => {
	const{ExamState,setExamState}=useContext(ExamContext);
	const [InputData,setInputData]=useState({});
	const register=async ()=>{
		await axios({
			method:"post",
			url:"http://localhost:5000/api/register",
			data:InputData
		}).then(res=>{
		})

	}
	return (
		<div className="user-register">
			
			<div className="register-form">
				
				<div className="image-capture">
					{/* <img src={human} alt="human-outline" height="350px"/> */}
					<WebcamCapture />
				</div>
			</div>
		</div>
	);
};

export default Register;
