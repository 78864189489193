import React, { useState } from "react";
import Skill from "./Skill";
import { Card, Grid, Typography } from "@mui/material";

const SkillsList = ({ skill }) => {

  return ( 
    <div>

      <Card>
        <Grid container>


          
              {skill?.map(skill => 
                <Grid item lg={6} xs={12}>
            <Card sx={{ padding: '4%', backgroundColor: '#f3f5fb', margin: '8%' }}>
                  <div className="display-skills" style={{display:"flex"}}> 
                    <Typography sx={{ fontFamily: 'poppins', fontSize: '18px', fontWeight: '500' }}>{skill.title}</Typography>
                    <div style={{ flex: 1 }} />
                    <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', fontWeight: '200' }}
                    >
                      {skill.Emptyp}
                    </Typography>
                  </div> </Card></Grid>
                )
              }


              

           
        </Grid></Card>
    </div>
  );
};
export default SkillsList;
