import React from 'react';
// import "../App.css";
import { Typography, Grid, Button, Card, Paper, IconButton } from '@mui/material';
import ResumeFile from './ResumeFile';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

export default function FinalResume() {

  const navigate = useNavigate();

  return (
    <Grid container padding={'1%'}>
      <Card sx={{borderRadius:'0px' }}>
        <Grid container >
          <Grid item xs={12} lg={12} md={12} sm={!2} display='flex' justifyContent={'space-between'}>
            <Typography fontFamily='poppins' variant='h4' marginTop='1%'>
              {/* <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </IconButton> */}
              Build your Resume</Typography>
              <NavLink to={'/user/templates'} style={{ textDecoration: 'none' }}>
                            <Button variant='contained' sx={{ backgroundColor: '#375dbe' }}>See Templates</Button>
                        </NavLink>
          </Grid>
         
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <ResumeFile />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}