import React, { useContext } from "react";
import { ResumeContext } from "ResumeContext";
import { Card,Box, Grid, Typography } from "@mui/material";

// const cont= [
//   {"email": "abc@gmail.com", "phone": '9999999999', "country": 'India', "state" : 'Jammu' , "city" : 'Akhnoor'},
// ]

function Contact ({cont}) {
  return (
    <div>
    <Card>
      <Grid container>
   
  <Grid item lg={6} xs={12}>
    <Card sx={{padding:'2%',backgroundColor:'#f3f5fb',margin:'8%'}}>
    <Typography sx={{fontFamily:'poppins',fontSize:'18px',fontWeight:'200'}}> {cont.email}</Typography>
    <Typography sx={{fontFamily:'poppins',fontSize:'18px',fontWeight:'200'}}> {cont.phone}</Typography>
    <Typography sx={{fontFamily:'poppins',fontSize:'18px',fontWeight:'200'}}> {cont.country}</Typography>
    <Typography sx={{fontFamily:'poppins',fontSize:'18px',fontWeight:'200'}}> {cont.state}</Typography>
    <Typography sx={{fontFamily:'poppins',fontSize:'18px',fontWeight:'200'}}> {cont.city}</Typography>
    </Card>
    </Grid>
    </Grid>
    </Card>
  </div>
  )
}

export default Contact