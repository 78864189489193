import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Temp1 from './Temp1';
import { Button, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Temp2 from './Temp2';
import Temp3 from './Temp3';
import Temp4 from './Temp4';
import Temp5 from './Temp5';
import { useState, useContext, useEffect } from 'react';
import { UserContext } from 'ContextFiles';
import axios from 'axios';
import Landing from 'Opencase/My-opencase/Landing';
import Logo from '../../assets/images/Myopencase/Logo.png';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && (
				<Box sx={{ p: 0 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

export default function Resumetabs() {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [data, setData] = useState({});

	const { user } = useContext(UserContext);
	useEffect(() => {
		axios({
			method: 'post',
			url: 'https://cms.keewesolutions.com/opencase/get',
			data: {
				userId: user.id
			}
		}).then((response) => {
			const result = response.data.Item;
			setData(result);
		});
	}, []);

	return (
		<Grid container sx={{padding:'2%'}}>
			<Grid xs={12} lg={12} marginLeft="10px" justifyContent={'space-between'} display={'flex'}>
				<Grid display="flex">
					<img src={Logo} alt="logo" style={{ width: '35px' }} />
					<Typography variant="h2" sx={{ marginLeft: '5px', marginTop: '2%' }}>
						My Open<span style={{ color: '#375DBE' }}>case</span>
					</Typography>
				</Grid>
				<Grid>
					<NavLink to={'/finalresume'} style={{ textDecoration: 'none' }}>
						<Button variant="contained" sx={{ backgroundColor: '#375dbe' }}>
							Back
						</Button>
					</NavLink>
				</Grid>
			</Grid>
			<Grid item xs={12} lg={12}>
				<Box sx={{ marginTop: '2%' }}>
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<Tabs value={value} variant="scrollable" onChange={handleChange} aria-label="basic tabs example">
							<Tab label="Digital Resume" {...a11yProps(0)} />
							<Tab label="Resume 1" {...a11yProps(1)} />
							<Tab label="Resume 2" {...a11yProps(2)} />
							<Tab label="Resume 3" {...a11yProps(3)} />
							<Tab label="Resume 4" {...a11yProps(4)} />
							<Tab label="Resume 5" {...a11yProps(5)} />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<Landing data={data} />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<Temp1 data={data} />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<Temp2 data={data} />
					</TabPanel>
					<TabPanel value={value} index={3}>
						<Temp3 data={data} />
					</TabPanel>
					<TabPanel value={value} index={4}>
						<Temp4 data={data} />
					</TabPanel>
					<TabPanel value={value} index={5}>
						<Temp5 data={data} />
					</TabPanel>
				</Box>
			</Grid>
		</Grid>
	);
}
