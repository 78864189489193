import React from "react";
import Typography from '@mui/material/Typography';
import { Paper, Grid } from "@mui/material";
// import logo from '../KL.png';
import './Ardss.css';
import logo from '../components/m.png';
import Crestbell from './mrstpu.png';
import Asset1 from '../../layout/MainLayout/LogoSection/logo.png';


export default function Ardss() {
    return (

        <Grid container direction="column" className="mrsloginpage" sx={{ background: "#06213F", height: "100%", padding: '15% 0 0 15%' }}>
            <Grid item sx={{ margin: '10% 0 0 0' }}>
                <a href="https://lms.crestbellsupport.com"><img src={Asset1} alt="as" style={{
                    display:'block',
                    margin:'auto'

                }} />
                </a>
                <Typography className="ty" textAlign={'center'} alignSelf="center" color={"white"} fontWeight={"500"} fontSize={"50px"} fontFamily={"'Poppins', sans-serif"} marginTop={'4%'}>
                    Start 
                    L-Earning
                    With <span style={{fontWeight:'700'}}>Crestbell</span>
                </Typography>
            </Grid></Grid>
    );
}


