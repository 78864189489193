import React from "react";
import "./App.css";

import AboutFinal from "./components/AboutFinal";
import { Grid, Paper, Box } from "@mui/material";
import Opportunities from "./components/Opportunities";
import ResumeCard from "./components/ResumeCard";
import ProfileInfo from "./components/ProfileInfo";

import FinalResume from "./Resume/FinalResume";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {
  return (
    <Grid container>
      <Router>
        <Routes>
          <Route path="/" element={<HomeFinal />} />
          <Route path="/finalresume" element={<FinalResume />} />
          
        </Routes>
      </Router>
    </Grid>
  );
}

export default App;

//front page components integration

export function HomeFinal() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <AboutFinal />
      </Grid>

      <Grid item xs={12} lg={8}>
        <ProfileInfo />
      </Grid>
      <Grid item xs={12} lg={4}>
        <ResumeCard />
      </Grid>
      <Grid item xs={12}>
        <Opportunities />
      </Grid>
    </Grid>
  );
}

// <Router>
// <Routes>
//   <Route path='/' element = {<HomeFinal/>}/>
//   <Route path='/finalresume' element = {<FinalResume/>}/>
// </Routes>

// </Router>
