import React from 'react';
import { Typography, Grid , Card, TextField, Button } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';


import styled from "@emotion/styled";

const Typography1 = styled(Typography)({
  fontFamily:'Inter',
  marginBottom: '10px'

})

const TextField1 = styled(TextField)({
  marginBottom:'20px',
  width:'90%'
})

export default function ResearchForm(props){

  const navigate = useNavigate();

  return(
    <Grid container>

    <Grid item xs={12} lg={12}
    sx={{textAlign:'center', marginBottom:'20px'}}
    >
     <Typography variant='h3'>Start your <span style={{color:'#630000'}}>Research</span></Typography>
    </Grid>
    
    <Card sx={{margin:'0% 10%',
     padding:'2% 10%'
  
    }}>
    <Grid container >
      
      <Grid item xs={12} lg={6}>
      
      <Typography1><b>Topic of Research*</b></Typography1>
      <TextField1
      id='outlined-basic'
      label='Topic of Research'
      variant='outlined'
      />
      </Grid>

      <Grid item xs={12} lg={6}>
      
      <Typography1><b>Category of Research*</b></Typography1>
      <MultipleSelectPlaceholder text='Category of Research' />
      </Grid>

      <Grid item xs={12} lg={6}>
      
      <Typography1><b>Details of Research Topic*</b></Typography1>
      <TextField1
      id='outlined-basic'
      label='Details of Research Topic'
      variant='outlined'
      />
      </Grid>

      <Grid item xs={12} lg={6}>
      
      <Typography1><b> Research Allowance*</b></Typography1>
      <TextField1
      id='outlined-basic'
      label='Research Allowance'
      variant='outlined'
      />
      </Grid>

      <Grid item xs={12} lg={6}>
      
      <Typography1><b>Deadline for Research*</b></Typography1>
      <TextField1
      id='outlined-basic'
      label='Last date for submission of Research'
      variant='outlined'
      />
      </Grid>

      <Grid item xs={12} lg={6}>
      
     
      </Grid>

      <Grid item xs={12} lg={6}>
      
      <Typography1><b>Thumbnail of Research Topic*</b></Typography1>
      <UploadButtons/>
      </Grid>

      <Grid item xs={12} lg={6}>
      
     
      </Grid>

      <Grid item xs={12} lg={6}>
      
      <Typography1><b>Guidelines and Complete Details of Research*</b></Typography1>
      <UploadButtons/>
      </Grid>

      <Grid item xs={12} lg={12} sx={{display:'flex',justifyContent:'center'}}>
      <Button 
      variant='contained'
      sx={{backgroundColor:'#630000',':hover':{backgroundColor:'#890f0d'}}}
      onClick = {() => navigate('/researchfeed')}
      >
      Submit
      </Button>
      </Grid>



  


    </Grid>
    </Card>
    </Grid>
  )
}


// Select component

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ['Computers','Mechanical','Management','Finance','Electrical','Textile','Sales','Electronics','Agriculture','HR','Engineering','Marketing','Animal Husbandary'];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function MultipleSelectPlaceholder({text}) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <FormControl
        sx={{ width: '90%',  marginBottom: "3%" }}
      >
        <Select
          
          displayEmpty
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <Typography>{text}</Typography>;
            }

            return selected.join(", ");
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>{text}</em>
          </MenuItem>
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}


// Upload Button

const Input = styled('input')({
  display: 'none',
});

export  function UploadButtons() {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <label htmlFor="contained-button-file">
        <Input accept="image/*" id="contained-button-file" multiple type="file" />
        <Button variant="contained" component="span" sx={{marginBottom:'20px', backgroundColor:'#630000',':hover':{backgroundColor:'#890f0d'}}}>
          Upload Image
        </Button>
      </label>
      
    </Stack>
  );
}

