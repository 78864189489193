import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Grid, Box, Card, Avatar, Typography, Divider, Button } from '@mui/material';
import Avatarimg from './Avatarimg.png';


const Temp4 = ({ data }) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    // const arr = [
    //     {
    //         img: Avatarimg,
    //         name: 'Benjamin',
    //         lastname: 'Shah',
    //         occupation: 'SOCIAL MEDIA MANAGER',
    //         Profile:
    //             'There are many variations of passages of Lorem Ipsum available, but majority have suffered alteration in some form, by injected humour, or randomi words which do not look even slight believable. ',
    //         Experience: [
    //             {
    //                 post: 'Social Media Manager',
    //                 company: 'ABC Comapany',
    //                 startdate: '01/04/2020 ',
    //                 enddate: '01/04/2022',
    //                 place: 'Chandigarh',
    //                 specialization: ' Information Technology'
    //             },
    //             {
    //                 post: 'Social Media Manager',
    //                 company: 'ABCD Comapany',
    //                 startdate: '01/04/2020 ',
    //                 enddate: '01/04/2022',
    //                 place: 'Chandigarh',
    //                 specialization: ' Information Technology'
    //             }
    //         ],
    //         Education: [
    //             {
    //                 degree: 'Certificate Course',
    //                 type: 'Social Media Marketing',
    //                 university: ' University of Delhi',
    //                 startdate1: '01/04/2016',
    //                 enddate1: '01/04/2020',
    //                 gradetype: 'CGPA',
    //                 points: '8.2'
    //             },
    //             {
    //                 degree: 'Certificate Course',
    //                 type: 'Social Media Marketing',
    //                 university: ' University of Delhi',
    //                 startdate1: '01/04/2016',
    //                 enddate1: '01/04/2020',
    //                 gradetype: 'CGPA',
    //                 points: '7.2'
    //             }
    //         ],
    //         certificate: [
    //             {
    //                 course: 'Machine learning',
    //                 fromwhere: 'Coursera',
    //                 startdate2: '01/04/2019',
    //                 enddate2: '04/04/2019',
    //                 description: 'Machine learning specialization'
    //             },
    //             {
    //                 course: 'Machine learning',
    //                 fromwhere: 'Coursera',
    //                 startdate2: '01/04/2019',
    //                 enddate2: '04/04/2019',
    //                 description: 'Machine learning specialization'
    //             }
    //         ],
    //         achievements: [
    //             { certi: 'Secured 1st position in Maths Olympiad', date: '01/04/2017', description1: 'Good Experience' },
    //             { certi: 'Secured 1st position in Maths Olympiad', date: '01/04/2017', description1: 'Good Experience' }
    //         ],
    //         skills: [
    //             { skill: 'Excellent presentation skills' },
    //             { skill: 'Excellent presentation skills' },
    //             { skill: 'Excellent presentation skills' }
    //         ],
    //         languages: [
    //             { language: 'English', proficiency: 'Advanced' },
    //             { language: 'Hindi', proficiency: 'Advanced' },
    //             { language: 'Dogri', proficiency: 'Advanced' }
    //         ],
    //         contact: [
    //             { cont: 'abc@gmail.com' },
    //             { cont: '9999999999' },
    //             { cont: 'House no. 47, Sector 47D' },
    //             { cont: 'Chandigarh' },
    //             { cont: 'India' }
    //         ],
    //         hobbies: [{ hobby: 'Dancing' }, { hobby: 'Singing' }, { hobby: 'Calligraphy' }]
    //     }
    // ];
    return (
        <>
            <Grid container ref={componentRef} sx={{ display: 'flex', justifyContent: 'center' }} >
                <Grid item xs={12} lg={10} margin={'2%'} border='1px solid black' padding='2% 2% 2% 2%' >
                        <Grid item container xs={12} >
                            <Grid item xs={5} lg={5}>
                                <Box sx={{ backgroundColor: '#253439' }}>
                                    <Box sx={{ height: '20px' }}></Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                                        <Avatar sx={{ height: 180, width: 180 }} src={data.imageUrl} />
                                    </Box>
                                    <Box sx={{ backgroundColor: '#595551', padding: '8%', marginTop: '5%' }}>
                                        <Typography sx={{ fontFamily: 'Nunito', fontSize: '44px', fontWeight: '700', color: 'rgb(255, 255, 255)' }}>
                                            {data.fname}
                                        </Typography>
                                        <Typography sx={{ fontFamily: 'Nunito', fontSize: '44px', fontWeight: '700', color: 'rgb(255, 255, 255)', marginTop: '-5%' }}>
                                            {data.lname}
                                        </Typography>
                                        <Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: '400', color: 'rgb(255, 255, 255)', marginTop: '2%' }}>
                                            {data.headline}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ padding: '6%' }}>
                                        <Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: '700', color: 'rgb(255, 255, 255)', letterSpacing: '0.15em' }}>
                                            ABOUT
                                        </Typography>
                                        <Typography sx={{ fontFamily: 'Nunito', fontSize: '14px', fontWeight: '400', color: 'rgb(255, 255, 255)', marginTop: '6%' }}>
                                            {data.summary}
                                        </Typography><Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: '700', color: 'rgb(255, 255, 255)', marginTop: '10%', letterSpacing: '0.15em' }}>
                                            CONTACT
                                        </Typography>
                                        <Box>
                                            <Typography sx={{ fontFamily: 'Nunito', fontSize: '16px', fontWeight: '400', color: 'rgb(255, 255, 255)', marginTop: '6%' }}>
                                                {data.email}<br />{data.city}<br />{data.country}
                                            </Typography>
                                        </Box>
                                        <Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: '700', color: 'rgb(255, 255, 255)', marginTop: '10%', letterSpacing: '0.15em' }}>
                                            LANGUAGES
                                        </Typography>
                                        <Box>
                                            {data.languageArr?.map((item2) => {
                                                return (
                                                    <Typography sx={{ fontFamily: 'Nunito', fontSize: '16px', fontWeight: '400', color: 'rgb(255, 255, 255)', marginTop: '6%' }}>
                                                        {item2.title} <i>({item2.Emptyp})</i>
                                                    </Typography>
                                                );
                                            })}
                                        </Box>
                                        <Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: '700', color: 'rgb(255, 255, 255)', marginTop: '10%', letterSpacing: '0.15em' }}>
                                            SKILLS
                                        </Typography>
                                        <Box>
                                            {data.SkillArr?.map((item1) => {
                                                return (
                                                    <Typography sx={{ fontFamily: 'Nunito', fontSize: '16px', fontWeight: '400', color: 'rgb(255, 255, 255)', marginTop: '6%' }}>
                                                        {item1.title} <i>({item1.Emptyp})</i>
                                                    </Typography>
                                                );
                                            })}
                                        </Box>
                                        <Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: '700', color: 'rgb(255, 255, 255)', marginTop: '10%', letterSpacing: '0.15em' }}>
                                            HOBBIES
                                        </Typography>
                                        <Box>
                                            {data.hobbies?.map((item4) => {
                                                return (
                                                    <Typography sx={{ fontFamily: 'Nunito', fontSize: '16px', fontWeight: '400', color: 'rgb(255, 255, 255)', marginTop: '6%' }}>
                                                        {item4.title}
                                                    </Typography>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                    <Box sx={{ height: '80px' }}></Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6.5} lg={6.5}>
                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '20px', fontWeight: '1000', marginTop: '4%', letterSpacing: '0.15em', textAlign: 'end', color: '#595551' }}>RESUME</Typography>
                                <Divider />
                                <Box sx={{ padding: '8%' }}>
                                    <Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: 'bold', letterSpacing: '0.25em', color: '#595551', textDecoration: 'underline' }}>
                                        EXPERIENCE
                                    </Typography>
                                    {data.workArray?.map((item5) => {
                                        return (
                                            <Box>
                                                <Box component="span" sx={{ display: 'inline-block', mx: '8px', transform: 'scale(4)', color: '#595551', marginTop: '2%' }}>
                                                    •
                                                </Box>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: '600', marginTop: '2%' }}>
                                                    {item5.designation} at {item5.company}
                                                </Typography>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '15px', fontWeight: '500' }}>
                                                    ({item5.start}) - ({item5.end})
                                                </Typography>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '16px', fontWeight: '600', marginTop: '1%' }}>
                                                    ({item5.location})
                                                </Typography>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '16px', fontWeight: '600', marginTop: '1%' }}>
                                                    {item5.employmentType}
                                                </Typography>
                                            </Box>
                                        );
                                    })}
                                    <Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: '1000', letterSpacing: '0.15em', marginTop: '6%', color: '#595551', textDecoration: 'underline' }}>
                                        EDUCATION
                                    </Typography>
                                    {data.educationArr?.map((item6) => {
                                        return (
                                            <Box>
                                                <Box component="span" sx={{ display: 'inline-block', mx: '8px', transform: 'scale(4)', color: '#595551', marginTop: '2%' }}>
                                                    •
                                                </Box>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: '600', marginTop: '2%' }}>
                                                    {item6.course} in {item6.special}
                                                </Typography>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '15px', fontWeight: '500' }}>
                                                    ({item6.start}) - ({item6.end})
                                                </Typography>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '16px', fontWeight: '600', marginTop: '1%' }}>
                                                    {item6.inst}
                                                </Typography>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '15px', fontWeight: '500', marginTop: '1%' }}>
                                                    <span style={{ fontWeight: '600', fontSize: '16px' }}>CGPA -</span> {item6.grade}
                                                </Typography>
                                            </Box>
                                        );
                                    })}
                                    <Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: '1000', letterSpacing: '0.15em', marginTop: '6%', color: '#595551', textDecoration: 'underline' }}>
                                        TRAINING AND CERTIFICATES
                                    </Typography>
                                    {data.trainingArr?.map((item7) => {
                                        return (
                                            <Box>
                                                <Box component="span" sx={{ display: 'inline-block', mx: '8px', transform: 'scale(4)', color: '#595551', marginTop: '2%' }}>
                                                    •
                                                </Box>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: '600', marginTop: '2%' }}>
                                                    {item7.title} from {item7.organization}
                                                </Typography>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '15px', fontWeight: '500' }}>
                                                    ({item7.start}) - ({item7.end})
                                                </Typography>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '16px', fontWeight: '600', marginTop: '1%' }}>
                                                    {item7.description}
                                                </Typography>
                                            </Box>
                                        );
                                    })}
                                    <Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: '1000', letterSpacing: '0.15em', marginTop: '6%', color: '#595551', textDecoration: 'underline' }}>
                                        ACHIEVEMENTS
                                    </Typography>
                                    {data.achivementsArr?.map((item8) => {
                                        return (
                                            <Box>
                                                <Box component="span" sx={{ display: 'inline-block', mx: '8px', transform: 'scale(4)', color: '#595551', marginTop: '2%' }}>
                                                    •
                                                </Box>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '18px', fontWeight: '600', marginTop: '2%' }}>
                                                    {item8.title}
                                                </Typography>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '15px', fontWeight: '500' }}>
                                                    ({item8.start})
                                                </Typography>
                                                <Typography sx={{ fontFamily: 'Nunito', fontSize: '16px', fontWeight: '600', marginTop: '1%' }}>
                                                    {item8.description}
                                                </Typography>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Grid>
                            <Grid item xs={0.5} lg={0.5}></Grid>
                        </Grid>
                  
                </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button id="download" variant="contained" onClick={handlePrint} sx={{ marginTop: '20px', margin: 'auto' }}>Download PDF</Button>	</Grid>
        </>
    )
}

export default Temp4