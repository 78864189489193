import { Paper, Typography, Box, Card, Grid, color, Button } from '@mui/material'
import { fontSize, width } from '@mui/system'
import React , {useContext, useEffect, useState} from 'react'
import Rating from '@mui/material/Rating';
import lady from './lady.png';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import axios from "axios"
import { UserContext } from 'ContextFiles';
import { useAlert } from 'react-alert';
import Avatar from '@mui/material/Avatar';


const Reviews = ({courseId,id}) => {
    const alert=useAlert();
    const {user}=useContext(UserContext)
    const [reflect, setReflect] = useState([])
    const [value, setValue] = useState("")
    const [Data,setData]=useState({
        UserId:user.id,
        UserName:user.name,
        CourseId:courseId,
        Rating:'',
        Review:'',
        Status:'Not Verified'
    })
    useEffect(async ()=>{
        await axios({
            url:"https://cms.keewesolutions.com/courses/GetUserReview",
            method:"post",
            data:{
                UserId:user.id,
                CourseId:courseId}
        }).then(res=>{
            if(res.status==200 && res.data.Items){
                const revData = res.data.Items.filter(x =>{
                    if(x.CourseId==courseId){
                        return x
                    } else{
                        return null
                    }
                })
                setReflect(revData)  
            }
        }).catch(err=>{
            console.log(err)
            alert.show("Something Went Wrong Please Try After Sometime")
        })

    },[value])

    const handleReview=async ()=>{
        
        await axios({
            url:"https://cms.keewesolutions.com/courses/AddReview",
            method:"post",
            data:Data
        }).then(res=>{
            if(res.status==200){
                setData({
                    UserId:user.id,
                    CourseId:courseId,
                    Rating:'',
                    Review:'',
                    Status:"Not Verified"
                })
                alert.show("Thanks For Review")
                setValue("yes")
                setValue("no")
            }

        }).catch(err=>{
            console.log(err)
            alert.show("Something Went Wrong Please Try After Sometime")
        })

    }

    return (

        <Grid container lg={12} xs={12} >
            <Grid item lg={12} xs={12} >
                    <Typography sx={{ textAlign: "center", fontSize: '1rem', fontFamily: 'Roboto', fontWeight: '500', color: "#000", marginTop: "30px", marginBottom: "30px" }}>
                        How was your experience with us?
                    </Typography>
                    <Grid display={'flex'} justifyContent={'center'} height={'300px'} >
                        <Grid sx={{ textAlign: "center", marginTop: "50px" }}>
                            <Box
                                sx={{
                                    '& > legend': { mt: 2 },
                                    color: "#6C63FF",

                                }}
                            >
                                {/* <Rating
                                    name="simple-controlled"
                                    size="large"
                                    value={value}
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }}
                                /> */}
                            </Box>
                        </Grid>
                        <Grid sx={{ height: "100px", width: "auto" }}>
                            <img src={lady} alt={''} />
                        </Grid>
                    </Grid>
                    <Stack spacing={1} alignItems={'center'}>
                        <Rating name="size-large" value={Data.Rating} onChange={e=>setData({...Data,Rating:e.target.value})} size="large" required={true}/>
                    </Stack>
                    <Grid padding={"20px 0 20px 20px"}
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1,  }
                        }}
                        lg={10}
                        xs={10}

                        noValidate
                        autoComplete="off"
                    >
                        <TextField id="outlined-basic" label="Write a review" variant="outlined" 
                        value={Data.Review} onChange={e=>setData({...Data,Review:e.target.value})} sx={{width:"100%"}} />    
                    </Grid>
                    <Grid sx={{ textAlign:"center" ,  padding:"10px 0"}}>
                    <Button onClick={handleReview} variant='contained' sx={{width:"100px" , height:"50px" , borderRadius:"10px" }}>
                        Submit
                        </Button>
                    </Grid>        
            </Grid>
            <Grid>
                {reflect.map((item)=>{
                    return(<Card sx={{ height:"auto",padding:"1rem 0"}}>
                    <Box sx={{display:"flex",paddingBottom:"5px"}}>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg">{item.UserId[0]}</Avatar>
                    <Box>
                    <Typography sx={{paddingLeft:"5px"}}>{item.UserId}</Typography>
                    {/* <Typography sx={{paddingLeft:"5px",fontSize:'12px'}}>{item.createdAt}</Typography> */}
                    <Stack spacing={1} >
                        <Rating name="read-only" value={item.Rating}  size="large" readOnly />
                    </Stack>
                    </Box>
                    </Box>
                    <Typography>{item.Review}</Typography>
                    {/* <TextField  label="Discussion" id="fullWidth"  onChange={event => { setdata({ ...data,comment:event.target.value }) }} />
                    <Button onClick={postDiscussion}>Post Discussion</Button> */}
                    
                  </Card>)
                })

                }
            </Grid>
        </Grid>

    )
}

export default Reviews;