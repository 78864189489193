import React, {useState} from 'react';
import { Typography, Grid, Card, Button, ImageList  } from '@mui/material';
import ModalComponent from "./ModalComponent"
import { fontFamily } from '@mui/system';
import ModalPlant from '../images/ModalPlant.png';
import { NavLink } from 'react-router-dom';


export default function ResearchCard({company, picture, heading, content, date, allowance, modalcontent}){

  const [buttonText, setButtonText] = useState('Apply Now')

  return(
    <Grid container  >
      <Grid xs={12} lg={12} sx={{width:'auto',height:'auto', marginBottom:'2%', marginLeft:'2%', border:'1px solid #c9cdd0', boxShadow:'0px 3px 5px 3px #c9cdd0'}}>
       <Grid container xs={12} lg={12}  >
         <Grid container xs={12} lg={12} sx={{margin:'2% 5%'}}>
            <Grid item xs={8} lg={7}>
              <Typography sx={{fontFamily:'poppins', marginTop:'2%'}}><b>{company}</b></Typography>
            </Grid>
            <Grid item xs={4} lg={5}>
            <ModalComponent heading={heading} modalcontent={modalcontent} picture={picture} />
            </Grid>
         </Grid>

         <Grid item lg={12} xs={12} sx={{margin:'2% 5%'}}>
           <img src={picture} alt='imaggvnhbdee' width={'100%'}/>
         </Grid>

         <Grid item lg={12} xs={12} sx={{marginLeft:'5%', marginBottom:'1%'}}>
           <Typography sx={{fontFamily:'poppins'}}><b>{heading}</b></Typography>
         </Grid>

         <Grid item lg={12} xs={12} sx={{margin:'2% 5%', textAlign: 'justify'}}>
            <Typography sx={{fontFamily:'poppins'}}>{content}</Typography>
         </Grid>

         <Grid container sx={{margin:'4% 5%'}} >
            <Grid item lg={6} xs={12}>
               <Typography sx={{fontSize:'15px', fontFamily:'poppins'}}><span style={{color:'#21b2bb'}}>Deadline:</span> {date}</Typography>
            </Grid>

            <Grid item  lg={6} xs={12}>
               <Typography sx={{fontSize:'15px', fontFamily:'poppins'}}><span style={{color:'#21b2bb'}}>Allowance:</span> {allowance}</Typography>
            </Grid>
         </Grid>

         <Grid item lg={12} xs={12} sx={{marginBottom:'5%'}}>
          <Button variant='contained' 
            sx={{backgroundColor: buttonText != "Apply Now" ? '#55c555':'#21b2bb', marginLeft:'5%', marginRight:'5%', width:'90%'}}
            onClick = {() => {setButtonText('Applied Successfully')}}
            >
              {buttonText}
           </Button>
         </Grid>
       </Grid>
       </Grid>
    </Grid>
  )
}