import { Typography,Grid,Box,Divider, Card,Button } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import React,{useRef} from "react";
import { useReactToPrint } from "react-to-print";


const Temp5 = ({ data }) => {
  const componentRef = useRef();
	const handlePrint = useReactToPrint({
	  content: () => componentRef.current,
	});
  //   const arr = [
	// 	{
	// 		name: ' Diana Johnson',
	// 		occupation: 'Customer Service Representative',
	// 		Profile:
	// 			'There are many variations of passages of Lorem Ipsum available, but majority have suffered alteration in some form, by injected humour, or randomi words which do not look even slight believable. ',
	// 		Experience: [
	// 			{
	// 				post: 'Social Media Manager',
	// 				company: 'ABC Comapany',
	// 				startdate: '01/04/2020 ',
	// 				enddate: '01/04/2022',
	// 				place: 'Chandigarh',
	// 				specialization: ' Information Technology'
	// 			},
	// 			{
	// 				post: 'Social Media Manager',
	// 				company: 'ABCD Comapany',
	// 				startdate: '01/04/2020 ',
	// 				enddate: '01/04/2022',
	// 				place: 'Chandigarh',
	// 				specialization: ' Information Technology'
	// 			}
	// 		],
	// 		Education: [
	// 			{
	// 				degree: 'Certificate Course',
	// 				type: 'Social Media Marketing',
	// 				university: ' University of Delhi',
	// 				startdate1: '01/04/2016',
	// 				enddate1: '01/04/2020',
	// 				gradetype: 'CGPA',
	// 				points: '8.2'
	// 			},
	// 			{
	// 				degree: 'Certificate Course',
	// 				type: 'Social Media Marketing',
	// 				university: ' University of Delhi',
	// 				startdate1: '01/04/2016',
	// 				enddate1: '01/04/2020',
	// 				gradetype: 'CGPA',
	// 				points: '7.2'
	// 			}
	// 		],
	// 		certificate: [
	// 			{
	// 				course: 'Machine learning',
	// 				fromwhere: 'Coursera',
	// 				startdate2: '01/04/2019',
	// 				enddate2: '04/04/2019',
	// 				description: 'Machine learning specialization'
	// 			},
	// 			{
	// 				course: 'Machine learning',
	// 				fromwhere: 'Coursera',
	// 				startdate2: '01/04/2019',
	// 				enddate2: '04/04/2019',
	// 				description: 'Machine learning specialization'
	// 			}
	// 		],
	// 		achievements: [
	// 			{ certi: 'Secured 1st position in Maths Olympiad', date: '01/04/2017', description1: 'Good Experience' },
	// 			{ certi: 'Secured 1st position in Maths Olympiad', date: '01/04/2017', description1: 'Good Experience' }
	// 		],
	// 		skills: [
	// 			{ skill: 'Phone skills' },
	// 			{ skill: 'Leadership skills' },
	// 			{ skill: 'Excellent presentation skills' },
  //               { skill: 'Skills 1' },
  //               { skill: 'Skills 2' },
  //               { skill: 'Skills 3' },
  //               { skill: 'Skills 4' },
  //               { skill: 'Skills 5' },
	// 		],
	// 		languages: [
	// 			{ language: 'English', proficiency: 'Advanced' },
	// 			{ language: 'Hindi', proficiency: 'Advanced' },
	// 			{ language: 'Dogri', proficiency: 'Advanced' }
	// 		],
	// 		contact: [
	// 			{ email: 'abc@gmail.com',phoneno: '9999999999',  houseno: 'House no. 47',street: 'street 4' ,locality: 'sector 7',city: 'CHD',state: 'Chandigarh',country: 'India'},
			
	// 		],
	// 		hobbies: [{ hobby: 'Dancing' }, { hobby: 'Singing' }, { hobby: 'Calligraphy' }]
	// 	}
	// ];
  return (
    <>
    <Grid container ref={componentRef} sx={{display:'flex',justifyContent:'center'}} >
       
   
			<Grid item xs={12} lg={10} margin={'2%'} >
           
						<Box sx={{ border: '1px solid #cac5c538',padding:'3%'}}>
            <Box sx={{backgroundColor:'#449399',padding:'2%'}}>
             <Typography sx={{fontFamily:'Public Sans',color:'rgb(255, 255, 255)',fontSize:'42px',fontWeight:'700',lineHeight:'1.3'}}>
                {data.fname} {data.lname}
             </Typography>
             <Typography sx={{fontFamily:'Public Sans',color:'rgb(255, 255, 255)',fontSize:'20px',fontWeight:'600'}}>
             {data.headline}
             </Typography>
             <Divider sx={{ borderColor:'rgb(255, 255, 255)',borderBottomWidth:'initial',marginTop:'1%'}}/>
             <Typography sx={{fontFamily:'Public Sans',color:'rgb(255, 255, 255)',fontSize:'16px',fontWeight:'600',marginTop:'1%'}}>
           {data.summary}
             </Typography>
            </Box>
            <Grid item container xs={12} marginTop="2%">
                
            <Grid item xs={4} lg={4}>
            <Typography sx={{fontFamily:'Nunito',fontSize:'22px',fontWeight:'700',color:'#449399',letterSpacing:'0.1em',marginTop:'5%' }}>
             CONTACT
             </Typography>
             <Divider sx={{ borderColor:'#449399',borderBottomWidth:'initial'}}/>
            
												  <Box>
                     <EmailIcon sx={{color:'#449399',fontSize:'1.2rem',marginTop:'4%'}}/>
             <Typography sx={{fontFamily:'Nunito',fontSize:'16px',fontWeight:'600'}}>
              {data.email}
             </Typography>
             {/* <LocalPhoneIcon sx={{color:'#449399',fontSize:'1.2rem',marginTop:'2%'}}/> */}
             {/* <Typography sx={{fontFamily:'Nunito',fontSize:'16px',fontWeight:'600'}}>
              {item3.phoneno}
             </Typography> */}
             <LocationOnIcon sx={{color:'#449399',fontSize:'1.2rem',marginTop:'2%'}}/>
             {/* <Typography sx={{fontFamily:'Nunito',fontSize:'16px',fontWeight:'600'}}>
              {item3.houseno}, {item3.street}, {item3.locality}
             </Typography> */}
             <Typography sx={{fontFamily:'Nunito',fontSize:'16px',fontWeight:'600'}}>
              {data.city},{data.state},{data.country}
             </Typography>
                </Box>
               
											
             <Typography sx={{fontFamily:'Nunito',fontSize:'22px',fontWeight:'700',color:'#449399',letterSpacing:'0.1em',marginTop:'3%' }}>
             SKILLS
             </Typography>
             <Divider sx={{ borderColor:'#449399',borderBottomWidth:'initial'}}/>
             {data.SkillArr?.map((item1) => {
												return (
											<Box display={'flex'} sx={{marginTop:'4%'}}>
                 <Box component="span" sx={{ display: 'inline-block', transform: 'scale(2)',color:'#449399',marginTop:'1%' }}>
	                     	•
                   	</Box>
            <Typography sx={{fontFamily:'Nunito',fontSize:'16px',fontWeight:'600',marginLeft:'4%'}}>
             {item1.title} <i>({item1.Emptyp})</i>
             </Typography>
             </Box>
												);
											})}
                    
             
             <Typography sx={{fontFamily:'Nunito',fontSize:'22px',fontWeight:'700',color:'#449399',letterSpacing:'0.1em',marginTop:'3%' }}>
             LANGUAGES
             </Typography>
             <Divider sx={{ borderColor:'#449399',borderBottomWidth:'initial'}}/>
             <Box>
             {data.languageArr?.map((item2) => {
												return (
													 <Typography sx={{fontFamily:'Nunito',fontSize:'16px',fontWeight:'600',marginTop:'4%'}}>
              {item2.title} <i>({item2.Emptyp})</i>
             </Typography>
												);
											})}
                
             </Box>
            
             <Typography sx={{fontFamily:'Nunito',fontSize:'22px',fontWeight:'700',color:'#449399',letterSpacing:'0.1em',marginTop:'3%' }}>
             HOBBIES
             </Typography>
             <Divider sx={{ borderColor:'#449399',borderBottomWidth:'initial'}}/>
             {data.hobbies?.map((item4) => {
												return (
													 <Typography sx={{fontFamily:'Nunito',fontSize:'16px',fontWeight:'600',marginTop:'4%'}}>
              {item4.title}
             </Typography>
												);
											})}
            
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={6} lg={6}>
            <Typography sx={{fontFamily:'Nunito',fontSize:'22px',fontWeight:'700',color:'#449399',letterSpacing:'0.1em',marginTop:'3%' }}>
             EXPERIENCE
             </Typography>
             <Divider sx={{ borderColor:'#449399',borderBottomWidth:'initial'}}/>
             {data.workArray?.map((item5) => {
                      return (
                           <Box>
                 <Typography sx={{fontFamily:'Nunito',fontSize:'18px',fontWeight:'600',marginTop:'2%'}}>
                 {item5.designation} at {item5.company}
             </Typography>
             <Typography sx={{fontFamily:'Nunito',fontSize:'15px',fontWeight:'500'}}>
             ({item5.start}) - ({item5.end})
             </Typography>
             <Typography sx={{fontFamily:'Nunito',fontSize:'16px',fontWeight:'600'}}>
             {item5.location}
             </Typography>
             <Typography sx={{fontFamily:'Nunito',fontSize:'16px',fontWeight:'600'}}>
             {item5.employmentType}
             </Typography>
             </Box>
            
												);
											})}
            
             <Typography sx={{fontFamily:'Nunito',fontSize:'22px',fontWeight:'700',color:'#449399',letterSpacing:'0.1em',marginTop:'3%' }}>
             EDUCATION
             </Typography>
             <Divider sx={{ borderColor:'#449399',borderBottomWidth:'initial'}}/>
             {data.educationArr?.map((item6) => {
												return (
												  <Box>
                <Typography sx={{fontFamily:'Nunito',fontSize:'18px',fontWeight:'600',marginTop:'2%'}}>
                {item6.course} in {item6.special}
             </Typography>
             <Typography sx={{fontFamily:'Nunito',fontSize:'15px',fontWeight:'500'}}>
             ({item6.start}) - ({item6.end})
             </Typography>
             <Typography sx={{fontFamily:'Nunito',fontSize:'16px',fontWeight:'600'}}>
             <span style={{ fontWeight: '600', fontSize: '16px' }}>CGPA -</span> {item6.grade}
             </Typography>
             </Box>
												);
											})}
           
             
             <Typography sx={{fontFamily:'Nunito',fontSize:'22px',fontWeight:'700',color:'#449399',letterSpacing:'0.09em',marginTop:'3%' }}>
             TRAINING AND CERTIFICATES
             </Typography>
             <Divider sx={{ borderColor:'#449399',borderBottomWidth:'initial'}}/>
             {data.trainingArr?.map((item7) => {
												return (
												 <Box>
                 <Typography sx={{fontFamily:'Nunito',fontSize:'18px',fontWeight:'600',marginTop:'2%'}}>
                 {item7.title} from {item7.organization}
             </Typography>
             <Typography sx={{fontFamily:'Nunito',fontSize:'15px',fontWeight:'500'}}>
             ({item7.start}) - ({item7.end})
             </Typography>
             <Typography sx={{fontFamily:'Nunito',fontSize:'16px',fontWeight:'600'}}>
            {item7.description}
             </Typography>
             </Box>
												);
											})}
            
            
             <Typography sx={{fontFamily:'Nunito',fontSize:'22px',fontWeight:'700',color:'#449399',letterSpacing:'0.1em',marginTop:'3%' }}>
             ACHIEVEMENTS
             </Typography>
             <Divider sx={{ borderColor:'#449399',borderBottomWidth:'initial'}}/>
             {data.achivementsArr?.map((item8) => {
												return (
												 <Box>
                <Typography sx={{fontFamily:'Nunito',fontSize:'18px',fontWeight:'600',marginTop:'2%'}}>
             {item8.title}
             </Typography>
             <Typography sx={{fontFamily:'Nunito',fontSize:'15px',fontWeight:'500'}}>
             ({item8.start}) 
             </Typography>
             <Typography sx={{fontFamily:'Nunito',fontSize:'16px',fontWeight:'600'}}>
             {item8.description1}
             </Typography>
             </Box>
												);
											})}
            
             
            </Grid>
            </Grid>
            </Box> 
				
            
            </Grid>
            
    </Grid>
    <Grid sx={{display:'flex',justifyContent:'center'}}>
		<Button id="download" variant="contained" onClick={handlePrint}  sx={{marginTop:'20px',margin:'auto'}}>Download PDF</Button>	</Grid>
    </>
  )
}

export default Temp5