import React, { useContext } from "react";
import { Card, Grid, Typography} from "@mui/material";



function EducationComponent({edu}) {
  return (
      
    <div>
    <Card>
        <Grid container>
      {edu?.map((item)=>
       <Grid item lg={6} xs={12}>
       <Card sx={{padding:'2%',backgroundColor:'#f3f5fb',margin:'8%'}}>
      <Typography Typography sx={{fontFamily:'poppins',fontSize:'20px',fontWeight:'500',marginTop:'4%'}}> {item.course} at {item.inst} </Typography>
      <Typography sx={{fontFamily:'poppins',fontSize:'16px',fontWeight:'200',marginTop:'4%'}}>
        {item.start} - {item.end}
      </Typography>
      <Typography sx={{fontFamily:'poppins',fontSize:'16px',fontWeight:'200',marginTop:'4%'}}>{item.special}</Typography>
      <Typography sx={{fontFamily:'poppins',fontSize:'16px',fontWeight:'200',marginTop:'4%'}}>{item.grade}</Typography>
      </Card></Grid>
      )}</Grid></Card>
    </div>
  );
}
export default EducationComponent;
