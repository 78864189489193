import { List, Grid, IconButton, Collapse } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import NestedList from './NestedList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Week from 'Week/Week';
import Week1 from 'Week/Week1';
export default function Resources({ courseContent}) {


    return (
        <Grid lg={11} xs={12}>
            <Week1 courseId={courseContent} />
        </Grid>
    );
}