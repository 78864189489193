import Footer from '../Deepak/Footer';
import { Grid } from '@mui/material';
import Nav from '../Deepak/Nav';
import Alljobs from '../Deepak/Alljobs';
import SocialMedia2 from '../Rohan/SocialMedia2';



export default function Searchcompaniesresult() {
    return (
        <Grid>
            <Nav/>
            <SocialMedia2/>
            <Alljobs/>
            
        </Grid>
    )
}