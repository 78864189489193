import {React,useContext,useState,useEffect,createContext} from "react";
import {
  Typography,
  Card,
  Grid,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DropStates from "Opencase/components/Countries";
import { ResumeContext } from "ResumeContext";
import axios from 'axios';
import './Opencase.css';

export const Address=createContext();

export default function Contact({hc, index}) {
  const {data, setData} = useContext(ResumeContext);
  const [add,setAdd]=useState({
    country:data.country,
    state:data.state,
    city:data.city
  })

  useEffect(()=>{
    setData({...data,city:add.city,state:add.state,country:add.country})
  },[add])

  const onNext=()=>{
	
		axios({
			method:"post",
			url:"https://cms.keewesolutions.com/opencase/put",
			data:{...data
				// image:data.imageFile
			}
		}).then((response) => {
      hc("", index + 1);
    })
	}
  return (
    <Address.Provider value={{add,setAdd}}>
    <Grid container>
      <Grid className='n1' item xs={12} lg={12} sm={12} md={12} marginBottom={"1%"} marginTop={'1%'}>
        <Typography
          fontFamily="poppins"
          variant="h5"
          
          color="#23d366" fontWeight='bold'
          sx={{ }}
        >
          Contact Details
        </Typography> </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12} ><Typography fontFamily="poppins" variant="h7" color="#4a4a4a">
          Share with us the best way to reach out to you!
        </Typography></Grid>
        
     

      <Grid item xs={12} lg={8} marginBottom="1%">
       
        <TextField className='n2'
          variant="outlined"
          label="Email"
          type="text"
          fullWidth
          sx={{ marginTop: "2%" }}
          value={data.email}
          onChange={e=>setData({...data,email:e.target.value})}
        />
      </Grid>

      <Grid item xs={12} lg={8} marginBottom="1%">
       
        <TextField className='n2'
          variant="outlined"
          label="Phone Number"
          fullWidth
          sx={{ marginTop: "2%" }}
          value={data.phone}
          onChange={e=>setData({...data,phone:e.target.value})}
        />
      </Grid>
    
      <Grid item xs={12} lg={8} marginBottom="1%">
        <TextField className='n2'
          variant="outlined"
          label="House no."
          fullWidth
          sx={{ marginTop: "2%" }}
          value={data.houseno}
          onChange={e=>setData({...data,houseno:e.target.value})}
        /></Grid>
        <Grid item xs={12} lg={8} marginBottom="1%"> <TextField className='n2'
          variant="outlined"
          label="Street"
          fullWidth
          sx={{ marginTop: "2%" }}
          value={data.street}
          onChange={e=>setData({...data,street:e.target.value})}
        /></Grid>
        <Grid item xs={12} lg={8} marginBottom="1%"> <TextField className='n2'
          variant="outlined"
          label="Locality"
          fullWidth
          sx={{ marginTop: "2%" }}
          value={data.locality}
          onChange={e=>setData({...data,locality:e.target.value})}
        /></Grid>
        
      </Grid>

      <Grid item xs={12} lg={12}m md={12} sm={12} marginBottom="3%">
       
        <Grid item xs={12} lg={8} > 
          {/* <TextField
            variant="outlined"
            label="Country"
            fullWidth
            sx={{ marginBottom: "2%", marginTop: "1%" }}
            onChange={e=>setCurrentaddress(e.target.value)}
          /> */}
          <DropStates/>
          
        </Grid>
      </Grid>

      <Grid item xs={6} lg={7} md={9} sm={9} display="flex">
        <Typography fontFamily="poppins" onClick={(e)=>hc(e,index-1)} sx={{cursor: 'pointer'}}>
        <IconButton >
          <ArrowBackIcon />
        </IconButton>
          Previous
        </Typography>
      </Grid>
      <Grid item xs={6} lg={4} md={3} sm={3} sx={{paddingLeft:"18px"}}>
        <Typography fontFamily="poppins" 
        // onClick={(e)=> { if( data.email.length != 0,data.phone.length != 0 ) hc(e,index+1)}} sx={{cursor: 'pointer'}}
        >
          Save & Next
          <IconButton onClick={onNext}>
            <ArrowForwardIcon />
          </IconButton >
        </Typography>
      </Grid>
   
    </Address.Provider>
  );
}
