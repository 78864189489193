import React, { useContext, useState, useEffect } from "react";
import {
  Typography,
  Card,
  Paper,
  Button,
  Grid,
  Box,
  Avatar,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EditForm from './EditForm';
import EditAbout from "./EditAbout";
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from "react-router-dom";
import AAA from '../images/aaa.jpg';
import { UserContext } from "ContextFiles";
import axios from 'axios';

export default function About() {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const [data, setData] = useState({
    fname:"",
    lname:"",
    headline:"",
    summary:"",
    phone:"",
    email:"",
    city:"",
    state:"",
    country:"",
    educationArr:"",
    trainingArr:"",
    workArray:"",
    achivementsArr:"",
    hobbies:"",
    languageArr:"",
    SkillArr:"",
    imageUrl:"",
    imageFile:""});

  useEffect(() => {
    axios({
      method: "post",
      url: "https://cms.keewesolutions.com/opencase/get",
      data: {
        userId: user.id
      }
    }).then((response) => {
      const result = response.data.Item;
      if(result!=undefined){
        setData(result)
      }
      
    })
  }, [])




  const [aboutMe, setAboutMe] = useState('Write something about yourself')
  const [headName, setHeadName] = useState('Your headline goes here...')
  const [cityName, setCityName] = useState('City')
  const [stateName, setStateName] = useState('State')
  const [countryName, setCountryName] = useState('Country')
  const [emailName, setEmailName] = useState('')
  const [skill, setSkill] = useState("")
  const [skillArr, setSkillArr] = useState([]);
  return (
    <Grid container padding="2% 4%">
      <Grid container>
        <Grid item xs={6} lg={3} md={3} sm={3}>
        <Avatar sx={{ height: 150, width: 150 }} src={data.imageUrl} />
        </Grid>

        <Grid item xs={12} lg={5} margin="3% 1%">
          <Typography
            variant="h5"
            fontFamily="poppins"
            color="#000000"
            fontWeight="bold"
            marginBottom="2%"
          >
            {user.name}
          </Typography>
          <Typography fontFamily="poppins" color="#4a4a4a" marginBottom="1%">
            {headName}
          </Typography>
          <Grid item xs={12} display="flex">
            <LocationOnIcon />
            <Typography fontFamily="poppins" color="#4a4a4a">
              {cityName}, {stateName}, {countryName}
            </Typography>
          </Grid>
          <Typography fontFamily="poppins" color="#4a4a4a" marginBottom="1%">
            {emailName}
          </Typography>
          <IconButton onClick={() => navigate('/finalresume')}><Typography
            fontFamily="poppins"
            color="#177acc"
            fontWeight="bold"
          // onClick={() => navigate('./finalresume')}
          >
            Edit my resume
          </Typography>

          </IconButton>

          <IconButton onClick={() => {
            if (data.fname != '') { navigate('/user/resume') }
            else { navigate('/fillfirst') }
          }}><Typography
            fontFamily="poppins"
            color="#177acc"
            fontWeight="bold"
          // onClick={() => navigate('./finalresume')}
          >
              View my resume
            </Typography>

          </IconButton>
        </Grid>

        <Grid item xs={12} lg={2} margin="2% 1%">
          <EditIcon onClick={() => navigate('/finalresume')} style={{ cursor: 'pointer' }} />

          {/* <EditForm setHead={setHeadName} setCity={setCityName} setState={setStateName} setCountry={setCountryName}
        setEmail={setEmailName}/> */}

        </Grid>

        <Grid xs={12} lg={8} padding="1%">
          <hr width="140%" />
        </Grid>

        <Grid item xs={12} lg={8} padding="2%">
          <Typography fontFamily="poppins" variant="h5" marginBottom="2%">
            About
          </Typography>
          <Typography color="#4a4a4a" fontFamily="poppins" marginBottom="2%">
            {aboutMe}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={2} margin="2% 3%">
          <EditAbout setAbout={setAboutMe} />
        </Grid>

        <Grid xs={12} lg={8} padding="1%">
          <hr width="140%" />
        </Grid>

        <Grid item xs={12} lg={8} padding="2%">
          <Typography fontFamily="poppins" variant="h5" marginBottom="2%">
            Your Top 3 skills
          </Typography>
          <Typography fontFamily="poppins" variant="h7" marginBottom="2%">
            Highlight your top skills to the recruiters...
          </Typography>
          <Typography color="#4a4a4a" fontFamily="poppins" marginBottom="2%">
            <div>
              {skillArr.map((skill, skillIndex) => {
                return (
                  <Box key={skillIndex + skill} >
                    {skill}
                    <IconButton sx={{ color: "grey" }} onClick={() => {
                      let newSkillArr = [...skillArr]
                      newSkillArr.splice(skillIndex, 1)
                      setSkillArr(newSkillArr)
                    }}>
                      <ClearIcon sx={{ color: 'grey' }} />
                    </IconButton>
                  </Box>
                )
              })}
            </div>
          </Typography>
        </Grid>
        <Grid item xs={12} lg={2} margin="2% 3%">
          <EditSkill setSkill={setSkill} skill={skill} skillArr={skillArr} setSkillArr={setSkillArr} />
        </Grid>

        <Grid xs={12} lg={8} padding="1%">
          <hr width="140%" />
        </Grid>
      </Grid>
    </Grid>
  );
}




export function EditSkill({ skillArr, setSkillArr, skill, setSkill }) {
  const [open, setOpen] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton>
        <EditIcon onClick={handleClickOpen} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle fontFamily="poppins">
          <b>Edit your top 3 skills</b>
        </DialogTitle>

        <DialogContent>
          <DialogContentText fontFamily="poppins">
            Start typing your top 3 skills to shine before the recruiters.
          </DialogContentText>
          <TextField
            onChange={(e) => {
              setSkill(e.target.value)
            }}
            // autoFocus
            margin="dense"
            id="name"
            label="Enter your skills"
            type="text"
            fullWidth
            variant="standard"
            fontFamily="poppins"
            value={skill}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            sx={{ borderRadius: "40px", width: "100px" }}
            onClick={() => {
              let newSkillArr = [...skillArr]
              newSkillArr.push(skill)
              setSkillArr(newSkillArr)
              setSkill('')
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
