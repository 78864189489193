import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography, Button, Card } from "@mui/material";
import About from "./About";
import PlayIcon from "../images/PlayIcon.png";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { UserContext } from "ContextFiles";
import axios from "axios";
import { Video } from "react-feather";

export default function AboutFinal() {
  const { user } = useContext(UserContext);
  const [videoUrl,setVideoUrl]=useState("");
  useEffect(()=>{
   axios({
      method:'post',
      url:"https://cms.keewesolutions.com/opencase/getIntroVideo",
        data: {
          userId: user.id
        }
      }).then((res)=>{
        if(res.data.Item!=undefined){
          setVideoUrl(res.data.Item.videoUrl)
        }
        
      })

  },[])

 

	
  return (
    <Card>
      <Grid container display="flex">
        <Grid item xs={12} lg={7.5}>
          <About />
        </Grid>

        <Grid item xs={12} lg={4}>
          <VideoComponent videoUrl={videoUrl} />
        </Grid>
      </Grid>
    </Card>
  );
}

//video component
    export function VideoComponent({videoUrl}) {
 
  
  return (
    <Grid container>
      {videoUrl!=""?<Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="center"
        sx={{ marginTop: "10%" }}
      >
        <video src={videoUrl} controls >
          <track default
           kind="captions"
           srclang="en"
           src={""} />
    Sorry, your browser doesn't support embedded videos.
    </video>
      </Grid>:""}
      <>
      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="center"
        sx={{ marginTop: "10%" }}
      >
        <img src={PlayIcon} alt="button-icon" />
        
      </Grid>

      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="center"
        sx={{ marginTop: "10%" }}
      >
        <Typography fontFamily="poppins">
          Standout with your Intro Video
        </Typography>
      </Grid>

      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="center"
        sx={{ marginTop: "5%" }}
      >
        <Typography
          fontFamily="poppins"
          color="#4a4a4a"
          sx={{ textAlign: "justify" }}
        >
          Present yourself and grab 10x attention from companies
        </Typography>
      </Grid>

      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="center"
        sx={{ marginTop: "5%" }}
      >
        <Typography fontFamily="poppins">
          Tips to short and specific Intro-video
        </Typography>
      </Grid>

      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="center"
        sx={{ marginTop: "5%", marginBottom: "2%" }}
      >
        <EditVideo />
      </Grid>
      </>
    </Grid>
  );
}

//edit video function

const Input = styled("input")({
  display: "none",
});

export function EditVideo() {
  const {user}=useContext(UserContext);
  const [data,setData] = useState({videoUrl:""})
  const [open, setOpen] = React.useState(false);
  const onNext=async ()=>{
		
				const formData=new FormData();
				formData.append('userId',user.id);
				formData.append('videoUrl',data.videoUrl);
			  await	axios({
					method:"post",
					url:"https://cms.keewesolutions.com/opencase/uploadVideo",
					data:formData
				}).then((response)=>{
        })
			}
			
	//	})
	//	}

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        sx={{ borderRadius: "20px", width: "400px", "&:hover":{backgroundColor:'#1976d2', color: '#fff'} }}
        onClick={handleClickOpen}
      >
        Add your Intro Video
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle fontFamily="poppins">
          <b>Add your introductory video</b>
        </DialogTitle>

        <DialogContent>
          <DialogContentText fontFamily="poppins" marginBottom="6%">
            Add your introductory video to shine before the recruiters.
          </DialogContentText>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="center"
            marginBottom="8%"
          >
            <img src={PlayIcon} alt="sadfghf" />
          </Grid>
          <Grid
            container
            item
            sx={12}
            direction="row"
            justifyContent="center"
            marginBottom="4%"
          >
            <label >
            <input
								accept="video/*"
								type="file"
								style={{
									display: 'none'
								}}
								onChange={ e => {

									setData({...data,videoUrl:e.target.files[0]});
								}}
							/>
              <Button
                variant="outlined"
                component="span"
                sx={{ borderRadius: "20px", width: "400px", "&:hover":{backgroundColor:'#1976d2', color: '#fff'} }}
              >
                Add your Intro Video
              </Button>
            </label>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            sx={{ borderRadius: "40px", width: "100px" }}
             onClick={onNext}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
