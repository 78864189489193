import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AvatarImage from '../../assets/images/studash/incubation/AvatarImage.png'
import Stage2N from './Stage2N';
import FormN from './FormN';
import Stage3N from './Stage3N';
import Stage4N from './Stage4N';
import Stage5N from './Stage5N';
import { Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ideaArray } from './Context';
import { useState } from 'react';
import { UserContext } from 'ContextFiles';
import { useEffect } from 'react';
import axios from 'axios';

const steps = [
    {
        label: 'Stage 1',
        description: <FormN />
    },
    {
        label: 'Stage 2',
        description: <Stage2N />
    },
    {
        label: 'Stage 3',
        description: <Stage3N />
    },
    {
        label: 'Stage 4',
        description: <Stage4N />
    },
    {
        label: 'Stage 5',
        description: <Stage5N />
    }
];

export default function VerticalLinearStepper() {
    
    const [activeStep, setActiveStep] = React.useState(0);

    const {user}=React.useContext(UserContext);

    useEffect(() => {
        setData({...data, email : user.email})
    }, [])
    

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === steps.length-1){
           
            handleSubmit()
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleSubmit =async () => {
       
    
        const formData=new FormData();
        formData.append('category',data.category);
        formData.append('completionStatus',data.completionStatus);
        formData.append('email',data.email);
        formData.append('expansion',data.expansion);  
        formData.append('ideaImage',data.ideaImage);
        formData.append('ideaVideo',data.ideaVideo);
        formData.append('modal',data.modal);
        formData.append('outline',data.outline);
        formData.append('projectDescription',data.projectDescription);
        formData.append('projectName',data.projectName);
        formData.append('revenue',data.revenue);
        formData.append('subCategory',data.subCategory);
        
        await axios({
            method:"post",
            url:"https://cms.keewesolutions.com/incubation/uploadidea",
            data:formData
        }).then((response)=>{
            
            
        })
    };

    const [data, setData] = React.useState({});

    return (
        <>  
        <ideaArray.Provider value = {{data,setData}}>
        <div style={{display: 'flex'}}>
            <Grid container lg={6}>
                <Grid item lg={12}>
                    <Typography
                        sx={{
                            fontFamily: 'AktivGrotesk-Medium',
                            fontSize: '48px',
                            lineHeight: '56px',
                            letterSpacing: '0.03em'
                            // paddingLeft: '50%'
                        }}
                    >
                        Creating the
                        <br />
                        <span style={{ color: 'blue', fontFamily: 'Mixed' }}>NEXT BIG</span> STARTUP
                    </Typography>
                </Grid>
            </Grid>
            <Grid container lg={6}>
                <Grid item lg={12} sx={{ textAlign: 'right', margin: '2% 0 0 0'}}>
                    <NavLink to={'/submitted-ideas'} style={{ textDecoration:'none'}}>
                        <Button variant='contained' >Submitted Ideas</Button>
                    </NavLink>
                </Grid>
            </Grid>
            </div>
            <Grid container lg={12}>
            <Grid item lg={12} xs={12}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel optional={index === 4 ? <Typography variant="caption">Last step</Typography> : null}>
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                <Typography>{step.description}</Typography>
                                <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Continue'}
                                            
                                        </Button>
                                        <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                                            Back
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                            Reset
                        </Button>
                    </Paper>
                )}
            </Grid>
            </Grid>
            </ideaArray.Provider>
        </>
    );
}
