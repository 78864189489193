import { Typography, Grid, Dialog, DialogContent, IconButton } from "@mui/material";
import { useState, useEffect } from 'react';
import { Box } from "@mui/system";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

const Notify = () => {
    const [arr, setArr] = useState([]);
    const [openMedia, setOpenMedia] = useState(false);
    const [mediaSrc, setMediaSrc] = useState('');
    const [mediaType, setMediaType] = useState('');


    useEffect(() => {
        // Fetch data from your backend API
        const fetchData = async () => {
            try {
                const response = await fetch('https://cms.keewesolutions.com/courses/latestupdate'); // Adjust the URL as needed
                const result = await response.json();
                if (result.Items) {
                    const formattedData = result.Items.map(item => ({
                        display: 'none',
                        title: item.title,
                        description: item.description,
                        icon: AddCircleOutlineIcon,
                        media: item.media,
                        type: item.type,
                        date: parseInt(item.date, 10)
                    }));

                    const sortedData = formattedData.sort((a, b) => b.date - a.date).slice(0,10);

                    setArr(sortedData);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleMediaOpen = (src, type) => {
        setMediaSrc(src);
        setMediaType(type);
        setOpenMedia(true);
    };

    const handleMediaClose = () => {
        setOpenMedia(false);
        setMediaSrc('');
        setMediaType('');
    };

    return (
        <Grid container lg={12} xs={11} spacing={2}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontFamily: 'Roboto', marginTop: '20px', fontSize: '16px', fontWeight: '700', lineHeight: '24px', color: '#000' }}>Latest Update</Typography>
            </Grid>
                {arr.map((item, index) => {
                    // if (index > 5) return null; // Only show the first 3 items
                    return (
                        <Grid item key={item.title + index} sx={{ width: '100%', border: '1px solid #E3E5E7', marginLeft: '20px', marginTop: '20px', boxShadow: '0px 5px 5px 0px #e7e1e1' }}>
                            <Grid container>
                                <Grid item lg={2} xs={12}>
                               {item.type === 'image' ? (
                           <Box component="button" onClick={() => handleMediaOpen(item.media, 'image')} sx={{ width: '100%', cursor: 'pointer', border: 'none', background: 'transparent', padding: 0 }}>
                        <img 
                        src={item.media}
                        alt="content"
                        style={{ width: '40%', maxWidth: '40%' }}
                        />
                        </Box>
                         ) : (
                        <Box component="button" onClick={() => handleMediaOpen(item.media, 'video')} sx={{ width: '100%', cursor: 'pointer', border: 'none', background: 'transparent', padding: 0 }}>
                        <video
                        style={{  width: '40%', maxWidth: '40%' }}
                        controls
                        muted
                        >
                        <source src={item.media} type="video/mp4" />
                        Your browser does not support the video tag.
                        </video>
                        </Box>
                        )}
                        </Grid>
                                <Grid item lg={8} xs={12}>
                                    <Grid display="flex" alignItems="center">
                                <Typography sx={{ textAlign: 'left', fontFamily: 'Roboto', marginTop: '10px', fontSize: '16px', fontWeight: '400', color: '#298BD0', marginLeft: '20px', width: '100%', marginBottom: '10px' }}>
                                    {item.title}
                                </Typography>
                                <IconButton onClick={() => {
                                    let newStateArr = [...arr];
                                    newStateArr[index].display = newStateArr[index].display === "flex" ? "none" : "flex";
                                    newStateArr[index].icon = newStateArr[index].display === "flex" ? RemoveCircleOutlineIcon : AddCircleOutlineIcon;
                                    setArr(newStateArr);
                                }}>
                                    <item.icon />
                                </IconButton>
                            </Grid>
                            <Typography sx={{ fontWeight: '500', fontFamily: 'Roboto', textAlign: 'left', marginLeft: '20px', marginTop: '2px', width: '95%', marginBottom: '10px', display: item.display, marginRight: '10px', color: '#777F8A' }}>
                                {item.description}
                            </Typography>

                            {/* Display image or video */}
                           {/* Instead of making the image and video directly clickable, wrap them in a button for accessibility */}
                           </Grid>
                </Grid>
            </Grid>


                        
                    );
                })}
            

            {/* Dialog for displaying media */}
            <Dialog open={openMedia} onClose={handleMediaClose} >
                <DialogContent>
                    <IconButton onClick={handleMediaClose} sx={{ position: 'absolute', right: 10, top: 10 }}>
                        <CloseIcon />
                    </IconButton>
                    {mediaType === 'image' && <img src={mediaSrc} alt="content" style={{ width: '100%' }} />}
                    {mediaType === 'video' && (
                        <video controls style={{ width: '100%'}}>
                            <source src={mediaSrc} type="video/mp4" />
                            <track kind="captions" srcLang="en" label="English captions" default />
                            Your browser does not support the video tag.
                        </video>
                    )}
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

export default Notify;
