import React, { useContext } from "react";
import { ResumeContext } from "ResumeContext";
import { Card,Box, Grid, Typography } from "@mui/material";



function WorkComponent({ work}) {
  return (
    <div>
      <Card>
        <Grid container>
     {work?.map((item)=>
    <Grid item lg={6} xs={12}>
      <Card sx={{padding:'2%',backgroundColor:'#f3f5fb',margin:'8%'}}>
      <Typography sx={{fontFamily:'poppins',fontSize:'20px',fontWeight:'500'}}> {item.designation} at {item.company}</Typography>
      <Typography sx={{fontFamily:'poppins',fontSize:'18px',fontWeight:'200',marginTop:'4%'}}> {item.employmentType}</Typography>
      <Typography sx={{fontFamily:'poppins',fontSize:'16px',fontWeight:'200',marginTop:'4%'}}>
        {item.start} - {item.end}
      </Typography>
      <Typography sx={{fontFamily:'poppins',fontSize:'16px',fontWeight:'200',marginTop:'4%'}}>{item.location}</Typography>
      </Card>
      </Grid>)}
      </Grid>
      </Card>
    </div>
  );
}
export default WorkComponent;
