import React, { useState,useEffect } from 'react';
import { AssesmentContext } from 'ContextFiles';
import Mcq from './Card';
import Light from './light';
import axios from 'axios';
import { UserContext } from 'ContextFiles';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';

function AssesmentFinal() {
	const [assesmentDetails, setAssesmentDetails] = useState({
		questions:[],
		responses: {},
		status: {},
		score:0
	});
	const location = useLocation()
	const userContext = useContext(UserContext)
	useState(() => {
		
		axios({
			url: 'https://cms.keewesolutions.com/assessment',
			method: "POST",
			data: {
				userId: userContext.user.id,
				courseId: location.state.courseId
			}
		}).then(res => {
			
			let newAssesmentDetails = { ...assesmentDetails }
			newAssesmentDetails.questions = res.data.response.questions.questions
			
			setAssesmentDetails(newAssesmentDetails)

		}).catch(err => console.log(err))
	})

	return (
		<div>
			<AssesmentContext.Provider value={{ questions: assesmentDetails.questions, responses: assesmentDetails.responses, status: assesmentDetails.status, score:assesmentDetails.score, setNewContext: setAssesmentDetails }}>
				<Mcq userId={userContext.user.id} courseId={location.state.courseId}/>
			</AssesmentContext.Provider>
		</div>
	);
}

export default AssesmentFinal;
