import React, { useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import imgs from './Previews.jpg';
import './Stage5.css';
import { TextareaAutosize } from '@mui/material';
import { boxSizing, height } from '@mui/system';
import Stage2N from './Stage2N';
import idea from '../../assets/images/icons/idea.png'
import { ideaArray } from './Context';
import { formContext } from "./Context";
import { stepNumber } from "./Context";
import generateKey from './generateKey';




function FormN({setShowOutlineForm}, pic) {

    const formData = useContext(formContext)
    const counter = React.useContext(stepNumber)
    const [ideaImage, setIdeaImage] = useState(null)
    const {data, setData} = React.useContext(ideaArray)

  

    const handleUpload = (event) => {
        

        let file = event.target.files[0];
    let reader = new FileReader();

    const fileExtension = file.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "png"];
    
    reader.readAsDataURL(event.target.files[0]);

      
    }


    
    const [imageUrl, setImageUrl] = useState(null)
    const [courseImageFile, setCourseImageFile] = useState('')


    
    return (
        <Grid container>

                <Grid lg={12} xs={11}sx={{margin:'0 0 0 10%'}}>  
                        <Grid lg={7} xs={11}sx={{justifyContent: 'center', alignItems: 'center'}}>
                            <FormControl fullWidth variant="standard">
                                <Typography fontSize={"16px"} float={'left'} textAlign={'initial'}>
                                    Category*
                                </Typography>
                                <TextField
                                    onChange={(e) => {
                                        setData({...data,category:e.target.value})
                                    }}
                                    sx={{  backgroundColor: "#fffdfd", marginBottom:"3%" }}
                                    // margin="dense"
                                    variant="outlined"
                                    label="eg: Information Technology"
                                    id="Title"
                                    value={data.category}
                                />
                            </FormControl>
                        </Grid>
                        <Grid lg={7} xs={11}>
                            <FormControl fullWidth variant="standard">
                                <Typography fontSize={"16px"} textAlign={'initial'}>
                                    Sub-category/Domain*
                                </Typography>
                                <TextField
                                    onChange={(e) => {
                                        // setSubCategory(e.target.value)
                                        setData({...data,subCategory:e.target.value})
                                    }}
                                    sx={{ backgroundColor: "#fffdfd", marginBottom:"3%" }}
                                    // margin="dense"
                                    variant="outlined"
                                    label="eg: Ed-Tech"
                                    id="Title"
                                    value={data.subCategory}
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid lg={7} xs={11}>
                            <FormControl fullWidth variant="standard">
                                <Typography fontSize={"16px"} textAlign={'initial'}>
                                    Idea Name*
                                </Typography>
                                <TextField
                                    onChange={(e) => {
                                        // setIdea(e.target.value)
                                        setData({...data,projectName:e.target.value})
                                    }}
                                    sx={{ backgroundColor: "#fffdfd", marginBottom:"3%" }}
                                    // margin="dense"
                                    variant="outlined"
                                    label="eg: Online Education"
                                    id="Title"
                                    value={data.projectName}
                                />
                            </FormControl>
                        </Grid>
                        <Grid lg={7} xs={11}>
                            <FormControl fullWidth varient="standard">
                                <Typography fontSize={"16px"} textAlign={'initial'}>
                                    Project Details*
                                </Typography>
                                <TextField
                                    onChange={(e) => {
                                        // setProject(e.target.value)
                                        setData({...data,projectDescription:e.target.value})
                                    }}
                                    sx={{ backgroundColor: "#fffdfd", marginBottom:"3%" }}
                                  
                                    multiline
                                    rows="3"
                                    margin="dense"
                                    variant="outlined"
                                    label="Write about the Project in detail"
                                    id=" Description"
                                    value={data.projectDescription}
                                />
                            </FormControl>
                        </Grid>
                        
                        <div>
                            <Typography fontSize={"16px"} sx={{ marginBottom: "1%", textAlign: 'match-parent' }} >
                                Upload Thumbnail*
                            </Typography>

                            {
                                (data.ideaImage === null) || (data.ideaImage === undefined) ?
                                    (
                                        <img src={imgs}
                                            alt=""
                                            style={{
                                                width: "250px",
                                                height:"200px",
                                                objectFit: "contain"
                                            }} />
                                    )
                                    :
                                    (
                                        <img src={imageUrl}
                                            alt=""
                                            style={{
                                                width: "250px",
                                                height:"200px",
                                                objectFit: "contain"
                                            }} />
                                    )
                            }
                        </div>
                        <div>
                            <label>
                            <input accept="image/*" type="file" style={{
                                display: 'none',
                                backgroundColor:'#fffdfd'
                            }}
                            onChange={e=>{
                                setImageUrl(URL.createObjectURL(e.target.files[0]))
                                // setCourseImageFile(e.target.files[0])
                                setData({...data,ideaImage:e.target.files[0]})
                                handleUpload(e)
                            }}
                            
                            />
                            <Button
                        
                           

                                // onClick={handleNext}
                                variant="contained"
                                // fontSize="large"
                                disabled={courseImageFile == null ? true : false}
                                sx={{
                                    marginTop: "10px"
                                }}
                                // variant="contained"
                                component="span"

                            >
                                Upload

                            </Button>
                            </label>
                        </div>
                    </Grid>
              
            </Grid>
        
    );
}

export default FormN;