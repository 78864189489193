import React, {useState} from "react";
import "./feed.css";
import { Grid, Typography, ImageList, Button } from "@mui/material";
import styled from "@emotion/styled";
import ResearchCard from "./ResearchCard";
import SearchField from "./SearchField";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Plant from '../images/Plant.png';
import Automation from '../images/Automation.png';
import Brain from '../images/Brain.png';
import ModalPlant from '../images/ModalPlant.png';
import { Link, NavLink, useNavigate } from "react-router-dom";


const Button1 = styled(Button)({
  color: '#1a50b2'
})



const Typography1 = styled(Typography)({
  color: "white",
  marginTop: "20px",
});


export default function ResearchFeed() {

  let arr = []
  let c1 = {
    company: 'CrestBell',
    picture: Plant,
    heading: 'Artificial Intelligence(AI) in E-Commerce website',
    content: 'In search of aspirants who are interested in a research project that is based on the AI implimentaion in E-Commerce website and role of technologies in enhancing E-Commerce',
    date: "25th Aug'22 ",
    allowance: 'Rs 25,000',
    ModalPicture: ModalPlant,
    ModalHead: 'Impact of Climate Change on Cash crops and Fisheries',
    modalcontent: 'Climate change is bound to increase pressure on land and the water bodies while deteriorating yield growth, with the anomaly of only a few regions. Productivity is predicted to decrease for about half of the fishing industry globally as a result of climate change. Moreso, its furthering impacts on stock productivity and on fish migration patterns.Over time, aquaculture producers, farmers, and fishers will be under immense pressure to evolve their practices and technologies to meet these challenges. It is our aim to make a dent in this rapidly expanding problem.'


  }

  let c2 = {
    company: 'Good Learning Softwware Services',
    picture: Automation,
    heading: 'Future of Robotics, AI, and Automation',
    content: 'Hiring research students enthusiastic about robotics, AI and automation. The study revolves around the current achievements in the field of robotics and prospects for its future.',
    date: "3rd Sept'22",
    allowance: 'Rs 30,000',
    ModalPicture: ModalPlant,
    ModalHead: 'Future of Robotics, AI, and Automation',
    modalcontent: 'Engineering designs have been radically altered by the utilization of AI and machine learning in the form of generative design. Generative design uses AI algorithms to repeatedly optimize designs within set parameters. This means that concepts are no longer restricted by the limits of human imagination and calculation. Thus, reducing the chances of human error. The study further explores some existing inventions and makes informative predictions for the future. '



  }

  let c3 = {
    company: 'Learn Ahead',
    picture: Brain,
    heading: 'Types of Nonverbal Communication',
    content: 'Looking for candidates interested in participating in a study of the types of non-verbal communication. All the learners can perform research as an individual or in groups. ',
    date: "1st Nov'22",
    allowance: 'Rs 20,000',
    ModalPicture: ModalPlant,
    ModalHead: 'Types of Nonverbal Communication',
    modalcontent: 'Non-verbal communication plays a significant role in how we convey information and meaning to others, as well as how we perceive the actions of those around us. Specialists have discovered that daily we respond to thousands of nonverbal cues and behaviours including postures, facial expressions, eye gaze, gestures, and tone of voice. From our handshakes to our hairstyles, nonverbal details reveal who we are and impact how we relate to other people. Therefore, the research takes a deep dive into the various types of nonverbal communication and their impact on our relationships. '
  }

  for (let i = 0; i < 1; i++) {
    arr.push(c1)
    arr.push(c2)
    arr.push(c3)
  }

  const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const [searchTerm,setSearchTerm] = useState('')

  return (

    <Grid container>
      <Grid container sx={{ marginBottom: '2%' }}>
        <Grid item xs={12} lg={8} />
        <Grid item xs={12} lg={4} sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <NavLink to={'/home'} style={{ textDecoration: 'none' }}><Button1 sx={{ height: "40px", width: "100px", fontSize: '1.1rem', backgroundColor: '#EDF1FD' }}><b>Home</b></Button1></NavLink>
          <NavLink to={'/researchfeed'} style={{ textDecoration: 'none' }}><Button1 sx={{ height: "40px", width: "100px", fontSize: '1.1rem', backgroundColor: '#EDF1FD' }}><b>Feed</b></Button1></NavLink>
          <NavLink to={'/researchprofile'} style={{ textDecoration: 'none' }}><Button1 sx={{ height: "40px", fontSize: '1.1rem', backgroundColor: '#EDF1FD' }}><b>Research Profile</b></Button1></NavLink>
        </Grid>

      </Grid>
      <Grid
        container
        className="back-image"
        sx={{ width: "100%", height: "auto" }}
      >

        <Grid item xs={12} lg={12} className='text-grid'>
          <Grid sx={{ height: '200px' }}>
            <Typography1 sx={{ fontSize: "48px", fontFamily: 'Inter', padding: '3% ', textAlign: 'center' }}>
              <b>Research Feed</b>
            </Typography1>
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={{ margin: '2% 3%' }}>
        <Grid item xs={12} lg={4} sx={{ display: 'flex', marginBottom: '2%' }}>
          <Typography sx={{ marginRight: '4%', marginTop: '2%', fontFamily: 'poppins' }}><b>Sort By:</b></Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date Created"
              inputFormat="MM/dd/yyyy"
              value={value}

              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} lg={3} sx={{ marginLeft: '1%', marginBottom: '2%' }} >
          <SearchField onChange={e=>setSearchTerm(e.target.value)}/>
          {/* {countries.filter((val) => {
            if (searchTerm == "") {
              return val
            }
            else if (val.first_name.toLowerCase().includes(searchTerm.toLowerCase())) {
              return val;
            }
          }).map((val, key) => {
            return <div>{val.first_name} </div>
          })} */}
        </Grid>

        {/* <Grid item xs={12} lg={2} sx={{marginBottom:'2%', marginLeft:'7%'}}>
          <Link to={'/researchprofile'} style={{textDecoration:'none'}}> <Button>Research profile</Button></Link>
        </Grid> */}


      </Grid>

      <Grid item lg={12} xs={12} sx={{ display: 'flex', overflowY: 'hidden' }}>
        <Grid item lg={12} xs={12} sx={{ marginLeft: '2%' }}>
          <ImageList className='scroll' sx={{ display: "flex" }} >
            {arr.map((val, index) => {
              return <ResearchCard key={index} content={val.content} heading={val.heading} date={val.date} allowance={val.allowance} picture={val.picture} company={val.company} modalcontent={val.modalcontent}>
              </ResearchCard>
            })}
          </ImageList>
        </Grid>
      </Grid>
    </Grid>


  )
}



