// assets
import {
    IconCardboards,
    IconDashboard,
    IconDatabase,
    IconFileLike,
    IconOutlet,
    IconBriefcase,
    IconLogout,
    IconSettings
} from '@tabler/icons';


// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        // {
        //     id: 'default',
        //     title: 'Buy Courses',
        //     type: 'item',
        //     path: '/buycourses',
        //     icon: IconCertificate ,
        //     breadcrumbs: false
        // },
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            path: '/dashboard',
            icon: IconDatabase ,
            breadcrumbs: false
        },
        {
            id: 'util-typography',
            title: 'Latest Updates',
            type: 'item',
            path: '/latest-updates',
            icon: IconFileLike,
            breadcrumbs: false
        },
        {
            id: 'current-courses',
            title: 'Current Courses',
            type: 'item',
            path: '/current-courses',
            icon: IconCardboards,
            breadcrumbs: false
        },
        // {
        //     id: 'util-shadow',
        //     title: 'Buy Courses',
        //     type: 'item',
        //     url: '/my-open-case',
        //     breadcrumbs: false
        // },
        {
            id: 'open-case',
            title: 'My Open Case',
            type: 'item',
            path: '/finalresume',
            icon: IconOutlet,
            breadcrumbs: false
        },
        {
            id: 'sample-page',
            title: 'Certificate',
            type: 'item',
            path: '/coursecertificate',
            icon: IconBriefcase,
            breadcrumbs: false,
        },
        {
            id: 'account-setting',
            title: 'Account Setting',
            type: 'item',
            path: '/basicinfo',
            icon: IconSettings,
            breadcrumbs: false
        }
    ]
};

export default dashboard;