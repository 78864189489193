
import Findjobs from './Assemble/Findjobs'
import Browser from "./Assemble/Browsecompanies"
import CrestBelldescription from './Assemble/Jobdescription';
import Searchcompaniesresult from './Assemble/Searchcompaniesresult';
import Companyprofile from './Assemble/Companyprofile';
import DiscoverJobs from "./Assemble/DisvoverJobs";

// import StartPosting from "./NehaBhagat1/StartPosting";
import { Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import * as React from 'react'
import { UserContext } from './ContextFiles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import RegisterCard from './formlogin/components/RegisterCard'
// import Verification from './formlogin/components/Verification'
// import ForgotPassword from './formlogin/components/NewPassword'
// import NewPassword from './formlogin/components/NewPassword'
// import axios from 'axios'

// import Companyprofile from './Assemble/Companyprofile';

export const queryContext = React.createContext({
  value: '',
  setValue: () => { }
})
// import Page2 from './NehaBhagat1/Page2';

function App() {
  const [query, setQuery] = useState('')

  //   const [authState, setAuthState] = useState({
  //     authenticated: false,
  //     user: {},
  // })
  const navigate = useNavigate()


  const [show, setShow] = useState(null)

  useEffect(() => {
    const progressData1 = async () => {
      const details = await axios({
        url: "https://cms.keewesolutions.com/checkProgress",
        data: {
          'userId': JSON.parse(localStorage['keewe.lmsStorage']).user.id
        },
        method: "POST"
      })
   
      const progressArr = details.data.Items
      for (let i in progressArr) {
        let progressData = progressArr[i]
       
        const { progressRecord } = progressData
       
        const len = Object.keys(progressRecord).length
        // const ones = 0
        const valArray = Object.values(progressRecord)
       
        const ones = valArray.filter(val => val === 1).length

       
        const ans = Math.round((ones / len) * 100)
        if (parseFloat(ans).toFixed(2) >= 80) {
          setShow(true)
          break;
        } else {
          setShow(false)
        }


      }

    }
    progressData1()
  }, [])


  return (
    show === null ? 
      <queryContext.Provider value={{
        value: query,
        setValue: setQuery
      }}>
        <Routes>
          <Route path="/" element={<DiscoverJobs />} />

          <Route path="/findjobs" element={<Findjobs />} />
          <Route path="/searchcompanies" element={<Browser />} />
          <Route path="/companiesresult" element={<Searchcompaniesresult />} />
          {/* <Route path="/jobdescription" element={<Jobdescription />} /> */}
          <Route path="/companyprofile" element={<Companyprofile />} />
        </Routes>
      </queryContext.Provider> : <p>You Will Get The Access Only After Completing 80% Of Course</p>

  )
}


export default App;
