import React, { useContext, useEffect } from "react";
import { Typography, Grid, Card, TextField, Button } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from '@mui/material/Stack';
import imgs from '../images/Previews.jpg';
import { useState } from "react";

import styled from "@emotion/styled";

export default function ResearchDetails({ setResearchdetails, buttonText1, buttonText2 }) {
    const formData = new FormData()
    const Typography1 = styled(Typography)({
        fontFamily: 'Inter',
        marginBottom: '10px'

    })


    const TextField1 = styled(TextField)({
        marginBottom: '20px',
        width: '90%'
    })
    const handleNext = () => {
        if (topic.length != 0 &&
            allowance.length != 0 &&
            deadline.length != 0 &&
            research.length != 0 &&

            fileObj.length != 0) {


            setResearchdetails(false)

        } else {
            alert("please complete all the fields")
        }
    }
    const handleUpload = (file) => {
        formData.set("courseImage", file)
    }



    const [topic, setTopic] = useState('')
    const [research, setResearch] = useState('')
    const [category, setCategory] = useState('')
    const [allowance, setAllowance] = useState('')
    const [deadline, setDeadline] = useState('')
    const [courseImage, setCourseImage] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [fileObj, setFileObj] = useState('')
    return (
        <Grid container>
            

            <Grid item xs={12} lg={12}
                sx={{ textAlign: 'center', marginY: '1%' }}
            >
                <Typography variant='h3'><span style={{ color: '#1a50b2' }}>Research</span> Details</Typography>
            </Grid>

            <Card sx={{
                margin: '0% 10%',
                padding: '2% 10%'

            }}>
                <Grid container >

                    <Grid item xs={12} lg={6}>

                        <Typography1><b>Topic of Research*</b></Typography1>
                        <TextField1
                            onChange={(e) => {
                                setTopic(e.target.value)
                            }}
                            id='outlined-basic'
                            label='Topic of Research'
                            variant='outlined'
                            value={topic}
                        />
                    </Grid>

                    <Grid item xs={12} lg={6}>

                        <Typography1><b>Category of Research*</b></Typography1>
                        <MultipleSelectPlaceholder text='Category of Research'
                            onChange={(e) => {
                                setCategory(e.target.value)
                            }} />
                    </Grid>

                    <Grid item xs={12} lg={6}>

                        <Typography1><b>Details of Research Topic*</b></Typography1>
                        <TextField1
                            onChange={(e) => {
                                setResearch(e.target.value)
                            }}
                            id='outlined-basic'
                            label='Details of Research Topic'
                            variant='outlined'
                            value={research}
                        />
                    </Grid>

                    <Grid item xs={12} lg={6}>

                        <Typography1><b> Research Allowance*</b></Typography1>
                        <TextField1
                            onChange={(e) => {
                                setAllowance(e.target.value)
                            }}
                            id='outlined-basic'
                            label='Research Allowance'
                            variant='outlined'
                            value={allowance}
                        />
                    </Grid>

                    <Grid item xs={12} lg={6}>

                        <Typography1><b>Deadline for Research*</b></Typography1>
                        <TextField1
                            onChange={(e) => {
                                setDeadline(e.target.value)
                            }}
                            id='outlined-basic'
                            label='Last date for submission of Research'
                            variant='outlined'
                            value={deadline}
                        />
                    </Grid>

                    <Grid item xs={12} lg={6}>


                    </Grid>

                    <Grid item xs={12} lg={6}>

                        <Typography1 sx={{marginTop:'2%'}}><b>Thumbnail of Research Topic*</b></Typography1>
                       
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <label htmlFor="contained-button-file">
                                <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={(e) => {
                                    formData.set("previewImage", e.target.files[0])
                                    setImageUrl(URL.createObjectURL(e.target.files[0]))
                                }} />
                                <Button variant="contained" component="span" sx={{ marginBottom: '20px', backgroundColor: '#1a50b2',  }}>
                                    {buttonText1}
                                </Button>
                            </label>

                        </Stack>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <div style={{ marginTop: "12px", marginBottom: "24px" }}>
                            <div>
                                <Typography fontSize={"16px"} sx={{ marginBottom: "12px" , fontFamily:'inter'}} >
                                <b>
                                  Course Image*
                                </b>
                                    
                                </Typography>
                                {
                                    imageUrl === null ?
                                        (
                                            <img src={imgs}
                                                alt="asdf"
                                                style={{
                                                    width: "300px",
                                                    height: "200px",
                                                    objectFit: "contain"
                                                }} />
                                        )
                                        :
                                        (
                                            <img src={imageUrl}
                                                alt="asdf"
                                                style={{
                                                    width: "300px",
                                                    height: "200px",
                                                    objectFit: "contain"
                                                }} />
                                        )
                                }
                            </div>
                            <div>
                                <label>
                                    <input accept="image/*" type="file" style={{
                                        display: 'none'
                                    }}
                                        onChange={e => {
                                        
                                            setImageUrl(URL.createObjectURL(e.target.files[0]))
                                            setFileObj(e.target.files[0])
                                        }}

                                    />

                                </label>
                            </div>
                        </div>

                    </Grid>

                    <Grid item xs={12} lg={6}>

                        <Typography1><b>Guidelines and Complete Details of Research*</b></Typography1>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <label>
                                <Input accept=".pdf, application/pdf" multiple type="file" onChange={e => {

                                    formData.set("pdf", e.target.files[0])
                                }} />
                                <Button variant="contained" component="span" sx={{ marginBottom: '20px', backgroundColor: '#1a50b2',  }}>
                                    {buttonText2}
                                </Button>
                            </label>

                        </Stack>
                    </Grid>

                    <Grid item xs={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant='contained'
                            sx={{ backgroundColor: '#1a50b2', }}
                        >
                            Save
                        </Button>
                    </Grid>






                </Grid>
            </Card>
        </Grid>
    )
}


// Select component

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = ['Computers', 'Mechanical', 'Management', 'Finance', 'Electrical', 'Textile', 'Sales', 'Electronics', 'Agriculture', 'HR', 'Engineering', 'Marketing', 'Animal Husbandary'];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export function MultipleSelectPlaceholder({ text }) {
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    return (
        <div>
            <FormControl
                sx={{ width: '90%', marginBottom: "3%" }}
            >
                <Select

                    displayEmpty
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <Typography>{text}</Typography>;
                        }

                        return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                >
                    <MenuItem disabled value="">
                        <em>{text}</em>
                    </MenuItem>
                    {names.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}


// Upload Button

const Input = styled('input')({
    display: 'none',
});

export function UploadButtons({ text2 }) {
    return (
        <>

        </>
    );
}

