import * as React from 'react';
import { useState,useContext,useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BasicInfo from './BasicInfo';
import Contact from './Contact';
import WorkExperience from './WorkExperience';
import Education from './Education';
import Training from './Training';
import Achievements from './Achievements';
import Hobbies from './Hobbies';
import Languages from './Languages';
import { UserContext } from 'ContextFiles';
import { ResumeContext } from 'ResumeContext';
import Skills from './Skill';



function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && (
				<Box sx={{ p: '6px' }}>
					{/* <Typography> */}
						{children}
					{/* </Typography> */}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

export default function ResumeFile() {
	const {user}=useContext(UserContext);
	const [data,setData]=useState({
		userId:user.id,
		fname:"",
		lname:"",
		summary:"",
		headline:"",
		email:"",
		phone:"",
		imageUrl:"",
		imageFile:"",
		state:"",
		city:"",
		country:"",
		currentaddress:"",
		
		workArray:[],
		educationArr:[],
		
		// projectArr:[],
		trainingArr:[],
		activitiesArr:[],
		languageArr:[],
		SkillArr:[],
	
		achivementsArr:[],
		hobbies:[],
	
	
	  })
  

	


	const [value, setValue] = React.useState(0);

	const handleChange = (e, newValue) => {
		setValue(newValue);
	};


	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		};
	}

	return (
		<ResumeContext.Provider value={{data,setData}}>
			<Box sx={{ }}>
				<Box sx={{ }}>
					<Tabs value={value} variant='scrollable' onChange={handleChange} aria-label="basic tabs example">
						<Tab label="Basic Info" {...a11yProps(0)} />
						<Tab label="Contact" {...a11yProps(1)} />
						<Tab label="Language" {...a11yProps(2)} />
						<Tab label="Skills" {...a11yProps(3)} />
						<Tab label="Work Experience" {...a11yProps(4)} />
						<Tab label="Education" {...a11yProps(5)} />
						<Tab label="Training and Certificates" {...a11yProps(6)} />
						<Tab label="Achievements" {...a11yProps(7)} />
						<Tab label="Hobbies" {...a11yProps(8)} />
					</Tabs>
				</Box>
				<TabPanel value={value} index={0}>
					<BasicInfo hc={handleChange}  index={0} handleChange={handleChange} />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Contact hc={handleChange} index={1} />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<Languages hc={handleChange} index={2} />
				</TabPanel>
				<TabPanel value={value} index={3}>
					<Skills hc={handleChange} index={3} />
				</TabPanel>
				<TabPanel value={value} index={4}>
					<WorkExperience hc={handleChange} index={4} />
				</TabPanel>
				<TabPanel value={value} index={5}>
					<Education hc={handleChange} index={5} />
				</TabPanel>
				<TabPanel value={value} index={6}>
					<Training hc={handleChange} index={6} />
				</TabPanel>
				<TabPanel value={value} index={7}>
					<Achievements hc={handleChange} index={7} />
				</TabPanel>
				<TabPanel value={value} index={8}>
					<Hobbies hc={handleChange} index={8} />
				</TabPanel>
			</Box>
			</ResumeContext.Provider>
	);
}
