import {
  Grid,
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  FormControlLabel,
  Checkbox,
  TextField,
  FormLabel,
  Input,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React, { useContext, useEffect, useState } from "react";
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ResumeContext } from 'ResumeContext';
import axios from 'axios';


const style = {
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,

  marginTop: "30px",
};

export default function WorkExperience({ hc, index }) {

  const { data, setData } = useContext(ResumeContext);


    const onNext=()=>{
        
        axios({
            method:"post",
            url:"https://cms.keewesolutions.com/opencase/put",
            data:{...data
            }
        }).then((response) => {
          hc("", index + 1);
        })
    }


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [start, setstart] = useState("");
  const [start1, setstart1] = useState("");

  const handleChange = (newValue) => {
    const dd = newValue.$D+'/'+(newValue.$M+1)+'/'+newValue.$y
    setstart(
      dd
    );
    setstart1(newValue)
  };
  const [end, setend] = useState("");
  const [end1, setend1] = useState("");

  const handleChange1 = (newValue1) => {
    if (current) {
      setend("current");
      return null;
    }else {
      const e =  newValue1.$D+'/'+(newValue1.$M+1)+'/'+newValue1.$y
      setend(
       e
      );
      setend1(newValue1)
    } 
    
  };
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [location, setLocation] = useState("");
  const [current, setcurrent] = useState(false);

  return (
    <Grid container>
      <Grid item xs={12} marginBottom="1%">
        <Typography
          variant="h5"
          fontFamily="poppins"
          color="#25d366"
          marginBottom="2%"
          fontWeight="bold"
        >
          Work Experience
        </Typography>
      </Grid>
      <Grid item xs={12} marginBottom="3%">
        <Typography variant="h7" fontFamily="poppins" color="#4a4a4a">
          The biggest source of knowledge is experience, share your work
          experience!
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6} md={8} sx={{ marginTop: "20px" }}>
        {data.workArray.map((item,index) => {
          
          return (
            <Box sx={{ border: "1px solid #177acc", width: "90%", marginBottom: '2%', paddingBottom: '2%' }}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "10px",
                }}
              >
                <Typography sx={{ marginTop: "7px", fontFamily: "poppins" }}>
                  <b>{item.company}</b>
                </Typography>
                <IconButton
                  onClick={() => {
                    let newarr = [...data.workArray];
                    newarr.splice(index, 1);
                    setData({...data,workArray:newarr});
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid marginLeft={"10px"}>
                <Typography fontFamily="poppins">
                  Designation:&nbsp;<b>{item.designation}</b>,
                </Typography>
                <Typography fontFamily="poppins">
                  Employment Type:&nbsp;<b>{item.employmentType}</b>,
                </Typography>
                <Typography fontFamily="poppins">
                  Location:&nbsp;<b>{item.location}</b>
                </Typography>
              </Grid>
              <Grid display={"flex"} marginTop="10px" marginLeft={"10px"}>
                <Typography fontFamily="poppins">
                  <b>{item.start?.toString()}</b>{" "}
                </Typography>
                &nbsp;
                <Typography fontFamily="poppins">
                  to<b>&nbsp;{item.end?.toString()}</b>
                </Typography>
              </Grid>
            </Box>
          );
        })}
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={12} lg={6} sx={{ marginTop: "20px" }}>
        <Box sx={{ border: "1px solid #177acc", width: "90%" }}>
          <Grid sx={{ display: "flex", cursor: 'pointer' }} onClick={handleOpen}>
          <Typography sx={{ fontFamily: "poppins" }}>
              <IconButton sx={{ color: "#177acc" }}>
                <AddIcon />
              </IconButton>
                Add Work Experience
              </Typography>
          </Grid>
        </Box>
      </Grid>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowX: "scroll", overflowY: "scroll" }}
      >
        <Grid container>
          <Grid item xs={1} lg={3.5} />
          <Grid item xs={10} lg={5}>
            <Box sx={style}>
              <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  fontFamily="poppins"
                >
                  Add Work Experience
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>

              <Grid marginTop={"10px"}>
                <FormLabel>
                  <InputLabel sx={{ fontFamily: "poppins" }}>
                    Company:
                    <br />
                    <TextField
                      type="text"
                      label="Eg: Master of Business Administration"
                      sx={{ marginTop: "10px", width: "100%" }}
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </InputLabel>
                  <InputLabel sx={{ marginTop: "10px", fontFamily: "poppins" }}>
                    Designation:
                    <br />
                    <TextField
                      sx={{ width: "100%", marginTop: "10px" }}
                      type="text"
                      label="Eg: Product Management"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </InputLabel>
                  <InputLabel sx={{ marginTop: "10px", fontFamily: "poppins" }}>
                  Employment Type:
                  <br />
                  <Select
                    sx={{ width: "100%", marginTop: "10px" }}
                    value={employmentType}
                    onChange={(e) => setEmploymentType(e.target.value)}
                  >
                    <MenuItem value="Permanent">
                      Permanent
                    </MenuItem>
                    <MenuItem value="Part-time">Part-time</MenuItem>
                    <MenuItem value="Remote">Remote</MenuItem>
                  </Select>
                </InputLabel>
                  <InputLabel
                    sx={{
                      marginTop: "10px",
                      fontFamily: "poppins",
                      marginBottom: "2%",
                    }}
                  >
                    Location: <br />
                    <TextField
                      type="text"
                      label="Eg: Chandigarh"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      fullWidth
                      sx={{ marginTop: "2%" }}
                    />
                  </InputLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setcurrent(e.target.checked);
                        }}
                      />
                    }
                    style={{ marginTop: "10px" }}
                    label="I am currently working."
                  />

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <DesktopDatePicker
                        label="Start Date"
                        inputFormat="DD/MM/YYYY"
                        value={start1}
                        onChange={handleChange}
                        renderInput={(params) => <TextField {...params} />}
                      />

                      {!current ? (
                        <DesktopDatePicker
                          label="End Date"
                          inputFormat="DD/MM/YYYY"
                          value={end1}
                          onChange={handleChange1}
                          renderInput={(params1) => <TextField {...params1} />}
                        />
                      ) : (
                        <TextField value={"Currently Working"} />
                      )}
                    </Grid>
                  </LocalizationProvider>
                  
                </FormLabel>
              </Grid>
              <Button
                variant="contained"
                style={{
                  marginTop: "10px",
                  borderRadius: "40px",
                  width: "100px",
                }}
                onClick={() => {
                  if(company.length != 0 && designation.length !=0 && employmentType.length !=0 && start.length!=0 && location.length!=0 ){
                  let newArr = [...data.workArray];
                  
                  let newObj = {
                    company: company,
                    designation: designation,
                    employmentType: employmentType,
                    location: location,
                    start: start,
                    end: !current ? end : "current",
                  };

                  newArr.push(newObj);
                  setData({...data,workArray:newArr});
                  setCompany("")
                  setDesignation("")
                  setEmploymentType("")
                  setLocation("")
                  setcurrent("")
                  
                }
                  else {
                    return null ;
                  }
                }}
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Modal>
      <Grid item lg={3}/>
      <Grid item xs={6} lg={5} md={9} sm={9} display="flex" marginTop='1.5%'>
        <Typography fontFamily="poppins" marginTop="1%" onClick={(e) => hc(e, index - 1)} sx={{cursor: 'pointer'}}>
        <IconButton >
          <ArrowBackIcon />
        </IconButton>
          Previous
        </Typography>
      </Grid>
      <Grid item xs={6} lg={4} md={3} sm={3}  marginTop='2%'>
      <Typography fontFamily="poppins" 
      // onClick={(e) => { if( data.educationArr.length != 0 )hc(e, index + 1)}} 
      sx={{cursor: 'pointer'}}>
          Save & Next
          <IconButton onClick={onNext} >
            <ArrowForwardIcon />
          </IconButton>
        </Typography>
      </Grid>
    </Grid>
  );
}
