import React, { useContext } from "react";
import { Grid, Card} from "@mui/material";
import Typography from "@mui/material/Typography";

const achieve = [
  {"title": "xyz", "description": "uywedqoijd1-pieeo`wo "},
  {"title": "xyz", "description": "uywedqoijd1-pieeo`wo "},
  {"title": "xyz", "description": "uywedqoijd1-pieeo`wo "},
  {"title": "xyz", "description": "uywedqoijd1-pieeo`wo "},
  {"title": "xyz", "description": "uywedqoijd1-pieeo`wo "}
]

function AchivementComponent({achive}) {

  return (
    <>
      <Card>
        <Grid container>
    {achive?.map((key,index)=>
       <Grid item lg={6} xs={12}>
       <Card sx={{padding:'2%',backgroundColor:'#f3f5fb',margin:'8%'}}>
      <Typography sx={{fontFamily:'poppins',fontSize:'16px',fontWeight:'500',marginTop:'4%'}}> {key.title}</Typography>
      <Typography sx={{fontFamily:'poppins',fontSize:'16px',fontWeight:'200',marginTop:'4%'}}>
        {key.start} - {key.end}
      </Typography>
      <Typography sx={{fontFamily:'poppins',fontSize:'16px',fontWeight:'200',marginTop:'4%'}}>{key.description}</Typography>
      </Card></Grid>
      )}</Grid></Card>
      </>
  );
}
export default AchivementComponent;
