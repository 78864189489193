import { Grid, Card, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import rec from './rec.png';
import rec1 from './rec1.png';
import badge from './badge.png';
import badge1 from './badge1.png';
import badge2 from './badge2.png';
import badge3 from './badge3.png';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { NavLink } from 'react-router-dom';
import axios from 'axios';



const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? 'green' : '#308fe8',
    },
}));


const Currentpro = ({ percentage }) => {
   

    return (
        <Grid xs={12} lg={12}>
            <Box sx={{ border: '1px solid #E3E5E7',  marginTop: '4%',marginRight:'1%' }}>
                <Typography sx={{ fontFamily: 'Poppins', fontWeight: '600', fontSize: '22px', lineHeight: '27px', color: '#000000', marginLeft: '6%',marginTop:'4%' }}>
                    Current Progress
                </Typography>
                <Divider sx={{ marginTop: '4%' }} />
                <Box sx={{ padding: '4%', display: 'flex' }} >
                    <Grid item xs={12} lg={12} sx={{ }}>
                        <Box display={"flex"} justifyContent={"space-between"} sx={{ padding: "4%" }}>
                            <Typography sx={{ fontFamily: 'Poppins', fontWeight: '500', fontSize: '18px', lineHeight: '21px', color: '#5541d7' }}>
                                Progress
                            </Typography>
                            <Typography sx={{ fontFamily: 'Poppins', fontWeight: '500', fontSize: '18px', lineHeight: '21px', color: '#5541d7' }}>
                                {percentage} %
                            </Typography>
                        </Box>
                        <Box sx={{ marginTop: '-2%', border: '2px solid #5541d7', height: '20px', borderRadius: '16px', justifyContent: 'center', width: '100%',paddingLeft:'1%',paddingRight:'1%' }}>
                            <BorderLinearProgress variant="determinate" value={percentage} sx={{ marginTop: '1%', width: '100%',  }} />
                        </Box>
                    </Grid>
                    <Grid>
                        <img src={badge} alt="" style={{ marginLeft: '4%', marginTop: '-15%' }} />
                    </Grid>

                </Box>
                
                <Box sx={{ padding: '4%' }}>
                    <Typography sx={{ fontFamily: 'Roboto', fontWeight: '500', fontSize: '26px', lineHeight: '27px', color: '#5541d7', justifyContent: 'center', display: 'flex', letterSpacing: '0.04em', marginTop: '-4%' }}>
                        Congratulations!
                    </Typography>
                    <Typography sx={{ fontFamily: 'Roboto', fontWeight: '500', fontSize: '16px', lineHeight: '20px', color: '#625F59', marginTop: "6%" }}>
                        You've earned the Course Badge! To earn more badges, complete the course or participate in the following sections.
                    </Typography>
                </Box>
                <Box sx={{ justifyContent: 'space-evenly', display: 'inline-flex' }}>
                    <Box sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <NavLink to={'/Incubation'} style={{ textDecoration: 'underline' }}>Go to Incubation</NavLink>
                        <img src={badge1} alt="" />
                        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '500', fontSize: '15px', lineHeight: '100%', color: '#64748B' }}>
                            Silver Badge
                        </Typography>
                    </Box>
                    <Box sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <NavLink to={'/synergy'} style={{ textDecoration: 'underline' }}>Go to Research</NavLink>
                        <img src={badge2} alt="" />
                        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '500', fontSize: '15px', lineHeight: '100%', color: '#64748B' }}>
                            Gold Badge
                        </Typography>
                    </Box>
                    <Box sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <NavLink to={'/Incubation'} style={{ textDecoration: 'underline' }}>Jobs for You</NavLink>
                        <img src={badge3} alt="" />
                        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '500', fontSize: '15px', lineHeight: '100%', color: '#64748B' }}>
                            Platinum Badge
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}



// const Currentpro = () => {
//     const [progress, setProgress] = React.useState(0);

//     React.useEffect(() => {
//         const timer = setInterval(() => {
//             setProgress((oldProgress) => {
//                 if (oldProgress === 100) {
//                     return 0;
//                 }
//                 const diff = Math.random() * 10;
//                 return Math.min(oldProgress + diff, 100);
//             });
//         }, 500);

//         return () => {
//             clearInterval(timer);
//         };
//     }, []);
//     return (
//         <Grid container xs={12} lg={12} sm={12} md={12} sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
//             <Grid sx={{ width: "100%", textAlign: 'center', paddingTop: '5%' }} >
//                 <Typography variant='h3' sx={{ marginBottom: "20px", fontSize: '24px', fontWeight: '500', fontFamily: 'Mulish', letterSpacing: '0.005em' }}><b>Current Progress</b></Typography>
//                 <Divider variant="middle" />
//                 <Grid item container display={'flex'} xs={9} lg={12} textAlign="center" margin={'40px 0 0 10px'}>
//                     <Grid item sx={{display: 'flex'}}>
//                         {/* <Grid display={'flex'}> */}
//                         {/* </Grid> */}
//                         <Grid item xs={12} lg={12} sx={{display: 'block', direction: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
//                             <Grid xs></Grid>
//                             <Grid>
//                             <Typography sx={{ marginBottom: "10px", color: "#C55524", fontSize: '14px', fontFamily: 'Mulish' }} >Progress</Typography>
//                             <Box sx={{ width: '100%' }}>
//                                 <LinearProgress variant="determinate" value={10} sx={{ backgroundColor: "rgba(255, 228, 165, 1)", '& .MuiLinearProgress-bar': { background: "#e10e0e" } }} />
//                             </Box>
//                             <Typography sx={{ textAlign: 'center', color: "#C55524", padding: '2% 0', fontSize: '14px', fontFamily: 'Mulish' }}>Congratulations!</Typography>
//                             </Grid>
//                             <Grid></Grid>
//                         </Grid>
//                         <Grid  xs={"auto"} lg={4}>
//                             <img src={b1} alt={'ijk'} />
//                         </Grid>
//                     </Grid>
//                 </Grid>
//                 <Typography variant='body2' sx={{ marginTop: "10px", marginBottom: "10px", fontSize: '14px', fontFamily: 'Mulish' }}>You’ve earned the Bronze Badge! To earn more badges,<br /> participate in the following sections. </Typography>
//                 <Grid display={'flex'} justifyContent={'space-evenly'} marginTop={'50px'} color={'#298BD0'}>
//                     <Typography sx={{ fontSize: '14px', fontFamily: 'Mulish' }}><a href='/' style={{ textDecoration: 'none' }}>Go to Incubation</a></Typography>
//                     <Typography sx={{ fontSize: '14px', fontFamily: 'Mulish' }}><a href='/' style={{ textDecoration: 'none' }}>Go to Research</a></Typography>
//                     <Typography sx={{ fontSize: '14px', fontFamily: 'Mulish' }}><a href='/' style={{ textDecoration: 'none' }}>Go to Create Mode</a></Typography>
//                 </Grid>
//                 <Grid display={'flex'} justifyContent={'space-evenly'}>
//                     <Grid><img src={b2} alt={''} /></Grid>
//                     <Grid><img src={b3} alt={''} /></Grid>
//                     <Grid><img src={b4} alt={''} /></Grid>
//                 </Grid>
//                 <Grid display={'flex'} justifyContent={'space-evenly'} marginTop={'-20px'} color={'#64748B'} paddingBottom={'5%'} >
//                     <Typography variant='body2' sx={{ marginLeft: "-10px", fontSize: '14px', fontFamily: 'Mulish' }}> Silver Badge</Typography>
//                     <Typography variant='body2' sx={{ marginRight: "-10px", fontSize: '14px', fontFamily: 'Mulish' }}>Gold Badge</Typography>
//                     <Typography variant='body2' sx={{ marginRight: "-20px", fontSize: '14px', fontFamily: 'Mulish' }}>Platinum Badge</Typography>
//                 </Grid>
//             </Grid>

//         </Grid>
//     )
// }

export default Currentpro;