import { Grid, Avatar, Typography, Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Avtar from '../../../../assets/images/studash/Avtar.png';
import axios from 'axios';
import { UserContext } from 'ContextFiles';


export default function Instructor({courseId}) {
    const [teacher,setTeacher]=useState({
        Image:"",
        Firstname:"Firstname",
        Lastname:"Lastname",
        Description:"Description"

    });
    useEffect(async ()=>{
        const res=await axios({
            url:'https://cms.keewesolutions.com/db/getTeacherData',
            method:'get', 
            params: {
                courseId: courseId
            }
        })
        if(res.data.response.Items.length!=0){
            setTeacher(res.data.response.Items[0])
        }
    },[])

    return (
        <Grid container lg={11} xs={11.5} sx={{ marginTop: '5%' }}>
            <Grid lg={4} xs={12} sx={{ textAlign: 'center' }}>
                <Avatar alt="Remy Sharp" src={teacher.Image} sx={{ width: 86, height: 86, marginLeft: '30%' }} />
                <Typography variant="h2" sx={{  fontSize: '24px', fontFamily: 'Roboto' }}>
                    {teacher.Firstname}
                </Typography>
                <Typography variant="h5" sx={{  fontSize: '16px', fontFamily: 'Roboto' }}>
                {teacher.Lastname} 
                </Typography>
                    {/* <Button variant="contained" sx={{ fontSize: '16px', fontFamily: 'Mulish' , marginTop:"5px"}}>
                        Message
                    </Button> */}
            </Grid>
            <Grid lg={8}  xs={12} >
                <Typography sx={{ fontSize: '14px', fontFamily: 'Roboto', letterSpacing: '5%', textAlign: 'justify' }}>
                    {/* Hey folks !!! <br/> */}
                    {teacher.Description}
                    
                </Typography>
                {/* <Typography sx={{ color: '#1A50B2', padding: '4% 0' }}><a href='./'>Connect with me Social Networking Platform</a></Typography> */}
            </Grid>
        </Grid>
    );
}
