import {
  Grid,
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  FormControlLabel,
  Checkbox,
  TextField,
  FormLabel,
  Input,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React, { useContext, useEffect, useState } from "react";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ResumeContext } from 'ResumeContext';
import axios from 'axios';


const style = {
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,

  marginTop: "30px",
};

export default function Education({ hc, index }) {
  const [arr, setArr] = useState([]);

  const { data, setData } = useContext(ResumeContext);


	const onNext=()=>{

		axios({
			method:"post",
			url:"https://cms.keewesolutions.com/opencase/put",
			data:{...data
			}
		}).then((response)=>{
			hc("",index+1);
		})
	}


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [start, setstart] = useState("");
  const [start1, setstart1] = useState("");

  const handleChange = (newValue) => {
    const dd = newValue.$D+'/'+(newValue.$M+1)+'/'+newValue.$y
    setstart(
     dd
    );
    setstart1(
      newValue
    )
  };
  const [end, setend] = useState("");
  const [end1, setend1] = useState("");

  const handleChange1 = (newValue1) => {
    const e =  newValue1.$D+'/'+(newValue1.$M+1)+'/'+newValue1.$y
    if (current) {
      setend("current");
      return null;
    }
    setend1(
      newValue1
    );
    setend(e)
  };
  const [course, setcourse] = useState("");
  const [special, setspecial] = useState("");
  const [inst, setinst] = useState("");
  const [grade, setgrade] = useState("");
  const [current, setcurrent] = useState(false);

  return (
    <Grid container>
      <Grid item xs={12} marginBottom="1%">
        <Typography
          variant="h5"
          fontFamily="poppins"
          color="#25d366"
          marginBottom="2%"
          fontWeight="bold"
        >
          Education
        </Typography>
      </Grid>
      <Grid item xs={12} marginBottom="3%">
        <Typography variant="h7" fontFamily="poppins" color="#4a4a4a">
          The biggest source of knowledge is experience, share your work
          experience!
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6} md={8} sx={{ marginTop: "20px" }}>
        {data.educationArr.map((item, index) => {
          return (
            <Box sx={{ border: "1px solid #177acc", width: "90%", marginBottom: '2%', paddingBottom: '2%' }}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "10px",
                }}
              >
                <Typography sx={{ marginTop: "7px", fontFamily: "poppins" }}>
                  <b>{item.course}</b>
                </Typography>
                <IconButton
                  onClick={() => {
                    let newarr = [...data.educationArr];
                    newarr.splice(index, 1);
                    setData({...data,educationArr:newarr});
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid marginLeft={"10px"}>
                <Typography fontFamily="poppins">
                  Specialization:&nbsp;<b>{item.special}</b>,
                </Typography>
                <Typography fontFamily="poppins">
                  Institute:&nbsp;<b>{item.inst}</b>,
                </Typography>
                <Typography fontFamily="poppins">
                  Grade:&nbsp;<b>{item.grade}</b>
                </Typography>
              </Grid>
              <Grid display={"flex"} marginTop="10px" marginLeft={"10px"}>
                <Typography fontFamily="poppins">
                  <b>{item.start?.toString()}</b>{" "}
                </Typography>
                &nbsp;
                <Typography fontFamily="poppins">
                  to<b>&nbsp;{item.end?.toString()}</b>
                </Typography>
              </Grid>
            </Box>
          );
        })}
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={12} lg={6} sx={{ marginTop: "20px" }}>
        <Box sx={{ border: "1px solid #177acc", width: "90%" }}>
          <Grid sx={{ display: "flex", cursor: 'pointer' }} onClick={handleOpen}>
          <Typography sx={{ fontFamily: "poppins" }}>
              <IconButton sx={{ color: "#177acc" }}>
                <AddIcon />
              </IconButton>
                Add Education
              </Typography>
          </Grid>
        </Box>
      </Grid>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowX: "scroll", overflowY: "scroll" }}
      >
        <Grid container marginBottom={'100px'}>
          <Grid item xs={1} lg={3.5} />
          <Grid item xs={10} lg={5}>
            <Box sx={style}>
              <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  fontFamily="poppins"
                >
                  Add Education
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>

              <Grid marginTop={"10px"}>
                <FormLabel>
                  <InputLabel sx={{ fontFamily: "poppins" }}>
                    Course:
                    <br />
                    <TextField
                      type="text"
                      label="Eg: Master of Business Administration"
                      sx={{ marginTop: "10px", width: "100%" }}
                      value={course}
                      onChange={(e) => setcourse(e.target.value)}
                    />
                  </InputLabel>
                  <InputLabel sx={{ marginTop: "10px", fontFamily: "poppins" }}>
                    Specialization:
                    <br />
                    <TextField
                      sx={{ width: "100%", marginTop: "10px" }}
                      type="text"
                      label="Eg: Product Management"
                      value={special}
                      onChange={(e) => setspecial(e.target.value)}
                    />
                  </InputLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setcurrent(e.target.checked);
                        }}
                      />
                    }
                    style={{ marginTop: "10px" }}
                    label="I am currently doing this Course."
                  />

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <DesktopDatePicker
                        label="Start Date"
                        inputFormat="MM/DD/YYYY"
                        value={start1}
                        onChange={handleChange}
                        renderInput={(params) => <TextField {...params} />}
                      />

                      {!current ? (
                        <DesktopDatePicker
                          label="End Date"
                          inputFormat="MM/DD/YYYY"
                          value={end1}
                          onChange={handleChange1}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      ) : (
                        <TextField value={"currently Working"} />
                      )}
                    </Grid>
                  </LocalizationProvider>
                  <InputLabel sx={{ marginTop: "10px", fontFamily: "poppins" }}>
                    Institute:
                    <br />
                    <TextField
                      type="text"
                      label="Eg: Indian Institute of Management, Bangalore"
                      sx={{ marginTop: "10px", width: "100%" }}
                      value={inst}
                      onChange={(e) => setinst(e.target.value)}
                    />
                  </InputLabel>
                  <InputLabel
                    sx={{
                      marginTop: "10px",
                      fontFamily: "poppins",
                      marginBottom: "2%",
                    }}
                  >
                    Grade/Percentage/CGPA: <br />
                    <TextField
                      type="text"
                      label="Eg: 8.2"
                      value={grade}
                      onChange={(e) => setgrade(e.target.value)}
                      fullWidth
                      sx={{ marginTop: "2%" }}
                    />
                  </InputLabel>
                </FormLabel>
              </Grid>
              <Button
                variant="contained"
                style={{
                  marginTop: "10px",
                  borderRadius: "40px",
                  width: "100px",
                }}
                onClick={() => {
                  if(course.length != 0 && inst.length !=0 && special.length !=0 && start.length!=0 && grade.length!=0 ){
                  let newArr = [...data.educationArr];
                  
                  let newObj = {
                    course: course,
                    inst: inst,
                    special: special,
                    grade: grade,
                    start: start,
                    end: !current ? end : "current",
                  };

                  newArr.push(newObj);
                  setData({...data,educationArr:newArr});
                  setcourse("")
                  setspecial("")
                  setinst("")
                  setgrade("")
                  setcurrent("")
                  
                }
                  else {
                    return null ;
                  }
                }}
              >
                Add
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Modal>
      <Grid item lg={3}/>
      <Grid item xs={6} lg={5}  md={9} sm={9} display="flex" marginTop='1.5%'>
        <Typography fontFamily="poppins" marginTop="1%" onClick={(e) => hc(e, index - 1)} sx={{cursor: 'pointer'}}>
        <IconButton >
          <ArrowBackIcon />
        </IconButton>
          Previous
        </Typography>
      </Grid>
      <Grid item xs={6} lg={4}  md={3} sm={3} marginTop='2%'>
      <Typography fontFamily="poppins" 
      // onClick={(e) => { if( data.educationArr.length != 0 )hc(e, index + 1)}} 
      sx={{cursor: 'pointer'}}>
          Save & Next
          <IconButton onClick={onNext} >
            <ArrowForwardIcon />
          </IconButton>
        </Typography>
      </Grid>
    </Grid>
  );
}
