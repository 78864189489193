import React, { useContext, useEffect, useState } from "react";
import { Typography, Grid, Box, Button, Avatar, List, ListItem, IconButton } from "@mui/material";
import NFT from "../../assets/images/studash/incubation/nftimg.jpg"
import priyaimg from "../../assets/images/studash/incubation/priyaimg.JPG"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { NavLink, useLocation } from "react-router-dom";
import Video from "./player/Videoplayer"
import FileViewer from 'react-file-viewer';
import { saveAs } from 'file-saver';
import icon7 from './icon7.png'
import { UserContext } from "ContextFiles";
import axios from "axios";



export default function IdeaCard() {

    const { state } = useLocation();
    const download = (e) => {
        saveAs(e)

    }

    const [data, setdata] = useState({})

    useEffect(() => {

        async function studentData(){
            await axios({
              url:'https://cms.keewesolutions.com/user/userData',
              method:'POST',
              data:{email:authState.user.email}
            }).then((res)=>{
              setdata(res.data.Items[0])
            })
          }
          studentData()
    
      
    }, [])
    

    const { authState } = useContext(UserContext);

    const descp = [{ point: 'Ideation on Non-fungible tokens performing as lending platforms .' },
    { point: ' The acceptance of non-fungible assets as collaterals securities. ' },
    { point: ' This idea motivates to hold tokens and grants access to interest loans for lenders.' },
    { point: ' This  NFT lending platform is very significant that provide  holders of arising assets. ' },
    { point: ' The combination loans with non-fungible tokens attracts more users to your platform. ' }]
    return (
        <Grid container>
            <Grid container sx={{ margin: '2% 4%' }}>
                <Grid item xs={5} lg={9}>
                    <Typography variant='h2'><b>{state.data.projectName}</b></Typography>
                </Grid>

                <Grid item xs={4} lg={2}>
                    <NavLink to={'/incubhome'} style={{ textDecoration: 'none' }}> <Button variant='outlined' sx={{ borderRadius: '20px' }}>Back to homepage</Button></NavLink>
                </Grid>

                <Grid item xs={3} lg={1}>
                    <IconButton>
                        <NotificationsActiveOutlinedIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid lg={4} />
            <Grid lg={4} style={{ width: "100%", height: '400px' }}>
                <img
                    src={state.data.ideaImage}
                    alt="imagesa"
                    style={{ width: "100%", height: "400px" }}
                />
            </Grid>
            <Grid lg={4} />


            <Grid container item xs={12} lg={12} marginTop='8%'>
                <Grid lg={1} />
                <Grid container item xs={12} lg={11}>
                    {/* <Grid item xs={12} lg={12}>
                        <Typography variant="h4">Project Overview</Typography>
                        <Grid>
                            <Video file={state.data.ideaVideo} />
                        </Grid>

                    </Grid> */}
                    <Grid container item xs={12} lg={12} >

                        <Grid item xs={12} lg={2} marginTop='3%' >
                           
                            <Typography sx={{ display: 'inline', fontSize: '20px', color: '#001A49' }}><Avatar sx={{ width: '70px', height: '70px', marginLeft: '12%' }} src={data.ImageUrl} />{data.name}</Typography>
                        </Grid>
                        <Grid container item xs={12} lg={7} justifyContent='center' alignItems='center'>
                            {/* <Typography variant="h3" fontFamily="PT serif" marginTop='4%'>
                      NFT loan platform in Real Estate
                  </Typography> */}
                            <Grid direction='row' marginTop={'2%'}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#1A50B2",
                                        borderRadius: "0px",
                                        textTransform: "none",
                                        width: '189px',
                                        height: '50px',
                                        fontSize: '16px'
                                    }}
                                >
                                    Update/Edit
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} lg={2} alignItems='end' justifyContent='end' marginTop='4%' sx={{ display: 'inline' }}>
                            <Grid><Typography sx={{ fontSize: '20px', color: '#001A49' }}>Completion Status</Typography></Grid>
                            <Grid>
                                <Box sx={{ marginTop: '2%', border: '1px solid #FFB836', borderRadius: '20px', color: '#FFB836', width: '160px', height: '2rem', textAlign: 'center', fontSize: '20px', marginLeft: '2%', paddingTop: ".4rem" }}>{state.data.completionStatus}</Box>
                            </Grid>
                        </Grid>

                    </Grid>


                    <Grid container item xs={12} lg={11} sx={{ display: "flex" , marginTop:"15px" }}>


                        <Grid item xs={12} lg={12}  >

                            <Typography sx={{ fontFamily: 'Mulish', fontSize: '1.35rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '1rem' }}>
                                Categories
                            </Typography>

                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '1rem', fontWeight: '300', color: '#283034', marginBottom: '1rem' }}>
                                {state.data.category}
                            </Typography>

                            <Typography sx={{ fontFamily: 'Mulish', fontSize: '1.35rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '1rem' }}>
                                Sub-Categories
                            </Typography>

                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '1rem', fontWeight: '300', color: '#283034', marginBottom: '1rem' }}>
                                {state.data.subCategory}
                            </Typography>


                        </Grid>

                        <Grid item xs={12} lg={12} sx={{ backgroundColor: '#f6f6f6', border: '1px solid #f0f0f0', margin: "25px 0px 0px 0px", padding: '30px 25px', borderRadius: '10px' }}>

                            <Typography sx={{ fontFamily: 'Mulish', fontSize: '1.35rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '1rem' }}>
                                Project Description
                            </Typography>

                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '1rem', fontWeight: '300', color: '#283034', marginBottom: '1rem' }}>
                                {state.data.projectDescription}
                            </Typography>


                            {/* <List><Typography variant="h4"></Typography>

                            <Typography sx={{ color: '#515B6F', fontSize: '24px' }}>
                            
                            </Typography>
                        </List> */}
                        </Grid>

                        <Grid item xs={12} lg={4} marginTop='10px'>
                            <Typography variant="h4">Idea Overview</Typography>


                            {/* <Box sx={{ border: '2px dashed #EFEFF4', marginTop: '2%', borderRadius: '5px', width: '175px', height: '175px', display: 'flex', justifyContent: 'center' }}>
                                    <img src={icon7} alt="" />
                                </Box> */}
                            {/* <Typography sx={{ fontFamily: 'Urbanist', fontWeight: '300', fontSize: '13px', color: '#667085', marginTop: '6%' }}>
                                    {state.data.modal}
                                </Typography> */}
                            <Button variant='contained' sx={{
                                marginTop: '20px', backgroundColor: '#E9EBFD', color: '#1A50B2', border: '1px solid #4640DE', ':hover': {
                                    backgroundColor: '#e9ebfd'
                                }
                            }} onClick={() => download(state.data.ideaVideo)} >Download File</Button>
                            {/* <Button >Download</Button> */}
                        </Grid>

                        <Grid item xs={12} lg={4} marginTop='10px'>
                            <Typography variant="h4">Working Model Demo</Typography>


                            {/* <Box sx={{ border: '2px dashed #EFEFF4', marginTop: '2%', borderRadius: '5px', width: '175px', height: '175px', display: 'flex', justifyContent: 'center' }}>
                                    <img src={icon7} alt="" />
                                </Box> */}
                            {/* <Typography sx={{ fontFamily: 'Urbanist', fontWeight: '300', fontSize: '13px', color: '#667085', marginTop: '6%' }}>
                                    {state.data.modal}
                                </Typography> */}
                            <Button variant='contained' sx={{
                                marginTop: '20px', backgroundColor: '#E9EBFD', color: '#1A50B2', border: '1px solid #4640DE', ':hover': {
                                    backgroundColor: '#e9ebfd'
                                }
                            }} onClick={() => download(state.data.modal)} >Download File</Button>
                            {/* <Button >Download</Button> */}
                        </Grid>


                        <Grid item xs={12} lg={4} marginTop='10px'>
                            <Typography variant="h4">Expenses and Revenue Model</Typography>


                            {/* <Box sx={{ border: '2px dashed #EFEFF4', marginTop: '2%', borderRadius: '5px', width: '175px', height: '175px', display: 'flex', justifyContent: 'center' }}>
                                    <img src={icon7} alt="" />
                                </Box> */}
                            {/* <Typography sx={{ fontFamily: 'Urbanist', fontWeight: '300', fontSize: '13px', color: '#667085', marginTop: '6%' }}>
                                    {state.data.modal}
                                </Typography> */}
                            <Button variant='contained' sx={{
                                marginTop: '20px', backgroundColor: '#E9EBFD', color: '#1A50B2', border: '1px solid #4640DE', ':hover': {
                                    backgroundColor: '#e9ebfd'
                                }
                            }} onClick={() => download(state.data.revenue)} >Download File</Button>
                            {/* <Button >Download</Button> */}
                        </Grid>
                        <Grid item xs={12} lg={12} sx={{ backgroundColor: '#f6f6f6', border: '1px solid #f0f0f0', margin: "25px 0px 0px 0px", padding: '30px 25px', borderRadius: '10px' }}>

                            <Typography sx={{ fontFamily: 'Mulish', fontSize: '1.35rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '1rem' }}>
                                Expansion of the Product
                            </Typography>

                            <Typography sx={{ fontFamily: 'Roboto', fontSize: '1rem', fontWeight: '300', color: '#283034', marginBottom: '1rem' }}>
                                {state.data.outline}
                            </Typography>
                            <Button variant='contained' sx={{
                                marginTop: '20px', backgroundColor: '#E9EBFD', color: '#1A50B2', border: '1px solid #4640DE', ':hover': {
                                    backgroundColor: '#e9ebfd'
                                }
                            }} onClick={() => download(state.data.expansion)} >Download File</Button>

                            {/* <List><Typography variant="h4"></Typography>

        <Typography sx={{ color: '#515B6F', fontSize: '24px' }}>
        
        </Typography>
    </List> */}
                        </Grid>
                    </Grid>
                </Grid>


                {/* <Grid item xs={12} lg={12} marginTop='10px'>
                        <Typography variant="h4">Expenses and Revenue Model</Typography>
                        <Grid sx={{ display: 'flex' }}>
                            <Grid>
                               <Typography sx={{ color: '#515B6F', fontSize: '22px',marginTop:'10px' }}>
                                   {state.data.projectName}  </Typography>
                               </Grid>
                            <Grid sx={{ marginLeft: '10px' }}>
                                <Button variant='contained' sx={{
                                    marginLeft: '20px',
                                    backgroundColor: '#E9EBFD', color: '#1A50B2', border: '1px solid #4640DE', ':hover': {
                                        backgroundColor: '#e9ebfd'
                                    }
                                }}>View PDF</Button>
                                <Button variant='contained' sx={{
                                    marginLeft: '20px', backgroundColor: '#E9EBFD', color: '#1A50B2', border: '1px solid #4640DE', ':hover': {
                                        backgroundColor: '#e9ebfd'
                                    }
                                }}>Download PDF</Button>
                            </Grid>
                        </Grid>
                    </Grid> */}
                {/* <Grid item xs={12} lg={12} marginTop='30px' marginBottom='20px'> */}
                {/* <Typography variant="h4">Expansion of Ideation</Typography> */}
                {/* <Grid sx={{ display: 'flex' }}> */}
                {/* <Grid>
                                <Typography sx={{ color: '#515B6F', fontSize: '22px',marginTop:'10px' }}>
                                    Expansion NFT Loan Platform in Real Estate.pdf  </Typography>
                                </Grid> */}
                {/* <Grid sx={{ marginLeft: '68px' }}>
                                    <Button variant='contained' sx={{marginLeft:'20px',
                                        backgroundColor: '#E9EBFD', color: '#1A50B2', border: '1px solid #4640DE', ':hover': {
                                            backgroundColor: '#e9ebfd'
                                        }
                                    }}>View PDF</Button>
                                    <Button variant='contained' sx={{
                                        marginLeft: '20px', backgroundColor: '#E9EBFD', color: '#1A50B2', border: '1px solid #4640DE', ':hover': {
                                            backgroundColor: '#e9ebfd'
                                        }
                                    }}>Download PDF</Button>
                                </Grid> */}
                {/* </Grid>
                </Grid> */}
            </Grid>
        </Grid>


    );
}
