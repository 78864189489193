import React, { useContext } from "react";
import {
  Typography,
  Button,
  Grid,
  TextField,
  Link,
  Select,
  MenuItem,
  InputLabel,
  Modal,
  Paper
} from "@mui/material";
import VectorNew from "../components/VectorNew.png"
import PasswordBox from "./PasswordBox";
import img from "./crest.png";
import { NavLink } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import google from "./google.png";
import apple from "./apple.png";
import facebook from "./facebook.png";
import microsoft from "./microsoft.png";
import { useState, useEffect } from "react"
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from "ContextFiles";
import { useAlert } from "react-alert";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { data } from "views/dashboard/Scheduler/Grouping";
import Supportcenter from "./Supportcenter";
import tick from "./tick.png"



const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function Register() {
  const alert = useAlert();
  
  const contextUser = useContext(UserContext)


  const location = useLocation();
  

 
  const [loc, setloc] = useState(location.search.split("="))

  

  const initialValues = { username: "", email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {

    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    
  }

  const handleClick = () => {
    alert("path not set")
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);

  }

  useEffect(() => {
   
    
  }, [formErrors])

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = () => {
    setOpen1(false)
    navigate({ pathname: "/login/", search: location.search });
};
  const handleOpen = () => setOpen(true);
 
  const handleClose = () => setOpen(false);
  const paperStyle = { padding: 20, height: '80vh', width: "max-content", margin: "20px auto" }
  const [email, setEmail] = useState("");

  const validate = (values) => {
    const errors = {}
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.username) {
      errors.username = "Username is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }

    return errors;
  }
  const navigate = useNavigate();


  const [data, setdata] = useState({
    name: "",
    email: "",
    username: "",
    phone: "",
    country: "",
    password: "",
    ImageUrl:"",
    aadhar:"",
    fathername:"",
    mothername:"",
    qualification:"",
    supportCenter:"",
    showPassword: false,
    emailExists: false,
    userIdAP:loc[2]!="undefined"?loc[2]:"",
    userIdSC:loc[3]!="undefined"?loc[3]:""
  });

  const togglePassword = () => {
    setdata({ ...data, showPassword: !data.showPassword })
  }

  
  const onSubmit = (event) => {
    
    event.preventDefault();

    if (data.emailExists == false && data.usernameExists == false) {
        axios({
            url: 'https://cms.keewesolutions.com/user/signup',
            method: 'POST',
            data: {
                name: data.name,
                email: data.email,
                username: data.username,
                password: data.password,
                phone: '+91' + data.phone,
                country: "India"
            }
        })
            .then(
                (response) => {
                  
                   if(response.status==200){
                     axios({
                      url:"https://cms.keewesolutions.com/mailer/registrationmail",
                      method:"post",
                      data:{email:data.email}
                     }).then((res)=>{
                     
                     })
                   }
                    
                    if (response.data === 'UsernameExistsException') {
                        window.alert("Public Username is not available. please change and retry registering")
                        setdata({
                            name: "",
                            email: "",
                            username: "",
                            phone: "",
                            country: "",
                            password: "",
                            showPassword: false
                        })
                        
                        return;
                    }
                    else if (response.data === 'CodeDeliveryFailureException') {
                        window.alert("Error while Registering - try again!")
                        setdata({
                            name: "",
                            email: "",
                            username: "",
                            phone: "",
                            country: "",
                            password: "",
                            showPassword: false
                        })
                    } else {
                      
                       

                       
                        axios({
                            url: 'https://cms.keewesolutions.com/user/putUserData',
                            method: 'POST',
                            data: data
                        }).then((res) => {
                           


                            axios({
                                url: 'https://cms.keewesolutions.com/user/login',
                                method: 'POST',
                                data: {
                                    username: data.username,
                                    password: data.password
                                }
                            })
                                .then((response) => {
                                   
                                    if (response.data.accessToken !== undefined) {
                                        let userData = response.data
                                        let newUserContext = { ...contextUser }
                                        newUserContext.authenticated = true
                                        let newUser = {
                                            id: userData.idToken.payload['cognito:username'],
                                            name: userData.idToken.payload.name,
                                            email: userData.idToken.payload.email,
                                            idToken: userData.idToken.jwtToken,
                                            refreshToken: userData.refreshToken
                                        }
                                        newUserContext.user = newUser
                                        contextUser.setNewUser(newUserContext)

                                        let localStorageObject = JSON.stringify({
                                            isAuthenticated: true,
                                            user: newUser
                                        })
                                        localStorage.setItem("keewe.lmsStorage", localStorageObject)
                                        alert.show("Successfully Logged In")
                                        navigate({ pathname: "/", search: location.search })
                                    }
                                    })
                                    
                                })

                        }
                })
        
    } else if (data.usernameExists == true) {
        window.alert("please Change Username")
    }
    else {
        window.alert("please Change email address")
    }
}

  





  return (

    <Grid container sx={{ width: "auto" }}>
      <form onSubmit={e => {
        e.preventDefault();
        
              if (data.name == "") {
                alert.show("please enter name");
              }
              else if (data.email == "") {
                alert.show("please enter email");
              }

              else if (data.username == "") {
                alert.show("please enter username");
              }
              else if (data.password == "") {
                alert.show("please enter password");
              }
              else if (data.password.length < 8) {

                alert.show("Password should have atleast 8 characters");
              }
              else if (data.phone == "") {

                alert.show("please enter a Valid phone number");
              } 
              else if(data.phone[0].length!=10){
                alert.show("please enter valid phone number")
              }
              else if (!data.check){
                alert.show("please agree terms and conditions ")
              }
              // else if ((data.userIdAP==""||data.userIdAP==undefined)&&!loc){
              //   alert.show("Select Support Center")
              // }
              // else if((data.userIdSC==""||data.userIdSC==undefined)&&!loc){
              //   alert.show("Select Support Center")
              // }

              else {
                return onSubmit(e)

              }}} >
        <Grid item lg={8} xs={8} sm={8} md={8} sx={{ marginBottom: "10px" }} >
          <TextField
            fullWidth
            label="Full name"
            id="fullWidth"
            value={data.name}
            onChange={event => { setdata({ ...data, name: event.target.value }) }} />
          <p>{formErrors.username}</p>
        </Grid>
        {/* <Grid xs={4} /> */}
        <Grid item lg={8} xs={8} sm={8} md={8} sx={{ marginBottom: "10px" }}>
          <TextField
            fullWidth
            type="email"
            label="Email"
            id="fullWidth"
            value={data.email}
            onChange={event => {
              event.preventDefault();
              axios({
                method: 'post',
                url: "https://cms.keewesolutions.com/user/getUserByEmail",
                data: {
                  email: event.target.value
                }
              }).then((response => {
               
                if ((response.data.Users).length != 0) {
                  window.alert("Email already exist. please use another email address ")
                  setdata({ ...data, emailExists: true })
                }
                else {
                  setdata({ ...data, email: event.target.value, emailExists: false })
                }
              }
              ))
              setdata({ ...data, email: event.target.value })
            }}
          />
          <p>{formErrors.email}</p>
        </Grid>
        {/* <Grid xs={4} /> */}
        <Grid item lg={8} xs={8} sm={8} md={8} sx={{ marginBottom: "10px"  }}>
        <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="outlined-adornment-username">Public username</InputLabel>
          <OutlinedInput
            fullWidth
            label="Public username"
            id="outlined-adornment-username"
            value={data.username}
            onKeyDown={e => {
              if (e.keyCode == 32) {
                alert.show("Space is not allowed")
              }
            }}
            // onChange={event =>  
            //   setdata({ ...data, username: event.target.value.replace(/[^a-zA-Z0-9]/, '') })}
            onChange={event => {
              event.preventDefault();
              axios({
                method: 'post',
                url: "https://cms.keewesolutions.com/user/getUserByUser",
                data: {
                  Username: event.target.value
                }
              }).then((response => {
                if ((response.data.Users).length != 0) {
                  window.alert("Username already taken. Please use another Username ")
                  setdata({ ...data, username: event.target.value.replace(/[^a-z0-9]/, ''), usernameExists: true })
                }
                else {
                  setdata({ ...data, username: event.target.value.replace(/[^a-z0-9]/, ''), usernameExists: false })
                }
              }
              ))
              setdata({ ...data, username: event.target.value.replace(/[^a-z0-9]/, '') })
            }}

            endAdornment={
              <InputAdornment position="end">
                <Tooltip title="Only Alphabets (a-z) and Numerical values are allowed">
                <IconButton 
                edge="end">
              <InfoOutlinedIcon />
                </IconButton>
                </Tooltip>
              </InputAdornment>
            }
          //   <Tooltip title="Delete">
          //   <IconButton>
            
          //   </IconButton>
          // </Tooltip>
          />
          </FormControl>
        </Grid>

        {/* <Grid xs={4} /> */}
        <Grid item lg={8} xs={8} sm={8} md={8}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              fullWidth
              id="outlined-adornment-password"
              label="password"
              onChange={event => setdata({ ...data, password: event.target.value })}
              type={data.showPassword ? 'text' : 'password'}
              value={data.password}

              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    // onClick={()=>{setShowPassword(true)}}
                    // onMouseDown={handleMouseDownPassword}
                    onClick={togglePassword}
                    edge="end"
                  >
                    {data.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }

            />
          </FormControl>
          <p>{formErrors.password}</p>
        </Grid>
        <Grid item lg={8} xs={8} sm={8} md={8} sx={{ marginBottom: "10px" }}>
          <TextField
            fullWidth
            label="10 digit Phone Number"
            id="fullWidth"
            value={data.phone}
            onChange={event => setdata({ ...data, phone: event.target.value.match(/^[0-9]*/) })}
          />
        </Grid>
       {/* {!loc && <Grid item lg={8} xs={8} sm={8} md={8} sx={{ marginBottom: "10px" }} >

                <Supportcenter data={data} setdata={setdata} />
            </Grid>} */}
        {/* <Grid xs={4} /> */}
        {/* <Grid item lg={8} xs={8} sm={8} md={8}>
      
      </Grid> */}
        {/* <Grid xs={4} /> */}
        <Grid item lg={8} xs={8} sm={8} md={8}>
          <FormControlLabelPosition data={data} setdata={setdata} />
        </Grid>
        {/* <Grid xs={4} /> */}
        <Grid item lg={8} xs={8} sm={8} md={8} sx={{ textAlign: 'justify' }}>
          <Typography sx={{ fontSize: "12px", marginBottom: "10px", textAlign: 'justify' }}>
            By creating an account you agree to honour the code and terms of service. Crestbell has rights to process your personal data as per the Privacy Policy.
          </Typography>
        </Grid>
        <Grid xs={4} />

        <Grid item xs={6} sx={{ marginTop: "10px" }}>
          <Button
            type="submit"
            variant="contained"
            
            sx={{ backgroundColor: "#06213F", borderRadius: "0px", marginBottom: "10px" }}
          >
            Submit
          </Button>
        </Grid>
        
      </form>
    </Grid>
  );
}



function FormControlLabelPosition({data,setdata}) {
  const [checked, setChecked] = useState(false)
  const handleChange = (e)=>{
    setChecked(!checked)
    setdata({...data,check:e.target.checked})}
  return (
    <FormControl component="fieldset" sx={{ marginTop: "10px" }}>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="end"
          control={<Checkbox checked={checked} onChange={handleChange}/>}
          
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "14px"
            }
          }}
          label="I agree to abide by all the terms and conditions."
          labelPlacement="I agree to abide by all the terms and conditions."
        />
      </FormGroup>
    </FormControl>
  );
}