import Desktop from './Desktop.png';
import Nomad from './Nomad.png';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './SocialMedia1.css';
import { Grid, Typography, Box, Button, Avatar, TextField, InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import React, { useContext } from "react"
import SearchIcon from '@mui/icons-material/Search';
// import {queryContext } from "../App.js"


const Item = styled(Paper)(({ theme }) => ({

    padding: theme.spacing(1),
    textAlign: 'left',
    boxShadow: "none"
}));
const Item1 = styled(Paper)(({ theme }) => ({

    padding: theme.spacing(1),
    textAlign: 'right',
    boxShadow: "none"

}));




const SocialMedia1 = () => {
    const [filter, setfilter] = React.useState("Most Viewed");

    const handleChange = (event) => {
        setfilter(event.target.value);
    }

    // const QueryContext = useContext(queryContext)

    return (


        <Grid container xs={12} lg={12} sx={{ width: "100%", background: `url(${Desktop})`, backgroundSize: '105%' }}  >
            <Grid item xs={12} lg={12} sx={{ alignItems: "center", justifyContent: 'center' }} padding={'2%'}>

                <Typography variant='h3' fontFamily={'poppins'} color={'#25324B'} textAlign={'center'} padding><b>Find your</b> <span style={{ color: "#26A4FF" }}><b>dream Job</b> </span> </Typography>
                <Typography variant='body2' fontFamily={'poppins'} fontSize={'24px'} color={'#515B6F'} textAlign='center' marginTop={''} padding={'2%'}>Find your next career at companies like HubSpot, Nike, and Dropbox</Typography>

            </Grid>

            {/* <Grid item xs container > */}
            <Grid container item xs={12} lg={12}>
                <Grid item lg={0.5} />
                <Grid item lg={9}>
                    <Box sx={{ border: '1px solid #d6ddeb', width: '100%', margin: '3% 7%' }}>
                        <Grid container item>
                            <Grid lg={1} />
                            <Grid item xs={11} lg={4} marginLeft={'10px'}>
                                <SearchIcon sx={{ fontSize: 'xx-large', color: '#25324B', marginTop: '14px' }} />
                                <TextField sx={{ width: '70%' }} id="outlined-basic" label="Job title or keyword" variant="standard" />
                            </Grid>
                            <Grid xs={0.5} lg={0.5} marginTop={'10px'} >
                                <Box sx={{ width: '0.5px', height: '40px', backgroundColor: '#202430' }} />
                            </Grid>
                            <Grid item xs={6} lg={3} marginLeft='10px'>
                                <LocationOnIcon sx={{ fontSize: 'xx-large', color: '#25324B', marginTop: "14px" }} />
                                <FormControl variant="standard" sx={{ m: 1, minWidth: '60%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">Filter</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={filter}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"Most Relevant"}>Most Relevant</MenuItem>
                                        <MenuItem value={"Most Viewed'"}>Most Viewed</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid lg={1} />
                            <Grid item xs={2} lg={1} marginTop={'10px'}>
                                <Button variant='contained' sx={{ borderRadius: '10px', width: "140px", height: "40px" }}>Search</Button>
                            </Grid>
                        </Grid>
                    </Box>


                </Grid>
                <Grid item lg={1} />
            </Grid>
            <Typography variant='body2' fontFamily={'poppins'} color={'#515B6F'} marginLeft={'10%'} marginTop={'-2%'}>Popular : UI Designer, UX Researcher, Android, Admin</Typography>


        </Grid>

    )
}

export default SocialMedia1