import { Paper, Grid, Typography, Box, Button } from '@mui/material';
import React from 'react';
import log1 from './log1.jpg';
import log2 from './log2.png';
import log3 from './log3.png';
import log4 from './log4.png';
import log5 from './log5.png';
import log6 from './log6.png';
import log7 from './log7.png';
import log8 from './log8.png';
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';



const LatestJobs = ({ cdata, value }) => {
    // const arr = [{ link: '/jobdescription', image: log1, name: "Front end Developer", time: "Full Time", type1: "React JS", type2: "CSS" },
    // { link: '/LearnAheadDesription', image: log2, name: "Android Developer", time: "Full Time", type1: "Java Script", type2: "React Native" },
    // { link: '/GlssDescription', image: log3, name: "Backend Developer ", time: "Full Time", type1: "React", type2: "Node JS" },
    // { link: '/LearnAheadDesription', image: log4, name: "IOS Developer", time: "Full Time", type1: "Java Script", type2: "IOS Development" },
    // ]

    const [data, setData] = React.useState([{}]);

    useEffect(() => {
        axios({
            method: "post",
            url: "https://po.keewesolutions.com/jp/alljob",

        }).then((response) => {
            const result = response.data.Items;
            setData(result)
            
        })
    }, [])
    
    
    return (
        <Paper sx={{ marginTop: '50px', marginBottom: '50px' }}>
            <Grid container >
                <Grid item lg={1} ></Grid>
                <Grid item xs={12} lg={10} md={12} sm={12}  >
                    <Typography variant='h3' fontFamily={'Poppins'} sx={{ paddingTop: "10px" }}><b>All <span style={{ color: '#26A4FF' }}>jobs open</span></b></Typography>
                </Grid>
                <Grid item container xs={12} lg={12} md={12} sm={12}>
                    <Grid lg={1} />
                    <Grid item container lg={10.5} xs={12} md={12} sm={12} sx={{  marginTop: "20px",display:'flex' }} >
                        {data.filter(row => {
                  
                  if (value == "") {
                    return row
                  }
                  else if (row.jobTitle.toLowerCase().includes(value.toLowerCase())) {
                    return row;
                  }
                })?.map((item) => {
                            return (
                                <Grid item >
                                    {  
                                    cdata.map((cd) => { 

                                        if(item.companyId===cd.id){
                                           
                                        return ( 
                                            <Box key={item.name} sx={{ border: "1px solid #D6DDEB",marginLeft:'10px', marginRight:'10px', display: 'flex', marginTop: '10px',width:'420px',height:'140px' }}>
                                            <NavLink to={'/Jobs'} state={{
                                                jobdetails: data,
                                                company: cdata,
                                                companyId: cd.id,
                                                jobid: item.id
                        
                                            }} style={{ textDecoration: 'none' }}>
                                                
                                                
                                                    <Grid  >
                                                        <Grid sx={{ display: 'flex', marginLeft: '20px', marginTop: '10px', marginBottom: '20px' }}>

                                                            <Grid><img src={cd.imgUrl} style={{ width: '70px', height: '50px' }} alt="ewrt" />
                                                            </Grid>
                                                            <Grid sx={{ marginLeft: "15px", marginTop: '10px' }}>
                                                                <Typography variant="h3">{item.jobTitle}</Typography>
                                                            </Grid>

                                                        </Grid>
                                                        <Grid sx={{ display: "flex", marginBottom: '20px', marginLeft: '20px' }}>
                                                            <Box sx={{ borderRadius: '20px', background: "#56CDAD1A", width: "80px", height: "30px", color: '#56CDAD', textAlign: "center" }}><Typography sx={{ fontSize: '15px', marginTop: '3px' }}>{item.employmentType}</Typography></Box>
                                                            <Typography sx={{ color: "#D6DDEB", marginLeft: "8px" }}>|</Typography>
                                                            {item.Skills != undefined?
                                                                  
                                                                    <div style={{display:'flex'}}>
                                                                         <Box sx={{ marginLeft: '8px', borderRadius: '20px', border: '1px solid #FFB836',  color: '#FFB836', textAlign: "center" }}><Typography sx={{ fontSize: '13px', marginTop: '3px', marginLeft: '5px', marginRight: '5px' }}>{item.Skills[0]}</Typography></Box> 
                                                                        <Box sx={{ marginLeft: '5px', borderRadius: '20px', border: '1px solid #1A50B2', color: '#1A50B2', textAlign: "center" }}><Typography sx={{ fontSize: '13px', marginTop: '3px', marginLeft: '5px', marginRight: '5px' }}>{item.Skills[1]}</Typography></Box>
                                                                    </div>: null
                                                               } 

                                                        </Grid>
                                                    </Grid>

                                                
                                            </NavLink>
                                            </Box>
                                        )}
                                    })}
                                    
                                </Grid>
                            )
                        })}

                    </Grid>
                </Grid>
            </Grid>


        </Paper>
    )
}

export default LatestJobs
