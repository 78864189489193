import React from "react";
import { Typography, Grid, Card, Divider } from "@mui/material";
import ResumeImage from '../images/ResumeImage.png'

export default function ResumeCard() {
  return (
    <Card>
      <Grid container>
        <Grid container item xs={12} direction='row' justifyContent='center'>
          <img src={ResumeImage} alt="dsfgh" />
        </Grid>
        
        <Grid container item xs={12} direction='row' justifyContent='center' marginBottom='6%'>
          <Typography variant='h7' fontFamily="poppins" color="#177acc" fontWeight='bold' marginBottom='2%'>
            8 steps to build resume
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
