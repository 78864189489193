import {
    Grid,
    Box,
    Typography,
    IconButton,
    Button,
    Modal,
    FormControlLabel,
    Checkbox,
    TextField,
    FormLabel,
    Input,
    InputLabel,
    Select,
    MenuItem,
  } from "@mui/material";
  import AddIcon from "@mui/icons-material/Add";
  
  import React, { useContext, useState } from "react";
  import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import CloseIcon from "@mui/icons-material/Close";
  import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import { ResumeContext } from 'ResumeContext';
import axios from 'axios';

  const style = {
    bgcolor: "background.paper",
  
    boxShadow: 24,
    p: 4,
  
    marginTop: "30px",
  };
  
  export default function Achievements({ hc, index }) {
    const [arr, setArr] = useState([
      {
        title: "1st position in Maths Olympiad",
      
       
        current: "Previous",
        start: "09/12/2019",
        end: "01/02/2022",
        description: "Write more about your achievement..."
      },
    ]);
    const {data , setData} = useContext(ResumeContext)

    const onNext=()=>{
      axios({
        method:"post",
        url:"https://cms.keewesolutions.com/opencase/put",
        data:{...data
        }
      }).then((response)=>{
        hc("",index+1);
      })
    }
    const [achivements, setAchivements] = useState("");
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [start, setstart] = useState("");
    const [start1, setstart1] = useState("");
  
    const handleChange = (newValue) => {
      const dd = newValue.$D+'/'+(newValue.$M+1)+'/'+newValue.$y
      setstart(
       dd
      );
      setstart1(newValue)
    };
    const [end, setend] = useState("");
    const [end1, setend1] = useState("");
  
    const handleChange1 = (newValue1) => {
      const e = newValue1.$D+'/'+(newValue1.$M+1)+'/'+newValue1.$y
      if (current) {
        setend("current");
        return null;
      }
      setend1(
        newValue1
      );
      setend(e)
    };
    const [title, settitle] = useState("");
    const [current, setcurrent] = useState(false);
    const [description, setdescription] = useState("");
  
    return (
      <Grid container>
        <Grid item xs={12} marginBottom="1%">
          <Typography
            variant="h5"
            fontFamily="poppins"
            color="#25d366"
            marginBottom="1%"
            fontWeight="bold"
          >
            Achievements
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom="1%">
          <Typography variant="h7" fontFamily="poppins" color="#4a4a4a">
              You're meant for greater things, but tell us your achievements so far
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ marginTop: "20px" }}>
          {data.achivementsArr.map((item, index) => {
            return (
              <Box sx={{ border: "1px solid #177acc", width: "90%", marginBottom: '2%', paddingBottom: '2%' }}>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "10px",
                  }}
                >
                  <Typography sx={{ marginTop: "7px", fontFamily: "poppins" }}>
                    <b>{item.title}</b>
                  </Typography>
                  <IconButton
                    onClick={() => {
                      let newarr = [...data.achivementsArr];
                      newarr.splice(index, 1);
                      setData({...data,achivementsArr:newarr});
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                
                <Grid display={"flex"} marginTop="10px" marginLeft={"10px"}>
                  <Typography fontFamily="poppins">
                    <b>{item.start?.toString()}</b>
                  </Typography>
                 
                  
                </Grid>
                <Grid marginLeft='10px'>
                  <Typography fontFamily="poppins">
                    Short Description:&nbsp;<b>{item.description}</b>,
                  </Typography>
                  </Grid>
              </Box>
            );
          })}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={12} lg={6} sx={{ marginTop: "20px" }}>
          <Box sx={{ border: "1px solid #177acc", width: "90%" }}>
            <Grid sx={{ display: "flex", cursor: 'pointer' }} onClick={handleOpen}>
              <Typography sx={{ fontFamily: "poppins" }}>
              <IconButton sx={{ color: "#177acc" }}>
                <AddIcon />
              </IconButton>
                Add Achievements
              </Typography>
            </Grid>
          </Box>
        </Grid>
  
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <Grid container>
            <Grid item xs={1} lg={3.5} />
            <Grid item xs={10} lg={5}>
              <Box sx={style}>
                <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ fontFamily: "poppins" }}
                  >
                    Add Achievements
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
  
                <Grid marginTop={"10px"} sx={{marginBottom:'2%'}}>
                  <FormLabel>
                    <InputLabel sx={{ fontFamily: "poppins" }}>
                      Title:
                      <br />
                      <TextField
                        type="text"
                        label="Eg: Secured 1st position in Maths Olympiad"
                        sx={{ marginTop: "10px", width: "100%", marginBottom:'2%' }}
                        value={title}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </InputLabel>
                   
                    
                   
  
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Typography fontFamily='poppins' >Date of Achievement:</Typography>
                      <Grid
                        sx={{ display: "flex", justifyContent: "space-between", marginTop:'2%' }}
                      >
                        <DesktopDatePicker
                          label="Select Date"
                          inputFormat="MM/DD/YYYY"
                          value={start1}
                          onChange={handleChange}
                          renderInput={(params) => <TextField {...params} fullWidth/>}
                          sx={{ marginRight: "1%"}}
                          
                        />
  
                      </Grid>
                    </LocalizationProvider>
                  </FormLabel>
                  <InputLabel sx={{ marginTop: "10px", fontFamily: "poppins" }}>
                  Short Description:
                  <br />
                  <TextField
                    type="text"
                    label="Write more about your achievement..."
                    sx={{ marginTop: "10px", width: "100%" }}
                    value={description}
                    onChange={(e) => setdescription(e.target.value)}
                  />
                </InputLabel>
                </Grid>
                <Button
                  variant="contained"
                  style={{
                    marginTop: "10px",
                    borderRadius: "40px",
                    width: "100px",
                  }}
                  onClick={() => {
                    if(title.length != 0 && description.length !=0 && start.length!=0  ){
                    let newArr = [...data.achivementsArr];
                    let newObj = {
                      title: title,
                      start: start,
                      description: description
                    };
  
                    newArr.push(newObj);
                    setData({...data,achivementsArr:newArr});}
                    else{
                      return null
                    }
                  }}
                  
                >
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Modal>
        <Grid item lg={3}/>
        <Grid item xs={6} lg={5}  md={9} sm={9} display="flex" marginTop='1.5%'>
          <Typography fontFamily="poppins" marginTop="1%" onClick={(e) => hc(e, index - 1)} sx={{cursor: 'pointer'}}>
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
            Previous
          </Typography>
        </Grid>
        <Grid item xs={6} lg={4}  md={3} sm={3} marginTop='2%'>
          <Typography fontFamily="poppins" 
          // onClick={(e) => {if(data.achivementsArr.length != 0)  hc(e, index + 1)}} 
          sx={{cursor: 'pointer'}}>
            Save & Next
            <IconButton onClick={onNext}>
              <ArrowForwardIcon />
            </IconButton>
          </Typography>
        </Grid>
      </Grid>
    );
  }
  