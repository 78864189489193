import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Grid, Paper, TextField, Button, Typography, Link, Modal, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import VectorNew from "../components/VectorNew.png";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



const ForgotPassword = ({handleClose1}) => {
    const navigate=useNavigate();
    const [email, setEmail] = useState("");
    const [open, setOpen] = React.useState(false);
    const [code, setCode] = useState("");
    const [newpassword, setNewpassword] = useState("");
    const [showPassword, setShowPassword] = React.useState(false);

    const paperStyle = { padding: 20, height: '80vh', width: "max-content", margin: "20px auto" }
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    const handleClose = () => setOpen(false);
  
  //SendCode 
    const SendCode = () => {
        if(email!=""){
            axios({
                method: 'post',
                url: "https://cms.keewesolutions.com/user/getUserByEmail",
                data: {
                  email: email
                }
              }).then(response => {
               
                if((response.data.Users).length!=0){
                    axios({
                        url: 'https://cms.keewesolutions.com/user/forgotpassword',
                        method: 'POST',
                        data: {
                            username: email,
                        }
                    })
                    setOpen(true)
                }
                else{
                    alert("Email is not Registered, Please SignUp")
                    handleClose1();
                }
              })
        }  
    }

    const ConfirmCode=()=>{
       
        axios({
            url: 'https://cms.keewesolutions.com/user/forgotpasswordchange',
            method: 'POST',
            data: {
              username: email,
              code: code,
              newPassword: newpassword
            }
          })
            .then(
              (response) => {
              
                if (response.data === "SUCCESS") {
                handleClose();
                handleClose1();
                }
              }
            );
    }
  

    

    return (
        <Paper elevation={10} style={paperStyle}>
            <form>
                <Grid align='center'>


                    <div align="center">
                        <img src={VectorNew} alt="iamage" style={{ width: "-webkit-fill-available" }} />
                    </div>
                    </Grid>
                   
                    {open? 
                    
                    <> <h2>New Password</h2>
          <h6>Set new password for your account</h6>
        <TextField
          value={code}
          onChange={event => setCode(event.target.value)}
          variant="outlined" label='Verify OTP' fullWidth required />
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            fullWidth
            id="outlined-adornment-password"
            label="password"
            onChange={event => setNewpassword(event.target.value)}
            type={showPassword ? 'text' : 'password'}
            value={newpassword}


            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => { setShowPassword(true) }}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }

          />
           
        </FormControl>
       
        <Button
         variant="contained" 
         style={{ backgroundColor: "#630000", color: "white", height: "56px", fontSize: "20px", marginTop: "10px", marginBottom: "10px" }} 
         fullWidth
         
         onClick={ConfirmCode}>CONFIRM</Button>
        </>:<>
                    <h2>Forgot Password</h2>
                    <h6>Verification Code will be sent on Email</h6>
                
                <TextField
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    variant="outlined" label='Enter Email' fullWidth required />
                <Button
                onClick={SendCode} 
                variant="contained" 
                style={{ backgroundColor: "#06213F", color: "white", height: "56px", fontSize: "20px", marginTop: "10px", marginBottom: "10px" }} 
                fullWidth>SEND CODE</Button>
                </>}
            </form>
        </Paper>
    )
}

export default ForgotPassword
