import React, { useEffect } from "react";
import { Typography, Divider, Grid } from '@mui/material';

import RecipeReviewCard from "Namrata/CommentSection/components/Card";


export default function Discussions({ videoId }) {
  


  return (
    <div className="App">
      <Grid container lg={12} xs={12}>
        <Grid item lg={12} xs={12}>
          <div style={{ margin: "10px 0px 10px 10px" }}>
            <Typography fontSize={"24px"} fontWeight='500' color={'#000'} sx={{ fontFamily: "Roboto" }}>Discussion Forum</Typography>
          </div>
          <div style={{ margin: "10px 0px 10px 10px" }}>
            <RecipeReviewCard videoId={videoId} />
          </div>
          {/* <div style={{margin:"10px 0px 20px 20px"}}>
          <Typo />
        </div>
        <div style={{margin:"10px 0px 20px 20px"}}>
          <Filter />

        </div> */}
          {/* <Divider variant="middle" />
        <div style={{margin:"10px 0px 20px 20px"}}>
          <Comment />
        </div> */}
        </Grid>
      </Grid>
    </div >

  );
}


