import './Certi.css';
import logo from './images/mrslogo.png';
import logo1 from './layout/MainLayout/LogoSection/logo.png'
import Pdf from "react-to-pdf";
import React from 'react';
import { useLocation } from 'react-router-dom';
// import sign1 from './images/'
import sign2 from './images/coe.jpg'
import sign3 from './images/reg.jpg'
import { Diversity1 } from '@mui/icons-material';
const ref = React.createRef();

function Certi() {
  const location = useLocation();
  const { userName, courseName, duration, Date } = location.state;
  const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [9, 12]
  };
  return (
    <div>
      <Pdf targetRef={ref} filename="code-example.pdf" options={options} >
        {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
      </Pdf>
      <div className="background" ref={ref}>
        <div className="container" style={{ textAlign: 'left' }}>


          <div style={{ textAlign: 'center', position: 'relative' }}>
            <p style={{ fontFamily: "Algerian", fontSize: "1.5rem", fontWeight: 200, textAlign: 'left', wordSpacing: "1.5rem", lineHeight: "202%", marginTop: 0, paddingLeft: "1rem" }}>C.N.Crestbell/ONLINE/......</p>
            <br />
            <text style={{ fontSize: "2.3rem", fontWeight: 500, wordSpacing: "1rem" }}>ਮਹਾਰਾਜਾ ਰਣਜੀਤ ਸਿੰਘ ਪੰਜਾਬ ਟੈਕਨੀਕਲ ਯੂਨੀਵਰਸਿਟੀ, ਬਠਿੰਡਾ</text>
            <br />
            <text style={{ fontFamily: "old english text mt", fontSize: "2.3rem", fontWeight: 200, lineHeight: "120%", wordSpacing: "1rem" }}>Maharaja Ranjit Singh Punjab Technical University Bathinda</text>
            <br />
            <br />
            <text
              style={{
                fontFamily: "Poppins",
                fontSize: "1.25rem",
                fontWeight: "200",
                justifyContent: "center",
                display: "flex",
                lineHeight: "120%"
              }}
            >
              (A State University Established By Govt. of Punjab vide Punjab Act
              No. 5 of 2015 and Approved Under Section 2(f) & 12 (B) of UGC)
            </text>
            <div className="image">
              <img src={logo1} alt='this is logo' style={{ height: "7.5rem", width: "7.5rem" }} />
            </div>
            <img src={logo1} className='backgroundImage' alt='this is logo' />
            <div className="textarea">
              <text style={{ fontFamily: "Algerian" }}>CERTIFICATE OF COMPLETION</text>
              <div className="writearea">
                <text style={{ fontFamily: "Algerian", fontSize: "16px", fontWeight: 200, wordSpacing: "18px", lineHeight: "200%" }} >THIS IS TO CERTIFY THAT <span style={{ fontFamily: 'Poppins', fontSize: "16px", fontWeight: 400, wordSpacing: "18px", borderBottom: "2px dotted #000", paddingLeft: "20px", paddingRight: "20px" }}>{userName}</span> </text>
                <br />
                <text style={{ fontFamily: "Algerian", fontSize: "16px", fontWeight: 200, wordSpacing: "18px", lineHeight: "200%" }} >HAS SUCCESSFULLY COMPLETED THE ONLINE CERTIFICATION </text>
                <br />
                <text style={{ fontFamily: "Algerian", fontSize: "16px", fontWeight: 200, wordSpacing: "18px", lineHeight: "200%" }} >COURSE IN <span style={{ fontFamily: 'Poppins', fontSize: "16px", fontWeight: 400, wordSpacing: "18px", borderBottom: "2px dotted #000", paddingLeft: "20px", paddingRight: "20px" }}>{courseName}</span> WITH GRADE <span style={{ fontFamily: 'Poppins', fontSize: "16px", fontWeight: 400, wordSpacing: "18px", borderBottom: "2px dotted #000", paddingLeft: "20px", paddingRight: "20px" }}>A+</span> </text>
                <br />
                <text style={{ fontFamily: "Algerian", fontSize: "16px", fontWeight: 200, wordSpacing: "18px", lineHeight: "200%" }} >COURSE CODE <span style={{ fontFamily: 'Poppins', fontSize: "16px", fontWeight: 400, wordSpacing: "18px", borderBottom: "2px dotted #000", paddingLeft: "20px", paddingRight: "20px" }}>MOOCCSC-A03</span> OF DURATION<span style={{ fontFamily: 'Poppins', fontSize: "16px", fontWeight: 400, wordSpacing: "18px", borderBottom: "2px dotted #000", paddingLeft: "20px", paddingRight: "20px" }}>{duration} HOURS</span> </text>
                <br />
                <text style={{ fontFamily: "Algerian", fontSize: "16px", fontWeight: 200, wordSpacing: "18px", lineHeight: "200%" }} >DATE <span style={{ fontFamily: 'Poppins', fontSize: "16px", fontWeight: 400, wordSpacing: "18px", borderBottom: "2px dotted #000", paddingLeft: "20px", paddingRight: "20px" }}>{Date}</span> </text>
              </div>
              <text style={{ fontFamily: "old english text mt" }}>Given under the seal of the university</text>
              <br />
              <br />
              <br />
              <br />
              <div className="signature">
                <div style={{ display: "block" }}>
                  <div>
                    <img src={sign3} alt='sign3' />
                  </div>
                  <div>
                    <text style={{ fontFamily: "Algerian", paddingLeft: "10px" }}>REGISTRAR</text>
                  </div>
                </div>

                <div style={{ 'flex': 0.65 }} />
                <div style={{ 'flex': 1 }} />
                <div style={{ display: "block" }}>
                  <div>
                    <img src={sign2} alt='sign3' />
                  </div>
                  <div>
                    <text style={{ fontFamily: "Algerian", paddingLeft: "10px" }}>CONTROLLER OF EXAMINATION</text>
                  </div>
                </div>


              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Certi;
