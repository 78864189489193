import PropTypes from 'prop-types';

// material-ui
import { Box, Button, Card, Grid, Modal, Typography } from '@mui/material';


import MainCard from 'ui-component/cards/MainCard';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import photo1 from './layout/MainLayout/LogoSection/logo.png';

import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { UserContext } from 'ContextFiles';
import certificateschool from '../src/assets/images/logo.png'


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));


const TablerIcons = () => {
    const [certificate, setcertificate] = useState([])
    const [value, setValue] = useState("")
    const userContext = useContext(UserContext)
    

    useEffect(() => {
        const certificateUrl = async () => {
            await axios({
                url: "https://cms.keewesolutions.com/university/certificate",
                method: "POST",
                data:{
                    userId:userContext.authState.user.id,
                }
            }).then(res => {
                
                const array = res.data.Items
                const newarr = array.filter((item)=>
                    item.Approve==1
                )
               
                setcertificate(newarr)

            })
        }
        certificateUrl();
    }, [])


    return (
        <MainCard title="Generated Certificates" secondary={function () {

        }()
        }


        >
            
            <Grid container lg={12} sx={{ display: "flex" }} >
               


                   

                        <Grid item container sx={{ background: '#F9F9F9', backdropFilter: 'blur(5px)', boxShadow: '0 4px 30px rgba(0,0,0,0.1)', border: '1px solid #3a5a7b', marginBottom: '2%', borderRadius: '14px', width: "180px", display: "flex", margin: "4px" }}>

                            <Grid item lg={12}>
                                <img src={certificateschool} alt='not found' style={{ width: '178px',height:'80px',  borderRadius: "10px " }} />
                                {/* <Box height='30px' width='120px' sx={{ position: 'absolute', top: '8px', left: '8px', textAlign: 'center', marginBottom: '15px', borderRadius: '14px', paddingTop: '1%', backgroundColor: "#3a5a7b", color: '#fff' }}></Box> */}
                            </Grid>
                            <Grid item container margin='5%' >
                                <Grid item lg={12} marginBottom='2%'>
                                    <Typography variant='h5' fontSize={"Roboto"} fontWeight='600' color='#1F4690'></Typography>
                                </Grid>
                                <Grid item lg={12} marginBottom='2%'>
                                    <Typography fontSize={"16px"} fontFamily={"Roboto,Helvetica Neue,Helvetica,Arial,sans-serif"} fontWeight='300' color={"#737171"}>  </Typography>
                                </Grid>

                                <Grid item lg={12} >
                                    <Button ><a href={"https://dbucourse.s3.amazonaws.com/m1.jpg"}>Download Certificate</a></Button>
                                </Grid>
                            </Grid>
                        </Grid>


               
            </Grid>

        </MainCard>
    )
};

export default TablerIcons;




