import { Button, Grid, Paper, Typography } from '@mui/material';

import React, { useState } from 'react';
import log3 from '../NehaBhagat1/new/log1.jpg';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Mask from './Mask.png';
import Mask1 from './Mask1.png';
import { Link } from 'react-router-dom';
import "./Stripe.css"

const Stripe = ({jd,cd,id,jid}) => {
  
  return (

    <Paper>
      {cd.map((item) => {if(id===item.id)
      {         
        return (
      <Grid container marginTop={'20px'} marginBottom='30px' sx={{ display: "flex" }}>
        <Grid lg={0.5}/>
        <Grid item xs={12} lg={10} md={10} sm={10} paddingTop={'30px'} >
          <Grid display={'flex'}>
            <Grid> <img width={'100px'} height={'80px'} src={item.imgUrl} alt={''} /></Grid>
            <Grid sx={{ marginLeft: "20px" }}>
              <Typography rem fontSize={'30px'} fontFamily={'poppins'}><b>{item.name}</b></Typography>
              <Grid>
                
                <a href={item.website} style={{ textDecoration: 'none' }}><Button sx={{ color: '#1A50B2', fontFamily: 'poppins', rem: '20px' }}> Read more about {item.name} &#8594;</Button></a>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Typography rem='body2' fontFamily={'poppins'} fontSize={'17px'} marginTop={'10px'}>
              {item.discription}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} lg={5} marginTop={'30px'}>
          <Grid display={'flex'}  alignItem={'center'}>
            <Grid> <img src={Mask} alt={''} /></Grid>
            <Grid><img src={Mask1} alt={''} className="Mask1" />
              <img src={Mask1} alt={''} className="Mask1"/></Grid>
          </Grid>


        </Grid> */}
      </Grid>
     )}})}
    </Paper>
  )
}

export default Stripe