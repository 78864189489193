import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button, Box, Paper, Typography, Modal } from '@mui/material';
import Desktop from './Desktop.png';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import ShareIcon from '@mui/icons-material/Share';
import log1 from '../NehaBhagat1/new/log1.jpg';
import Form1 from '../NehaBhagat1/Form1';
import "./SocialMedia.css";
import { UserContext } from "ContextFiles";
import axios from 'axios';


const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '50%',
    maxHeight: '50%',
});

const style1 = {
    width: '70%',
    backgroundColor: "#0D0B41",
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    // overflow:'scroll',
};


const SocialMedia = ({ jd,id,cd,jid}) => {
    const {user}=useContext(UserContext);
   
    const [open, setOpen] = React.useState(false);
    const [jobhai, setjobhai] = useState()
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getdata =async()=>{ await axios({
        url:"https://cms.keewesolutions.com/jp/getdata",
        method:"post",
        data:{userId:user.id,
        jobId:jid
        }
    }).then((res)=>{
        setjobhai(res.data.Items.length)
    })
}

    useEffect(()=>{
        
    getdata()
    },[])
    return (

        <Paper padding={'20px'} sx={{ marginTop: '10px' }} >
            <Grid container xs={12} lg={12} sx={{ background: `url(${Desktop})`, }}>
                <Grid xs={12} lg={1} />
                {cd.map((item) => {if(id===item.id){
                     
                        return (
                            <Grid container item xs={12} lg={12} marginBottom={'20px'} >
                                {/* <Grid padding={'40px'} marginTop={'40px'} marginLeft={'20px'}><Typography variant='body2' fontFamily={'Poppins'} alignItems={'center'} marginLeft={'50px'} fontSize={'19px'}>Home / Companies / Nomad / Social Media Assistant</Typography>
</Grid> */}
                                <Grid container item xs={12} lg={12} sx={{ width: "200%", padding: "30px", marginTop: "-30px" }}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            margin: 'auto',
                                            maxWidth: 1000,
                                            flexGrow: 1,
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <Grid container item xs={12} lg={12} spacing={2}>
                                            <Grid item xs={10} lg={1.5} >

                                                <img width={'120px'} height={'80px'} alt="" src={item.imgUrl} />

                                            </Grid>
                                            <Grid item xs={12} lg={9} container>
                                                <Grid item container >
                                                        {jd.map((job)=>{if(jid===job.id){
                                                            
                                                            return(
                                                                <Grid item xs={12} lg={8} marginLeft={'10px'}>
                                                                <Typography className="front" variant="body2" gutterBottom fontFamily={'Poppins'} fontSize={'25px'}>
                                                                <b>{job.jobTitle}</b>
                                                            </Typography>
    
                                                            <Typography variant="body2" color="text.secondary" fontFamily={'Poppins'} fontSize={'15px'} marginTop={'-8px'}>
                                                                Chandigarh
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" fontFamily={'Poppins'} fontSize={'15px'}>
                                                                {job.employmentType}
                                                            </Typography>
                                                        </Grid>
                                                            )}
                                                        })}
                                                     
                                                    <Grid item lg={1}>
                                                        {/* <Typography component="div" sx={{ marginTop: "20px" }}>
                            {/* < ShareIcon fontSize='large' /> 
                        </Typography> */}
                                                    </Grid>
                                                    <Grid item xs={3} lg={2}>
                                                        <Typography component="div" sx={{ marginTop: "10px" }}>
                                                            <Button onClick={handleOpen} variant='contained' fontFamily={'Poppins'} sx={{ width: '100px' }}>{jobhai==1?"Applied":"Apply"}</Button>
                                                        </Typography>

                                                        <Modal
                                                            open={open}
                                                            onClose={handleClose}
                                                            aria-labelledby="modal-modal-title"
                                                            aria-describedby="modal-modal-description"
                                                            sx={{ overflowY: "scroll", position: 'absolute',alignItems:'center',left:'22%',top:'10%' }}
                                                        >

                                                            <Box sx={style1}>
                                                            {jd.map((job1)=>{if(jid===job1.id){
                                                            return(
                                                                <Form1 jobId={job1.id} cname={job1.companyName} image={item.imgUrl} Empytyp={job1.employmentType} jobname={job1.jobTitle} company={cd} handleClose={handleClose} getdata={getdata} />
                                                                )}
                                                            })}
                                                         
                                                            </Box>
                                                        </Modal>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                         )}              

                })}

            </Grid>
        </Paper>
    )
};

export default SocialMedia