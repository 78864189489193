import React from 'react';
import { Typography, Grid, Card, Button } from '@mui/material';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SwipeableTextMobileStepper from './CarouselCard';


export default function ProfileInfo(){
    return(
        <Card sx={{width:'99%'}}>
          <Grid container padding='2% 4%'>
             <Grid item xs={12} padding='1%'>
                <Typography variant='h5' fontFamily='poppins' marginBottom='4%'>Profile Strength</Typography>
                <HorizontalLabelPositionBelowStepper/>
             </Grid>
             <Grid item xs={12} padding='1%'>
                <hr />
             </Grid>
             <Grid item xs={12} padding='1%'>
               <Typography variant='h6' fontFamily='poppins'>Increase your profile strength to standout.</Typography>
             </Grid>
             <Grid item xs={12} padding='1%'>
             <SwipeableTextMobileStepper/>
           </Grid>
          </Grid>
        </Card>
    )
}



const steps = [
    'Average',
    'Good',
    'Excellent',
  ];
  
  export  function HorizontalLabelPositionBelowStepper() {
    return (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={1} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }