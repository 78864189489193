import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getCardHeaderUtilityClass, InputLabel, Typography } from '@mui/material';
import React from 'react';
import {useContext, useState} from 'react';
import {ResumeContext} from "../Resume/ResumeFile";
import { Grid } from '@mui/material';
import jsonList from './location.json'
import { Address } from 'Opencase/Resume/Contact';
import '../Resume/Opencase.css';

export default function DropStates({ setState, setCountry, setCity}) {
	const {add,setAdd}=useContext(Address);
	const [sc, setSc] = useState(0)
	const [cityArr, setCityArr] = React.useState(['none']);
	// const [stateArr, setStateArr] = React.useState(['none']);
	const [newCity, setNewCity] = React.useState('');
	const [newState, setNewState] = React.useState('');
	const [newCountry, setNewCountry] = React.useState('');
	// const {state, setState}= useContext(ResumeContext);
	// const {city, setCity} = useContext(ResumeContext);

	return (
		<Grid container item xs={12}>
			<Grid item xs={12} lg={6} md={6} sm={6} marginTop="2%">
				<FormControl
					sx={{
						display: 'flex'
					}}
					fullWidth
				><InputLabel>Country</InputLabel>
					<Select className='n4' label="countries" sx={{ width: '99%', height: '50px' }}  value={add.country} onChange={(e)=>setAdd({...add,country:e.target.value})}>
						{jsonList.Countries.map((country, countryIndex) => {
							return (
								<MenuItem
									onClick={() => {
										// setCityArr(stateList[stateIndex].cities);
										// setState(stateList[stateIndex].name);
										setSc(countryIndex)
									}}
									value={country.CountryName}
									key={country.CountryName}
								>
									{country.CountryName}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Grid>
			
			<Grid item xs={12} lg={6} md={6} sm={6} marginTop="2%">
				
				<FormControl
					sx={{
						display: 'flex'
					}}
					fullWidth
				>
					<InputLabel>State</InputLabel>
					<Select label="states" sx={{ width: '99%', height: '50px' }}  
					value={add.state} 
					onChange={(e)=>setAdd({...add,state:e.target.value})}>
						{jsonList.Countries[sc].States.map((state, stateIndex) => {
							return (
								<MenuItem
									onClick={() => {
										setCityArr(jsonList.Countries[sc].States[stateIndex].Cities);
                    					// setState(stateArr[stateArrIndex].StateName)
									}}
									value={state.StateName}
									key={state.StateName}
								>
									{state.StateName}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12} lg={6} md={6} sm={6} marginTop="2%">
				<FormControl
					sx={{
						marginBottom: '3%'
					}}
					fullWidth
				>
					<InputLabel>City</InputLabel>
					<Select
						label="Cities"
						sx={{ width: '99%', height: '50px' }}
						value={add.city}
						onChange={(e) => {
							setAdd({...add,city:e.target.value})
							// setCity(e.target.value);
						}}
					>
						{cityArr?.map((city, cityIndex) => {
							return (
								<MenuItem value={city} key={city}>
									{city}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
	);
}
