import React, { useContext } from 'react';
import Card from '@mui/material/Card';
import { Grid } from '@mui/material';
import { AssesmentContext } from './Exam';
function Light({ setCurrentIndex }) {
	const assObj = useContext(AssesmentContext)
 
	return (
		<>
				<Grid container>
					{assObj.questions.map((item, index) => {
						return (
							<Grid item key={item.id}>
								<Indicator key={item.id} obj={item} setCurrentIndex={setCurrentIndex} currentIndex={index} />
							</Grid>
						)
					})}
				</Grid>
		</>
	);
}

function Indicator({ obj, setCurrentIndex, currentIndex }) {
	const assObj = useContext(AssesmentContext)
	
	return (
		<div>	<button onClick={() => {
			setCurrentIndex(currentIndex)
			
		}} style={{ border: '1px solid #f1f1f1' }}>
			<div style={{
				width: '30px',
				height: "30px",
				borderRadius: "50%",
				backgroundColor:assObj.marked[obj.id]?'#ded407': assObj.status[obj.id] ? 'green':'#2196f3',
				display: 'flex',
				justifyContent: "center",
				alignItems: 'center',
				color: "white",
				fontSize: "14px",
			}}>
				{currentIndex + 1}
			</div>
		</button>
		</div>
	)
}
export default Light;
