import React, { useContext } from 'react';
import Card from '@mui/material/Card';
import { AssesmentContext } from 'ContextFiles';
import { Grid } from '@mui/material';
function Light({ setCurrentIndex }) {

	const assObj = useContext(AssesmentContext)

	return (
		<>
			<Card sx={{ width: "280px", textAlign: 'center',backgroundColor: '#f1f1f1' }}>
				<Grid container>
					{assObj.questions.map((item, index) => {
						return (
							<Grid item xs={2} sx={{ margin: '11.5px' }} key={item.id}>
								<Indicator key={item.id} obj={item} setCurrentIndex={setCurrentIndex} currentIndex={index} />
							</Grid>
						)
					})}
				</Grid>
			</Card>
		</>
	);
}

function Indicator({ obj, setCurrentIndex, currentIndex }) {
	const assObj = useContext(AssesmentContext)
	return (
		<div>	<button onClick={() => {
			setCurrentIndex(currentIndex)
			
		}} style={{ border: '1px solid #f1f1f1' }}>
			<div style={{
				width: '30px',
				height: "30px",
				borderRadius: "50%",
				backgroundColor: assObj.status[obj.id] == 0 ? "#cd1a1a" : assObj.status[obj.id] == 1 ? "#ded407" : assObj.status[obj.id] == -1 ? 'green' : "#2196f3",
				display: 'flex',
				justifyContent: "center",
				alignItems: 'center',
				color: "white",
				fontSize: "14px",
			}}>
				{currentIndex + 1}
			</div>
		</button>

		</div>
	)
}
export default Light;
