import { Typography, Grid, Box, Divider } from "@mui/material";
import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Description = ({ jd, id, cd, jid }) => {

  const arr = [{
    Description: 'CrestBell is looking a front-end web developer, who will be responsible for implementing visual elements that users see and interact with in a web application. They are usually supported by back-end web developers, who are responsible for server-side application logic and integration of the work front-end developers do.',
    resposibilities: [{ reponsibility: 'Implement responsible web design principles to ensure that our company website renders well across multiple devices.' },
    { reponsibility: 'Design a framework for creating user apps that grows with the needs of the company. ' },
    { reponsibility: 'Monitor website performance, watch for traffic drops related to site usability problems and rectify the issues.' },
    { reponsibility: 'Review app and feature coding and plan future website upgrades with the development team.' },
    { reponsibility: 'Assist in the development of applications and features that will be put on the website and in internal functions as well.' }],
    whoeareyou: [{ who: 'You have a degree in Computer Science or similar field' },
    { who: 'You must be proficient in coding languages such as HTML, CSS, JavaScript, and jQuery' },
    { who: 'You understand server-side CSS.' },
    { who: 'You are experienced with graphic design applications (e.g., Adobe Illustrator)' },
    { who: 'You have excellent skills in problem-solving' }],
    nicetohaves: [{ nice: 'Fluent in English' },
    { nice: 'Knowledge of HTML, CSS, Java Script' },
    { nice: 'Problem Solving skills' }],
    requiredskills: [{ skill: 'Java Script' },
    { skill: 'React JS' },
    { skill: 'HTML' },
    { skill: 'CSS' },
    { skill: 'Designing' },]
  }]

  return (
    <Grid container sx={{ marginTop: '90px' }}>

      <Grid lg={0.5} />
      <Grid item xs={12} lg={7} >
        {" "}
        {jd.map((job) => {
          if (jid === job.id) {
            
            return (
              <Box >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Clash Display",
                      fontWeight: "600",
                      fontSize: "32px",
                      lineHeight: "120%",
                      color: "#25324B",
                    }}
                  >
                    Description
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Epilogue",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "160%",
                      color: "#515B6F",
                    }}
                  >
                    {job.jobDescription}
                  </Typography>
                </Grid>



                <Grid item sx={{ marginTop: "4%" }}>
                  <Typography
                    sx={{
                      fontFamily: "Clash Display",
                      fontWeight: "600",
                      fontSize: "32px",
                      lineHeight: "120%",
                      color: "#25324B",
                    }}
                  >
                    Responsibilities
                  </Typography>
                  {/* {item.resposibilities.map((item1) => {
                  return ( */}
                  {job.responsibilities?.map((item1) => {
                    return (
                      <Grid item sx={{ display: "flex" }}>


                        <CheckCircleOutlineIcon
                          sx={{ color: "#56CDAD", width: "0.7em", height: "1em" }}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Epilogue",
                            fontWeight: "400",
                            fontSize: "16px",

                            color: "#515B6F",
                            marginLeft: "1%",
                          }}
                        >
                          {item1.name}
                        </Typography>
                      </Grid>
                    )
                  })}

                  {/* )
                })} */}
                </Grid>
                <Grid item sx={{ marginTop: "4%" }}>
                  <Typography
                    sx={{
                      fontFamily: "Clash Display",
                      fontWeight: "600",
                      fontSize: "32px",
                      lineHeight: "120%",
                      color: "#25324B",
                    }}
                  >
                    Who You Are
                  </Typography>
                  {/* {item.whoeareyou.map((item2) => {
                  return ( */}
                  {job.Whoareyou?.map((item2) => {
                    return (
                      <Grid sx={{ display: "flex" }}>
                        <CheckCircleOutlineIcon
                          sx={{ color: "#56CDAD", width: "0.7em", height: "1em" }}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Epilogue",
                            fontWeight: "400",
                            fontSize: "16px",

                            color: "#515B6F",
                            marginLeft: "1%",
                          }}
                        >
                          {item2.name}
                        </Typography>
                      </Grid>
                    )
                  })}

                  {/* )
                })} */}
                </Grid>
                <Grid item sx={{ marginTop: "4%" }}>
                  <Typography
                    sx={{
                      fontFamily: "Clash Display",
                      fontWeight: "600",
                      fontSize: "32px",
                      lineHeight: "120%",
                      color: "#25324B",
                    }}
                  >
                    Nice-To-Haves
                  </Typography>
                  {/* {item.nicetohaves.map((item3) => {
                  return ( */}
                  {job.niceToHave?.map((item3) => {
                    return (
                  <Grid sx={{ display: "flex" }}>
                    <CheckCircleOutlineIcon
                      sx={{ color: "#56CDAD", width: "0.7em", height: "1em" }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Epilogue",
                        fontWeight: "400",
                        fontSize: "16px",

                        color: "#515B6F",
                        marginLeft: "1%",
                      }}
                    >
                      {item3.name}
                    </Typography>
                  </Grid>
                    )})}
                  {/* )
                })} */}
                </Grid>

              </Box>)
          }
        })}
      </Grid>
      <Grid lg={0.5} />
      <Grid item xs={12} lg={3.5}>
        {" "}{jd.map((job) => {
          if (jid === job.id) {

            return (
              <Box >
                <Box>
                  <Grid item>
                    <Typography
                      sx={{
                        fontFamily: "Clash Display",
                        fontWeight: "600",
                        fontSize: "32px",
                        lineHeight: "120%",
                        color: "#25324B",
                      }}
                    >
                      About this role
                    </Typography>
                    {/* <Grid sx={{ marginTop: "20px", backgroundColor: "#F8F8FD" }}>
                      <Typography
                        sx={{ color: "#7C8493", fontSize: "14px", marginLeft: "1%" }}
                      >
                        <b>1 applied</b> of 10 capacity
                      </Typography>
                      <progress
                        value="1"
                        max={"10"}
                        style={{
                          width: "80%",
                          height: "12px",
                          marginLeft: "1%",
                          marginBottom: "1%",
                        }}
                      ></progress>
                    </Grid> */}
                    <Grid
                      item
                      display={"flex"}
                      justifyContent="space-between"
                      sx={{ marginTop: "2%" }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Epilogue",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#515B6F",
                        }}
                      >
                        Apply Before
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Epilogue",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#25324B",
                        }}
                      >
                        {job.EndDate}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display={"flex"}
                      justifyContent="space-between"
                      sx={{ marginTop: "2%" }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Epilogue",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#515B6F",
                        }}
                      >
                        Job Posted On
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Epilogue",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#25324B",
                        }}
                      >
                        {job.Startdate}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      display={"flex"}
                      justifyContent="space-between"
                      sx={{ marginTop: "2%" }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Epilogue",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#515B6F",
                        }}
                      >
                        Job Type
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Epilogue",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#25324B",
                        }}
                      >
                        {job.employmentType}
                      </Typography>
                    </Grid>{" "}
                    <Grid
                      item
                      display={"flex"}
                      justifyContent="space-between"
                      sx={{ marginTop: "2%" }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Epilogue",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#515B6F",
                        }}
                      >
                        Salary
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Epilogue",
                          fontWeight: "400",
                          fontSize: "16px",
                          color: "#25324B",
                        }}
                      >
                        INR {job.salary}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: "3%" }} />
                  <Grid item sx={{ marginTop: "3%" }}>
                    <Typography
                      sx={{
                        fontFamily: "Clash Display",
                        fontWeight: "600",
                        fontSize: "32px",
                        lineHeight: "120%",
                        color: "#25324B",
                      }}
                    >
                      Categories
                    </Typography>
                    <Grid item display={"flex"} sx={{ marginTop: "2%" }}>
                      {/* <Box
                      sx={{
                        marginLeft: "10px",
                        borderRadius: "80px",
                        border: "1px solid #FFB836",
                        
                        height: "30px",
                        color: "#FFB836",
                        textAlign: "center",
                        backgroundColor: "rgba(235, 133, 51, 0.1)",
                      }}
                    >
                      <Typography sx={{ fontSize: "15px", marginTop: "3px" ,marginLeft:'5px',marginRight:'5px'}}>
                        Web Development
                      </Typography>
                    </Box> */}

                      <Box
                        sx={{
                          marginLeft: "10px",
                          borderRadius: "80px",
                          border: "1px solid #56CDAD",

                          height: "30px",
                          color: "#56CDAD",
                          textAlign: "center",
                          backgroundColor: "rgba(86, 205, 173, 0.1)",
                        }}
                      >
                        <Typography sx={{ fontSize: "15px", marginTop: "3px", marginLeft: '5px', marginRight: '5px' }}>
                          {job.jobCategory}
                        </Typography>
                      </Box>


                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: "3%" }} />
                  <Grid item sx={{ marginTop: "3%" }}>
                    <Typography
                      sx={{
                        fontFamily: "Clash Display",
                        fontWeight: "600",
                        fontSize: "32px",
                        lineHeight: "120%",
                        color: "#25324B",
                      }}
                    >
                      Required Skills
                    </Typography>
                    <Grid item container xs={12} lg={12} md={12} sm={12} >
                      {job.Skills?.map((item) => {
                        return (
                          <Grid item xs={12} lg={12} md={12} sm={12}
                            sx={{
                              marginLeft: '5px', marginBottom: '6px', marginTop: "6px",
                              backgroundColor: "#F8F8FD",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Epilogue",
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#1A50B2",
                                textAlign: "center",
                              }}
                            >
                              {item}
                            </Typography>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Box>
              </Box>)
          }
        })}
      </Grid>
    </Grid>
  );
};

export default Description;
