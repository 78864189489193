import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import { Button, Typography, Card } from '@mui/material';
import Logo from '../../assets/images/Myopencase/Logo.png';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Bubble from '../../assets/images/Myopencase/Bubble.png';
import Waving from '../../assets/images/Myopencase/Waving.png';
import Avatar from '../../assets/images/Myopencase/Avatar.png';
import './landing.css';
import File from './File';
import File1 from './File1';
import Table1 from './Table1';
import Table2 from './Table2';
import Myopencase from './Myopencase';
import Myopencase1 from './Myopencase1';
import Card1 from './Card';
import Drone from '../../assets/images/Myopencase/Drone.png';
import Hand from '../../assets/images/Myopencase/Hand.png';
import Workshop from '../../assets/images/Myopencase/Workshop.png';
import WorkComponent from '../CV/WorkComponent';
import AchivementComponent from '../CV/AchivementsComponent';
import EducationComponent from '../CV/EducationComponent';
import Hobbies from '../CV/Hobbies';
import { UserContext } from "ContextFiles";
import SkillsList from '../CV/SkillsList';
import Contact from '../CV/Contact';
import Training from '../CV/Training';
import { Box } from '@mui/system';
import { NavLink } from 'react-router-dom';
import Video from 'views/Navlink/player/Videoplayer';
import q1 from '../../assets/images/Myopencase/q1.png';
import "../../../node_modules/video-react/dist/video-react.css";
// import "../../../node_modules/video-react/styles/scss/components/video-react.scss";
import { Player } from 'video-react';

export default function Landing() {
    const [data, setData] = useState({});
    const check = {}
    const { user } = useContext(UserContext);
    useEffect(() => {
        axios({
            method: "post",
            url: "https://cms.keewesolutions.com/opencase/get",
            data: {
                userId: user.id
            }
        }).then((response) => {
            const result = response.data.Item;
            setData(result)
        })
    }, [])
   
    return (
        <>
                <Grid container marginTop={'20px'} sx={{}}>
                    <Grid xs={12} lg={5} direction='column' justifyContent={'center'}>
                        <Grid style={{ marginLeft: '3%', marginTop: '8%' }}>
                            <Typography style={{ fontSize: '3.125rem', color: '#375dbe' }} >
                                {data.fname + ' ' + data.lname}
                            </Typography>
                        </Grid>
                        <Typography variant="h1" sx={{ fontSize: '3.125rem', margin: '2% 4%' }}>
                            Hi, Welcome To My OpenCase
                        </Typography>
                        {/* <Grid style={{ marginLeft: '3%', marginTop: '40px' }}>
                            <Button variant="contained" size="large" style={{ backgroundColor: '#375DBE', borderRadius: '0px' }}>
                                Educational Details
                            </Button>
                        </Grid> */}
                    </Grid>
                    <Grid item lg={1.5}></Grid>
                    <Grid xs={12} lg={4.5} sx={{marginTop:'2%'}}>
                     
                            <Player
                                playsInline
                                poster="/assets/poster.png"
                                src={data.videoUrl}
                            />
                            {/* <Video /> */}
                            {/* className="bubble-image"
                            <img src={Bubble} alt="bubble" />
                            <Grid className="waving-image" style={{ size: 'auto' }}>
                                <img src={Waving} alt="waving" />
                            </Grid> */}
                     
                    </Grid>
                </Grid>
           
            <Grid container marginTop={'40px'}>
                <Grid xs={3} lg={0.10}></Grid>
                <Grid  lg={1.7} xs={6}  >
                    <img src={data.imageUrl} alt="" style={{ width: '100%', marginTop: '-10%', marginLeft: '0%',backgroundColor:'white' }} />
                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={12} lg={6}>
                    <Typography variant="h3" sx={{ color: '#375DBE',marginTop:'2%' }}>
                        Let's Know Me
                    </Typography>
                    <Typography variant="" sx={{ fontSize: '2rem', lineHeight: '2',color:'#212121'}}>
                        {data.headline}
                    </Typography>
                    <Typography sx={{ fontFamily: 'Roboto,sans-serif', fontSize: '16px', lineHeight: '1.5',color:'#616161',letterSpacing:'0.02em'}}>
                        {data.summary}
                    </Typography>
                </Grid>
            </Grid>
            {/* <File1 /> */}
            {/* <Table1 /> */}
            <section className="cont">
                <Typography sx={{ fontFamily: 'Roboto,sans-serif', fontSize: '2rem', fontWeight: '700', color: '#375DBE', marginLeft: '2%', marginTop: '3%' }}>
                    {" "}
                    Contact{" "}
                </Typography>
                <Contact cont={data} />

            </section>
            {/* <> */}
            <Typography sx={{ fontFamily: 'Roboto,sans-serif', fontSize: '2rem', fontWeight: '700', color: '#375DBE', marginLeft: '2%', marginTop: '3%' }}>
                Skills
            </Typography>
            <SkillsList skill={data.SkillArr} />
            <section className="work">
                <Typography sx={{ fontFamily: 'Roboto,sans-serif', fontSize: '2rem', fontWeight: '700', color: '#375DBE', marginLeft: '2%', marginTop: '3%' }}>
                    <i className="fa fa-briefcase fa" /> Work Experience{" "}
                </Typography>
                <WorkComponent work={data.workArray} /> 
            </section>


            <section className="education">
                <Typography sx={{ fontFamily: 'Roboto,sans-serif', fontSize: '2rem', fontWeight: '700', color: '#375DBE', marginLeft: '2%', marginTop: '3%' }}>
                    {" "}
                    <i className="fa fa-university" /> Education{" "}
                </Typography>
                <EducationComponent edu={data.educationArr} /> 
            </section>
            <section className="certi">
                <Typography sx={{ fontFamily: 'Roboto,sans-serif', fontSize: '2rem', fontWeight: '700', color: '#375DBE', marginLeft: '2%', marginTop: '3%' }}>
                    {" "}
                    <i className="fa fa-university" /> Training and Certificates{" "}
                </Typography>
                <Training certi={data.trainingArr} /> 
            </section>

            <section className="achivements">
                <Typography sx={{ fontFamily: 'Roboto,sans-serif', fontSize: '2rem', fontWeight: '700', color: '#375DBE', marginLeft: '2%', marginTop: '3%' }}>
                    {" "}
                    <i className="fa fa-trophy" /> Achievements{" "}
                </Typography>
                <AchivementComponent achive={data.achivementsArr} />
            </section>
            <section className="hobbies">
                <Typography sx={{ fontFamily: 'Roboto,sans-serif', fontSize: '2rem', fontWeight: '700', color: '#375DBE', marginLeft: '2%', marginTop: '3%' }}>
                    {" "}
                    Hobbies{" "}    i
                </Typography>
                <Hobbies hob={data.hobbies} />
            </section>

            {/* <Grid sx={{marginTop:'2%'}}>
                <Grid container alignItems={'center'} marginLeft={'1.5%'}>
                    <Grid xs={4}>
                        <Card1 img={Drone} title="Quadcopter" />
                    </Grid>
                    <Grid xs={4}>
                        <Card1 img={Hand} title="Robo Hand" />
                    </Grid>
                    <Grid xs={4}>
                        <Card1 img={Workshop} title="3D Printing" />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid>
                        <Grid></Grid>
                        <Grid></Grid>
                    </Grid>
                    <Grid></Grid>
                </Grid>
            </Grid> */}

            {/* // <Grid container style={{textAlign:"start" }}>
        //     <div className="logo" style={{display: 'flex'}}>
        //         <Grid xs={12} >
        //             <img src={Logo} alt="logo" />
        //         <Grid xs={6}>
        //             <Typography style={{color:"darkblue", fontWeight:"bold"}}>
        //                 My Open<span style={{color:"#375DBE"}}>case</span>
        //             </Typography>
        //         </Grid>
        //             <Grid xs ={6}>
        //                 <Button 
        //                 variant="contained"
        //                 size="large"
        //                 style={{backgroundColor:"#375DBE", borderRadius:"0px", justifyContent:"right"}}
        //                 >
        //                 Hire Me !
        //                 </Button>
        //             </Grid>
        //         </Grid>
        //     </div>

        //    <Grid xs={6}>
        //    <Typography style={{color:"#375DBE"}}>
        //     MAHI SHARMA
        //    </Typography>
        //    <Typography style={{color:"black", fontSize:"40px", fontWeight:"bolder", fontFamily:"sans-serif"}}>
        //     Hi, Welcome To<br/>
        //     My OpenCase
        //    </Typography>
        //   </Grid>
        //   <Grid xs={6}>

        //   </Grid>
        //   <Grid xs={9}>
        //    <Typography style={{color:"grey"}}>
        //     <p>"Life is our resume. It is our story to tell, and the choices we make write the chapters.<br/>Can we live in a way where we look forward to looking back?"</p>
        //    </Typography>
        //   </Grid>
        //   <Grid xs ={12}>
        //   <Button
        //   variant="contained"
        //   size="large"
        //   style={{backgroundColor:"#375DBE", borderRadius:"0px"}}
        //   >

        //   Educational Details
        //   </Button>
        //   <Grid xs={12} >
        //   <img src={Avatar} alt="avatar" style={{float:"right"}}/>
        //   </Grid>

        //   </Grid>

        // </Grid>*/}
        </>
    );
}
