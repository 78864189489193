import { Link, useNavigate } from 'react-router-dom';
import { ButtonBase, Typography } from '@mui/material';
import config from 'config';


// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    
    <a href="https://lms.crestbellsupport.com" style={{textDecoration: 'none'}}>
        <ButtonBase disableRipple component={Link} to={config.defaultPath} disabled>
            <Typography sx={{ fontSize: "18px", marginLeft: "5px", color: '#616161' }}> Crestbell Online </Typography>
        </ButtonBase>
    </a>
);

export default LogoSection;
