import { Typography, Grid, Card, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import Icon1 from "../new/Icon1.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Icon2 from "../new/Icon2.png";
import Icon3 from "../new/Icon3.png";
import Icon4 from "../new/Icon4.png";
import Icon5 from "../new/Icon5.png";
import Icon6 from "../new/Icon6.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Category1 = ({cdata}) => {
  const [data, setData] = React.useState([{}]);

  useEffect(() => {
      axios({
          method: "post",
          url: "https://po.keewesolutions.com/jp/alljob",

      }).then((response) => {
          const result = response.data.Items;
          setData(result)
          
      })
  }, [])
  const navigate = useNavigate()
  const arr = [
    
    {
      icon: Icon1,
      name: "Design",
      value:"Designer",
      jobs: "Jobs available",
    },
    {
      icon: Icon2,
      name: "Web Developer",
      value:"Web Developer",
      jobs: "Jobs available",
    },
    {
      icon: Icon1,
      name: "Cloud Computing",
      value: "Cloud Computing",
      jobs: "Jobs available",
    },
    {
      icon: Icon3,
      name: "App Developer",
      value: "App Developer",
      jobs: "Jobs available",
    },
    {
      icon: Icon5,
      name: "Programmer",
      value: "Programmer",
      jobs: "Jobs available",
    },
    {
      icon: Icon4,
      name: "Others",
      value: "Others",
      jobs: "Jobs available",
    }
    // {
    //   icon: Icon3,
    //   name: "Business",
    //   jobs: "Jobs available",
    // },
    // {
    //   icon: Icon6,
    //   name: "Human Resources",
    //   jobs: "Jobs available",
    // },
  ];
  return (
    <Grid container xs={12} lg={12} md={12} sm={12} sx={{marginTop:"20px"}} >
      <Grid item lg={1}></Grid>
      <Grid item xs={12} lg={11} md={12} sm={12} >
        <Typography
          sx={{
            fontFamily: "Clash Display",
            fontWeight: "600",
            fontSize: "48px",
            lineHeight: "110%",
            color: "#25324B",
          }}
        >
          Explore by <span style={{ color: "#26A4FF" }}>category</span>
        </Typography>
        <Grid>
          <Grid container item xs={12} lg={11} >
            {arr.map((item, index) => {
              return (
                <Grid item lg={3} xs={6} md={4} sm={4}  marginTop="2%">
                  <Card
                    sx={{
                      padding: "6%",
                      width: "auto",
                      height: "178px",
                      border: "1px solid #D6DDEB",
                      marginRight:'3%',
                      marginBottom: '2%'
                    }}
                  >
                    <img src={item.icon} alt="" />
                    <Typography
                      sx={{
                        fontFamily: "Clash Display",
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "120%",
                        color: "#25324B",
                        marginTop: "10%",
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Epilogue",
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "160%",
                        color: "#7C8493",
                        // marginTop: "4%",
                        // marginBottom: '2%',
                        letterSpacing: ".05em",
                      }}
                    >
                      {item.jobs}{" "}
                      <IconButton sx={{ marginTop: "-2%" }}>
                        <ArrowRightAltIcon 
                        // onClick={()=>navigate('/job-category',{state:{data:data,category:item.value, cdata:cdata}})} 
                        />
                      </IconButton>
                    </Typography>
                  </Card>{" "}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Category1;
