import {
	Grid,
	Box,
	Typography,
	IconButton,
	Button,
	Modal,
	FormControlLabel,
	Checkbox,
	TextField,
	FormLabel,
	Input,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import React, { useState, useContext } from 'react';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ResumeContext } from 'ResumeContext';
import axios from 'axios';


const style = {
	bgcolor: 'background.paper',

	boxShadow: 24,
	p: 4,

	marginTop: '30px'
};

export default function Hobbies({ hc, index }) {
	const { data, setData } = useContext(ResumeContext);
	const onNext = () => {
		
	
		axios({
			method: "post",
			url: "https://cms.keewesolutions.com/opencase/put",
			data: {
				...data,

			}
		}).then((response) => {
			alert("Resume Template Will Be Updated Soon")
		})
	}
	const [hobbies, setHobbies] = useState('');
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [start, setstart] = useState('');

	const handleChange = (newValue) => {
		setstart(newValue.getDate() + '/' + newValue.getMonth() + '/' + newValue.getFullYear());
	};
	const [end, setend] = useState('');

	const [title, settitle] = useState('');

	return (
		<Grid container>
			<Grid item xs={12} marginBottom="1%">
				<Typography variant="h5" fontFamily="poppins" color="#25d366" marginBottom="1%" fontWeight="bold">
					Hobbies
				</Typography>
			</Grid>
			<Grid item xs={12} marginBottom="1%">
				<Typography variant="h7" fontFamily="poppins" color="#4a4a4a">
					What keeps you alive other than work?
				</Typography>
			</Grid>
			<Grid item xs={12} lg={6} sx={{ marginTop: '20px' }}>
				{data.hobbies.map((item, index) => {
					return (
						<Box sx={{ border: '1px solid #177acc', width: '90%', marginBottom: '2%', paddingBottom: '2%' }}>
							<Grid
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									marginLeft: '10px'
								}}
							>
								<Typography sx={{ marginTop: '7px', fontFamily: 'poppins' }}>
									<b>{item.title}</b>
								</Typography>
								<IconButton
									onClick={() => {
										let newarr = [...data.hobbies];
										newarr.splice(index, 1);
										setData({ ...data, hobbies: newarr });
									}}
								>
									<CloseIcon />
								</IconButton>
							</Grid>
						</Box>
					);
				})}
			</Grid>
			<Grid item xs={1} />
			<Grid item xs={12} lg={6} sx={{ marginTop: '20px' }}>
				<Box sx={{ border: '1px solid #177acc', width: '90%' }}>
					<Grid sx={{ display: 'flex' }} onClick={handleOpen}>
						<Typography sx={{ fontFamily: 'poppins' }}>
							<IconButton sx={{ color: '#177acc' }}>
								<AddIcon />
							</IconButton>
							Add Hobbies
						</Typography>
					</Grid>
				</Box>
			</Grid>

			<Modal
				open={open}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{ overflowX: 'scroll', overflowY: 'scroll' }}
			>
				<Grid container>
					<Grid item xs={1} lg={3.5} />
					<Grid item xs={10} lg={5}>
						<Box sx={style}>
							<Grid sx={{ justifyContent: 'space-between', display: 'flex' }}>
								<Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontFamily: 'poppins' }}>
									Add Hobbies
								</Typography>
								<IconButton onClick={handleClose}>
									<CloseIcon />
								</IconButton>
							</Grid>

							<Grid marginTop={'10px'} sx={{ marginBottom: '2%' }}>
								<FormLabel>
									<InputLabel sx={{ fontFamily: 'poppins' }}>
										Enter your Hobby:
										<br />
										<TextField
											type="text"
											label="Eg: Sketching"
											sx={{ marginTop: '10px', width: '100%', marginBottom: '2%' }}
											value={title}
											onChange={(e) => settitle(e.target.value)}
										/>
									</InputLabel>
								</FormLabel>
							</Grid>
							<Button
								variant="contained"
								style={{
									marginTop: '10px',
									borderRadius: '40px',
									width: '100px'
								}}
								onClick={() => {
									if (title.length != 0) {
										let newArr = [...data.hobbies];
										let newObj = {
											title: title
										};

										newArr.push(newObj);
										setData({ ...data, hobbies: newArr });
									}
									else { return null }
								}}
							>
								Add
							</Button>
						</Box>
					</Grid>
				</Grid>
			</Modal>
			<Grid item lg={3} />
			<Grid item xs={8} lg={5}  md={9} sm={9} display="flex" marginTop="2%">
				<Typography fontFamily="poppins" onClick={(e) => hc(e, index - 1)} sx={{ cursor: 'pointer' }}>
					<IconButton>
						<ArrowBackIcon />
					</IconButton>
					Previous
				</Typography>
			</Grid>
			<Grid item xs={4} lg={4}  md={3} sm={3} marginTop='2%'>
				<Typography fontFamily="poppins"
				
					sx={{ cursor: 'pointer' }}>
					Save
					<IconButton onClick={onNext}>
						<ArrowForwardIcon />
					</IconButton>
				</Typography>
			</Grid>
		</Grid>
	);
}
