import React from 'react';
import { Grid, Typography, Box, TextField, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
// import pageman from './pageman.png';
import pageman from './Pman.png'
import "./Page.css";

const page2 = ({value, setValue}) => {
  const defaultProps = {
    options: locations,
    getOptionLabel: (option) => option.title,
  };
  return (
    <Grid container xs={12} lg={11} md={12} sm={12}  marginTop='2%'>
      <Grid item lg={1}></Grid>
      <Grid item xs={12} lg={7} md={12} sm={12} padding={"1% 0 0 0"}>
        {/* <Typography sx={{
          fontFamily: 'Clash Display',
          fontWeight: '700',
          fontSize: '6rem',
          lineHeight: '110%',
          color: '#25324B'
        }}>
          Discover<br /> more than <br /> <span style={{ color: '#26A4FF' }}>5000+ Jobs</span>
        </Typography>
        <Typography sx={{
          fontFamily: 'Epilogue',
          fontWeight: '600',
          fontSize: '30px',
          lineHeight: '160%',
          color: '#515B6F', marginTop: '4%'
        }}>
          Great platform for the job seeker that searching for<br /> new career heights and passionate about startups.
        </Typography> */}
        <Box sx={{
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 79px 128px rgba(192, 192, 192, 0.09), 0px 28.8363px 46.7221px rgba(192, 192, 192, 0.0598508), 0px 13.9995px 22.6827px rgba(192, 192, 192, 0.0475723), 0px 6.86281px 11.1195px rgba(192, 192, 192, 0.0380675), 0px 2.71357px 4.39666px rgba(192, 192, 192, 0.0270615)',
          display: 'flex', justifyContent: 'left', padding: '1%'
        }}>
          <Box>
            <SearchIcon sx={{ color: "rgba(0, 0, 0, 0.6)", marginTop: '15%' }} /> <TextField onChange={e=>setValue(e.target.value)} id="standard-basic" label="Job title or keyword" variant="standard" sx={{ color: '#D6DDEB' }} />
          </Box>
          {/* <Box display={"flex"}>
            <LocationOnOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.6)", marginTop: '8%' }} />
            <Stack spacing={2} sx={{ width: 200 }}>
              <Autocomplete
                {...defaultProps}
                id="disable-close-on-select"
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField {...params} label="Location" variant="standard" />
                )}
              />
            </Stack> </Box> */}
          {/* <Button variant="contained" sx={{ textTransform: 'capitalize', marginLeft:"5%" }}>
            Search 
          </Button> */}
        </Box>
        <Typography sx={{
          fontFamily: 'Epilogue',
          fontWeight: '200',
          fontSize: '18px',
          lineHeight: '160%',
          color: '#202430', marginTop: '1%'
        }}>
          Popular : UI Designer, UX Researcher, Android, Admin
        </Typography>
      </Grid>
      {/* <Grid  lg={4} sx={{marginTop:"-5%"}}>
        <img src={pageman} alt="" className='photo'  />
      </Grid> */}
    </Grid>
  )
}

export default page2





const locations = [
  { title: 'Jammu , India' },
  { title: 'Kashmir , India' },
  { title: 'Chandigarh , India' },

];