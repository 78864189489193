import React, { useContext } from 'react'
import { Grid, Card, Typography } from "@mui/material";



function Hobbies({hob}) {

  return (
    <>
      <Card>
        <Grid container>
          {hob?.map((key, index) =>
            <Grid item lg={6} xs={12}>
              <Card sx={{ padding: '4%', backgroundColor: '#f3f5fb', margin: '8%' }}>
                <Typography sx={{ fontFamily: 'poppins', fontSize: '16px', fontWeight: '500' }}> {key.title}</Typography>


              </Card></Grid>
          )}</Grid>
        </Card>
    </>
  )
}

export default Hobbies