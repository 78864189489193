import { useSelector } from 'react-redux';
import React, { lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicAlerts from 'Deepak/Alert';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import CourseLayout from 'views/utilities/CurrentCourses/CourseLayout';
import Incubation from 'views/Navlink/Incubation';
import Courses from 'views/utilities/CurrentCourses/Coursess';
import Incubations from 'views/Navlink/Incubations';
import HomePage from 'Namrata/Research/HomePage';
import ResearchFeed from 'Namrata/Research/ResearchFeed';
import HomePage2 from 'views/Navlink/HomePage2';
import IdeaCard from 'views/Navlink/IdeaCard';
import RegisterCard from './formlogin/components/RegisterCard';
import { IfPaidContext, UserContext } from 'ContextFiles';
import BasicInfo from 'Namrata/components/BasicInfo';
import JobPage from 'JobPortal/App';
import Findjobs from './JobPortal/Assemble/Findjobs'
import Browser from "../src/JobPortal/Assemble/Browsecompanies"
import CrestBelldescription from 'JobPortal/Assemble/crestbellDescription';
import Searchcompaniesresult from './JobPortal/Assemble/Searchcompaniesresult';
import Companyprofile from './JobPortal/Assemble/Companyprofile';
import Notify from './views/utilities/CurrentCourses/coursesadd';
// dashboard routing
const DashboardDefault = Loadable(lazy(() =>
    import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() =>
    import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() =>
    import('views/utilities/Color')));


const UtilsTypography1 = Loadable(lazy(() =>
    import('views/utilities/Typography1')));   



import { Routes, Route } from 'react-router-dom';
import ForgotPassword from 'formlogin/components/ForgotPasswordEmail';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Verification from 'formlogin/components/Verification';
import { useState, useEffect } from 'react';
import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';
import Researchinfo from 'Deepak/Researchinfo';
import axios from 'axios';
import { HomeFinal } from 'Opencase/App';
import FinalResume from 'Opencase/Resume/FinalResume';
import Certi from 'Certificate';
import Landing from 'Opencase/My-opencase/Landing';
import Fillfirst from 'Opencase/components/Fillfirst';
import Resumetabs from 'Opencase/Tempelate/Resumetabs';
import AssesmentFinal from 'finalassesment/AssesmentFinal';
import Week from 'Week/Week';
import Progressbar from 'views/utilities/CurrentCourses/Progressbar';
import JobCategory from 'JobPortal/NehaBhagat1/new/Job-category';
import PageNotFound from 'PageNotFound';
import Exam from './Exam/App'
import TablerIcons from 'Coursecertificate';


const App = () => {

    const customization = useSelector((state) => state.customization);
    const [data, setData] = useState([])
    const [authState, setAuthState] = useState({
        authenticated: false,
        user: {},
    })
    const navigate = useNavigate()
    useEffect(() => {
        const loggedInUser = localStorage.getItem("keewe.lmsStorage");
        if (loggedInUser) {
            const foundUser = JSON.parse(loggedInUser);
            axios({
                url: "https://cms.keewesolutions.com/check",
                method: "POST",
                data: {
                    token: foundUser.user.idToken
                }
            }).then(res => {
                if (res.data.callStatusCode === 200) {
                    let newAuthState = { ...authState }
                    newAuthState.authenticated = true
                    let newPayload = res.data.response
                    let newUser = {
                        id: newPayload['cognito:username'],
                        email: newPayload.email,
                        name: newPayload.name,
                        idToken: foundUser.user.idToken,
                        refreshToken: foundUser.user.refreshToken.token
                    }
                    newAuthState.user = newUser
                    const getData = async()=>{
                        await axios({
                            url:"https://cms.keewesolutions.com/findIfPaid",
                            method:"POST",
                            data:{userId:newAuthState.user.id}
                        }).then((res)=>{
                            
                            setData(res.data.Items)
                        })
                    }
                    getData();
                    setAuthState(newAuthState)
                } else {
                    navigate({ pathname: "/login/", search: location.search })
                }
            }).catch(e => console.log(e))
        }
    }, []);
    return (
        <>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <UserContext.Provider value={{ ...authState, setNewUser: setAuthState, authState }}>
                            <IfPaidContext.Provider value={{data, setData}}>
                            {authState.authenticated ?
                             data.length>0 || authState.user.email == "courses@gmail.com"?
                                <Routes>
                                    <Route path="/exam" element={<Exam/>}/>
                                    <Route path='/' element={< MainLayout />}>
                                        <Route index element={< UtilsTypography />} />
                                        <Route path='/alert' element={< BasicAlerts />} />
                                        <Route path='get_certificate' element={<Certi />} />
                                        <Route path='/dashboard' element={< DashboardDefault />} />
                                        <Route path='/basicinfo' element={<BasicInfo />} />
                                        <Route path='/current-courses' element={< UtilsTypography />} />
                                        <Route path='/newcoursesadded' element={< UtilsTypography1 />} />
                                        <Route path='/progress' element={< Progressbar />} />
                                        <Route path='/job-category' element={< JobCategory />} />
                                        <Route path='latest-updates' element={< Notify />} />
                                        <Route path='/job-portal' element={<JobPage />} />
                                        <Route path='/CourseLAnding' element={< CourseLayout />} />
                                        <Route path='/Incubation' element={<Incubation />} />
                                        <Route path='/CourseLAnding/Coursess' element={< Courses />} />
                                        <Route path='/Incubations' element={<Incubations />} />
                                        <Route path='/synergy' element={<HomePage />} />
                                        <Route path='/researchfeed' element={<ResearchFeed />} />
                                        <Route path='/submitted-ideas' element={<HomePage2 />} />
                                        <Route path='/show-details' element={<IdeaCard />} />
                                        <Route path='/researchprofile' element={<Researchinfo />} />
                                        <Route path='/home' element={<HomePage />} />
                                        <Route path='/my-open-case' element={<HomeFinal />} />
                                        <Route path="/finalresume" element={<FinalResume />} />
                                        <Route path="/findjobs" element={<Findjobs />} />
                                        <Route path="/searchcompanies" element={<Browser />} />
                                        <Route path="/companiesresult" element={<Searchcompaniesresult />} />
                                        <Route path={"/Jobs"} element={<CrestBelldescription />} />
                                        <Route path="/companyprofile" element={<Companyprofile />} />
                                        <Route path="/user/resume" element={<Landing />} />
                                        <Route path="/user/templates" element={<Resumetabs />} />
                                        <Route path="/fillfirst" element={<Fillfirst />} />
                                        <Route path="assesment/" element={<AssesmentFinal />} />
                                        <Route path='/certificate' element={<Certi/>}/>
                                        <Route path='/week' element={<Week/>}/>
                                        <Route path='/coursecertificate' element={<TablerIcons/>}/>
                                    </Route>
                                </Routes>
                                 :<Routes>
                                    <Route path='/' element={< MainLayout />}>
                                        <Route index element={< UtilsTypography />} />
                                        <Route path='/alert' element={< BasicAlerts />} />
                                        <Route path='get_certificate' element={<PageNotFound />} />
                                        <Route path='/dashboard' element={<PageNotFound />} />
                                        <Route path='/basicinfo' element={<PageNotFound />} />
                                        <Route path='/current-courses' element={<PageNotFound />} />
                                        <Route path='/newcoursesadded' element={<PageNotFound />} />
                                        <Route path='/progress' element={<PageNotFound />} />
                                        <Route path='/job-category' element={<PageNotFound />} />
                                        <Route path='latest-updates' element={<PageNotFound />} />
                                        <Route path='/job-portal' element={<PageNotFound />} />
                                        <Route path='/CourseLAnding' element={<PageNotFound />} />
                                        <Route path='/Incubation' element={<PageNotFound />} />
                                        <Route path='/CourseLAnding/Coursess' element={<PageNotFound />} />
                                        <Route path='/Incubations' element={<PageNotFound />} />
                                        <Route path='/synergy' element={<PageNotFound />} />
                                        <Route path='/researchfeed' element={<PageNotFound />} />
                                        <Route path='/submitted-ideas' element={<PageNotFound />} />
                                        <Route path='/show-details' element={<PageNotFound />} />
                                        <Route path='/researchprofile' element={<PageNotFound />} />
                                        <Route path='/home' element={<PageNotFound />} />
                                        <Route path='/my-open-case' element={<PageNotFound />} />
                                        <Route path="/finalresume" element={<PageNotFound />} />
                                        <Route path="/findjobs" element={<PageNotFound />} />
                                        <Route path="/searchcompanies" element={<PageNotFound />} />
                                        <Route path="/companiesresult" element={<PageNotFound />} />
                                        <Route path={"/Jobs"} element={<PageNotFound />} />
                                        <Route path="/companyprofile" element={<PageNotFound />} />
                                        <Route path="/user/resume" element={<PageNotFound />} />
                                        <Route path="/user/templates" element={<PageNotFound />} />
                                        <Route path="/fillfirst" element={<PageNotFound />} />
                                        <Route path="assesment/" element={<PageNotFound />} />
                                        <Route path='/certificate' element={<PageNotFound />}/>
                                        <Route path='/week' element={<PageNotFound />}/> 
                                    </Route>
                                </Routes>
                                : (
                                    <>
                                        <Routes>
                                            <Route path="/" element={<RegisterCard id={1} search={location.search} />} />
                                            <Route path="register/" element={<RegisterCard id={0} search={location.search}/>} />                              
                                            <Route path="register/step2/:courseId" element={<RegisterCard id={2} search={location.search}/>} /> 
                                            <Route path="register/step2/" element={<RegisterCard id={2} search={location.search} state={location.state}/>} />         
                                            <Route path="register/:courseId" element={<RegisterCard id={0} search={location.search} />} />
                                            <Route path="login/" element={<RegisterCard id={1} search={location.search} />} />
                                            <Route path="login/:courseId" element={<RegisterCard id={1} search={location.search} />} />
                                            <Route path="verify/" element={<Verification />} />
                                            <Route path="forgotpassword/" element={<ForgotPassword />} />
                                            <Route path='/certificate' element={<Certi/>}/>
                                            <Route path='/week' element={<Week/>}/>
                                        </Routes>
                                    </>
                                    )
                            }
                            </IfPaidContext.Provider>
                        </UserContext.Provider>
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
            {/* <Currentpro/>  */}
        </>
    );
};

export default App;