import {
    Grid,
    Box,
    Typography,
    IconButton,
    Button,
    Modal,
    FormControlLabel,
    Checkbox,
    TextField,
    FormLabel,
    Input,
    InputLabel,
    Select,
    MenuItem,
  } from "@mui/material";
  import AddIcon from "@mui/icons-material/Add";
  
  import React, { useContext, useState } from "react";
  import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import CloseIcon from "@mui/icons-material/Close";
  import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DropStates from "Opencase/components/Countries";
import { ResumeContext } from 'ResumeContext';
import axios from 'axios';

  
  const style = {
    bgcolor: "background.paper",
  
    boxShadow: 24,
    p: 4,
  
    marginTop: "30px",
  };
  
  export default function WorkExperience({ hc, index }) {
    const [arr, setArr] = useState([]);
  const {data,setData} = useContext(ResumeContext)
  const onNext=()=>{
		
		axios({
			method:"post",
			url:"https://cms.keewesolutions.com/opencase/put",
			data:{...data
			}
		}).then((response)=>{
			hc("",index+1);
		})
	}
    
const [trainingcerf, setTrainingcerf] = useState("");
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [start, setstart] = useState("");
    const [start1, setstart1] = useState("");
  
    const handleChange = (newValue) => {
      const dd = newValue.$D+'/'+(newValue.$M+1)+'/'+newValue.$y
      setstart(
        dd
      );
      setstart1(newValue)
    };
    const [end, setend] = useState("");
    const [end1, setend1] = useState("");
  
    const handleChange1 = (newValue1) => {
      const e = newValue1.$D+'/'+(newValue1.$M+1)+'/'+newValue1.$y
      if (current) {
        setend("current");
        return null;
      }
      setend(
        e
      );
      setend1(newValue1)

    };
    const [title, settitle] = useState("");

    const [organization, setorganization] = useState("");
    const [locationcity, setlocationcity] = useState("");
    const [current, setcurrent] = useState(false);
    const [description, setdescription] = useState("");
  
    return (
      <Grid container>
        <Grid item xs={12} marginBottom="1%">
          <Typography
            variant="h5"
            fontFamily="poppins"
            color="#25d366"
            marginBottom="2%"
            fontWeight="bold"
          >
            Training and Certifications
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom="3%">
          <Typography variant="h7" fontFamily="poppins" color="#4a4a4a">
             Share with us your upskilling journey
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ marginTop: "20px" }}>
          {data.trainingArr.map((item, index) => {
            return (
              <Box sx={{ border: "1px solid #177acc", width: "90%", marginBottom: '2%', paddingBottom: '2%' }}>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "10px",
                  }}
                >
                  <Typography sx={{ marginTop: "7px", fontFamily: "poppins" }}>
                    <b>{item.title}</b>
                  </Typography>
                  <IconButton
                    onClick={() => {
                      let newarr = [...data.trainingArr];
                      newarr.splice(index, 1);
                      setData({...data,trainingArr:newarr});
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid marginLeft={"10px"}>
                  <Typography fontFamily="poppins">
                    Organization Name:&nbsp;<b>{item.organization}</b>,
                  </Typography>
                  
                  <Typography fontFamily="poppins">
                    Location:&nbsp;<b>{item.locationcity}</b>
                  </Typography>
                </Grid>
                <Grid display={"flex"} marginTop="10px" marginLeft={"10px"}>
                  <Typography fontFamily="poppins">
                    <b>{item.start?.toString()}</b>
                  </Typography>
                  <Typography fontFamily="poppins">
                    {" "}
                    &nbsp;to
                    <b>&nbsp;{item.end?.toString()}</b>
                  </Typography>
                  
                </Grid>
                <Grid marginLeft='10px'>
                  <Typography fontFamily="poppins">
                    Short Description:&nbsp;<b>{item.description}</b>,
                  </Typography>
                  </Grid>
              </Box>
            );
          })}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={12} lg={6} sx={{ marginTop: "20px" }}>
          <Box sx={{ border: "1px solid #177acc", width: "90%" }}>
            <Grid sx={{ display: "flex", cursor: 'pointer' }} onClick={handleOpen}>
              <Typography sx={{ fontFamily: "poppins" }}>
              <IconButton sx={{ color: "#177acc" }}>
                <AddIcon />
              </IconButton>
                Add Training/Certifications
              </Typography>
            </Grid>
          </Box>
        </Grid>
  
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <Grid container>
            <Grid item xs={1} lg={3.5} />
            <Grid item xs={10} lg={5}>
              <Box sx={style}>
                <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ fontFamily: "poppins" }}
                  >
                    Add Training/Certifications
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
  
                <Grid marginTop={"10px"}>
                  <FormLabel>
                    <InputLabel sx={{ fontFamily: "poppins" }}>
                      Title:
                      <br />
                      <TextField
                        type="text"
                        label="Eg: Machine Learning Specialization"
                        sx={{ marginTop: "10px", width: "100%" }}
                        value={title}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </InputLabel>
                   
                    <InputLabel sx={{ marginTop: "10px", fontFamily: "poppins" }}>
                      Organization Name:
                      <br />
                      <TextField
                        type="text"
                        label="Eg: Coursera"
                        sx={{ marginTop: "10px", width: "100%" }}
                        value={organization}
                        onChange={(e) => setorganization(e.target.value)}
                      />
                    </InputLabel>
                    <InputLabel sx={{ marginTop: "10px", fontFamily: "poppins" }}>
                      Location: <br />
                      <Grid container sx={{ display: "flex", marginBottom:'2%' }}>
                        <Grid item xs={6} lg={6} sx={{ marginTop: "10px" }}>
                        
                        
                        <TextField
                            type="text"
                            label="City"
                            sx={{ width: "99%" }}
                            //   sx={{ marginLeft: "10px" }}
                            value={locationcity}
                            onChange={(e) => setlocationcity(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </InputLabel>
                   
  
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Typography fontFamily='poppins' >Period of Time:</Typography>
                      <Grid
                        sx={{ display: "flex", justifyContent: "space-between", marginTop:'2%' }}
                      >
                        <DesktopDatePicker
                          label="Start Date"
                          inputFormat="DD/MM/YYYY"
                          value={start1}
                          onChange={handleChange}
                          renderInput={(params) => <TextField {...params} />}
                          sx={{ marginRight: "1%" }}
                        />
  
                        {!current ? (
                          <DesktopDatePicker
                            label="End Date"
                            inputFormat="DD/MM/YYYY"
                            value={end1}
                            onChange={handleChange1}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        ) : (
                          <TextField value={"currently Working"} />
                        )}
                      </Grid>
                    </LocalizationProvider>
                  </FormLabel>
                  <InputLabel sx={{ marginTop: "10px", fontFamily: "poppins" }}>
                  Short Description:
                  <br />
                  <TextField
                    type="text"
                    label="Write something about the training/course"
                    sx={{ marginTop: "10px", width: "100%" }}
                    value={description}
                    onChange={(e) => setdescription(e.target.value)}
                  />
                </InputLabel>
                </Grid>
                <Button
                  variant="contained"
                  style={{
                    marginTop: "10px",
                    borderRadius: "40px",
                    width: "100px",
                  }}
                  onClick={() => {
                    if(title.length != 0 && organization.length !=0 && locationcity.length !=0 && start.length!=0 && description.length!=0 ){
                    let newArr = [...data.trainingArr];
                    let newObj = {
                      title: title,
                      
                      organization: organization,
                      locationcity: locationcity,
                      start: start,
                    end: !current ? end: "current",
                      description: description
                    };
  
                    newArr.push(newObj);
                    setData({...data,trainingArr:newArr});}
                    else{
                      return null
                    }
                  }}
                >
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Modal>
        <Grid item lg={3}/>
        <Grid item xs={6} lg={5} md={9} sm={9} display="flex" marginTop='1.5%'>
          <Typography fontFamily="poppins" marginTop="1%" onClick={(e) => hc(e, index - 1)} sx={{cursor: 'pointer'}}>
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
            Previous
          </Typography>
        </Grid>
        <Grid item xs={6} lg={4} md={3} sm={3} marginTop='2%'>
          <Typography fontFamily="poppins" 
          // onClick={(e) => { if(data.trainingArr.length != 0)  hc(e, index + 1)}} 
          sx={{cursor: 'pointer'}}>
            Save & Next
            <IconButton onClick={onNext}>
              <ArrowForwardIcon />
            </IconButton>
          </Typography>
        </Grid>
      </Grid>
    );
  }
  