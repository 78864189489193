import React,{useRef} from "react";
import { useReactToPrint } from "react-to-print";
import { Grid, Box, Typography, Card, Divider, Avatar, Button} from "@mui/material";
import priyaimg from "./images/priyaimg.JPG";

export default function Temp2({data}) {
  const componentRef = useRef();
	const handlePrint = useReactToPrint({
	  content: () => componentRef.current,
	});
  
  return (
    <>
    <Grid conatiner ref={componentRef}  sx={{display:'flex',justifyContent:'center'}} >
     
      <Grid item container xs={12} lg={10}>
        <Box  border="1px solid black" margin={'2%'} >

          <Grid container padding="2%" xs={12} lg={12}>
            <Grid item container xs={4} backgroundColor='#062c30' padding='1%'>
              <BasicInfo
                image={data.imageUrl} first={data.fname} last={data.lname} headline={data.headline}
                house='463' street='sector 38' locality='sec 38a' city={data.city} state={data.city} country={data.country} email={data.email}
                />
              <SkillsFinal skill={data.SkillArr}/>
              <LanguageFinal lang={data.languageArr}/>
              <HobbiesFinal hob={data.hobbies}/>
            </Grid>
            <Grid item xs={0.5} />
            <Grid item container xs={7.5}>
              <Grid item xs={12}>
                <Summary summary={data.summary} />
              </Grid>
              <Grid item xs={12}>
                <ExperienceFinal exp={data.workArray}/>
              </Grid>
              <Grid item xs={12}>
                <TrainingFinal train={data.trainingArr}/>
              </Grid>
              <Grid>
                <EducationFinal edu={data.educationArr}/>
              </Grid>
            </Grid>

          </Grid>

        </Box>
      </Grid>
    </Grid>
    <Grid sx={{display:'flex',justifyContent:'center'}}>
		<Button id="download" variant="contained" onClick={handlePrint}  sx={{marginTop:'20px',margin:'auto'}}>Download PDF</Button>	</Grid>
    </>
  );
}

//Basic Info//

export function BasicInfo(props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Avatar
          src={props.image}
          alt="image"
          style={{ height: "150px", width: "150px", margin: '8% 12%' }}
        />
      </Grid>

      <Grid item xs={12} margin='2%'>
        <Typography 	fontSize='18px' fontWeight='bold' fontFamily='Nunito' color='#d1d1d1'>{props.first} {props.last}</Typography>
        <Typography 	fontSize='16px' fontWeight='bold' fontFamily='Nunito' color='#d1d1d1' marginTop='1%'>
          {props.headline}
        </Typography>
        <Typography 	fontSize='16px' fontWeight='bold' fontFamily='Nunito' color='#d1d1d1' marginTop='1%'>{props.contact}</Typography>
        <Typography 	fontSize='16px' fontWeight='bold' fontFamily='Nunito' color='#d1d1d1' marginTop='1%'>{props.house}, {props.street}, {props.locality}</Typography>
        <Typography 	fontSize='16px' fontWeight='bold' fontFamily='Nunito' color='#d1d1d1' marginTop='1%'>{props.city}, {props.state}, {props.country}</Typography>
        <Typography 	fontSize='16px' fontWeight='bold' fontFamily='Nunito' color='#d1d1d1' marginTop='1%'>{props.email}</Typography>
      </Grid>
    </Grid>

   
  )
}


//Summary section//

export function Summary(props) {

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
     
          backgroundColor="#c8eadf"
          textAlign="center"
          width="100%"
          fontSize='18px' fontWeight='bold' fontFamily='Nunito' color='black'
        >
          <b>SUMMARY</b>
        </Typography>
      </Grid>

      <Grid item xs={11.5}>
        <Typography fontSize='16px' fontWeight='bold' fontFamily='Nunito' marginTop='2%' marginBottom='2%'>
          {props.summary}
        </Typography>
      </Grid>

    </Grid>
  );
}

//Experience section//

export function ExperienceFinal({ exp }) {
  // let arr = []
  // let c1 = {
  //   company: 'Crest Bell',
  //   start: 'Nov',
  //   end: 'Present',
  //   designation: 'Software Engineer',
  //   emp: 'Permanent',
  //   location: 'Chandigarh'
  // };

  // for (let i = 0; i < 1; i++) {
  //   arr.push(c1);
  // }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
       
          backgroundColor="#c8eadf"
          textAlign="center"
          width="100%"
          fontSize='18px' fontWeight='bold' fontFamily='Nunito' color='black'
        >
          <b>WORK EXPERIENCE</b>
        </Typography>
      </Grid>
      {exp?.map((val, index) => {
        return (
          <Experience key={index} company={val.company} start={val.start} end={val.end} designation={val.designation} emp={val.employmentType} location={val.location} />
        );
      })}
    </Grid>
  )
}

export function Experience(props) {
  return (
    <Grid container>

      <Grid item xs={7}>
        <Typography
        fontSize='16px' fontWeight='bold' fontFamily='Nunito' marginTop='2%' 
        >
          <b>{props.company}</b>
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Typography
         fontSize='16px' fontWeight='bold' fontFamily='Nunito' marginTop='2%' 
        >
          <i>{props.start} to {props.end}</i>
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography
           fontSize='16px' fontWeight='bold' fontFamily='Nunito'  
        >
          <b>{props.designation}</b>
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Typography
          fontSize='16px' fontWeight='bold' fontFamily='Nunito' 
        >
          <i>{props.emp}</i>
        </Typography>
      </Grid>

      <Grid item xs={12} >
        <Typography
          fontSize='16px' fontWeight='bold' fontFamily='Nunito' 
        >
          {props.location}
        </Typography>
      </Grid>
      <Grid item xs={12} margin='1%' marginBottom='2%'>
        <Divider sx={{ width: '100%' }} />
      </Grid>
    </Grid>
  );
}

//Training and Certificates//

export function TrainingFinal({ train }) {
  // let arr = []
  // let c1 = {
  //   title: 'Raspberry pie with python',
  //   start: 'June 2019',
  //   end: 'August 2019',
  //   org: 'NIELET',
  //   loc: 'Mohali',
  //   desc: 'Build crowd management system with contour plotting using raspberry pie and python.'
  // };
  // let c2 = {
  //   title: 'Arduino with C programming',
  //   start: 'June 2018',
  //   end: 'August 2018',
  //   org: 'NETMAX',
  //   loc: 'Chandigarh',
  //   desc: 'Build bluetooth controlled car using arduino uno.'
  // };

  // for (let i = 0; i < 1; i++) {
  //   arr.push(c1);
  //   arr.push(c2);
  // }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
        
          backgroundColor="#c8eadf"
          textAlign="center"
          width="100%"
          fontSize='18px' fontWeight='bold' fontFamily='Nunito' color="black"
        >
          <b>TRAINING AND CERTIFICATES</b>
        </Typography>
      </Grid>
      {train?.map((val, index) => {
        return (
          <Training key={index} title={val.title} start={val.start} end={val.end} org={val.organization} loc={val.locationcity} desc={val.description} />
        );
      })}
    </Grid>
  )
}

export function Training(props) {
  return (
    <Grid container>

      <Grid item xs={7}>
        <Typography
           fontSize='16px' fontWeight='bold' fontFamily='Nunito' marginTop='2%'
        >
          <b>{props.title}</b>
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Typography
          fontSize='16px' fontWeight='bold' fontFamily='Nunito' marginTop='2%'
        >
          <i>{props.start} to {props.end}</i>
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography
         fontSize='16px' fontWeight='bold' fontFamily='Nunito' 
        >
          <b>{props.org}</b>
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Typography
         fontSize='16px' fontWeight='bold' fontFamily='Nunito' 
        >
          <i>{props.loc}</i>
        </Typography>
      </Grid>

      <Grid item xs={11}>
        <Typography
          fontSize='16px' fontWeight='bold' fontFamily='Nunito' 
        >
          {props.desc}
        </Typography>
      </Grid>
      <Grid item xs={12} marginTop='1%'>
        <Divider sx={{ width: '100%' }} />
      </Grid>
    </Grid>
  )
}


//Skills section//

export function SkillsFinal({ skill }) {
  // let arr = []
  // let c1 = {
  //   skill: 'HTML',
  //   level: 'Advanced'
  // };

  // let c2 = {
  //   skill: 'Javascript',
  //   level: 'Intermediate'
  // }

  // let c3 = {
  //   skill: 'React',
  //   level: 'Intermediate'
  // }

  // for (let i = 0; i < 1; i++) {
  //   arr.push(c1);
  //   arr.push(c2);
  //   arr.push(c3);
  // }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          
          backgroundColor="#c8eadf"
          textAlign="center"
          width="100%"
          fontSize='18px' fontWeight='bold' fontFamily='Nunito' color='black'
        >
          <b>SKILLS</b>
        </Typography>
      </Grid>
      {skill?.map((val, index) => {
        return (
          <Skills key={index} skill={val.title} level={val.Emptyp} />
        );
      })}
    </Grid>
  )
}

export function Skills(props) {
  return (
    <Grid container>

      <Grid item xs={7}>
        <Typography
              fontSize='16px' fontWeight='bold' fontFamily='Nunito' marginTop='2%' color='#d1d1d1'
        >
          <b>{props.skill}</b>
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Typography
              fontSize='16px' fontWeight='bold' fontFamily='Nunito' marginTop='2%' color='#d1d1d1'
        >
          <i>{props.level}</i>
        </Typography>
      </Grid>

    </Grid>
  )
}

//Education section//

export function EducationFinal({ edu }) {
  // let arr = []
  // let c1 = {
  //   course: 'B.Tech',
  //   start: '2016',
  //   end: '2020',
  //   special: 'Electronics and Communication',
  //   grade: '7.14',
  //   institute: 'UIET'
  // };

  // for (let i = 0; i < 1; i++) {
  //   arr.push(c1);
  // }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
     
          backgroundColor="#c8eadf"
          textAlign="center"
          width="100%"
          fontSize='18px' fontWeight='bold' fontFamily='Nunito' color='black'
        >
          <b>EDUCATION</b>
        </Typography>
      </Grid>
      {edu?.map((val, index) => {
        return (
          <Education key={index} course={val.course} start={val.start} end={val.end} special={val.special} grade={val.grade} institute={val.inst} />
        );
      })}
    </Grid>
  )
}

export function Education(props) {
  return (
    <Grid container>

      <Grid item xs={7}>
        <Typography
        fontSize='16px' fontWeight='bold' fontFamily='Nunito' marginTop='2%' 
        >
          <b>{props.course}</b>
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Typography
         fontSize='16px' fontWeight='bold' fontFamily='Nunito' marginTop='2%' 
        >
          <i>{props.start} to {props.end}</i>
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography
         fontSize='16px' fontWeight='bold' fontFamily='Nunito' 
        >
          <b>{props.special}</b>
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Typography
          fontSize='16px' fontWeight='bold' fontFamily='Nunito' 
        >
          <i>{props.grade}</i>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          fontSize='16px' fontWeight='bold' fontFamily='Nunito' 
        >
          {props.institute}
        </Typography>
      </Grid>
      <Grid item xs={12} margin='1%'>
        <Divider sx={{ width: '100%' }} />
      </Grid>
    </Grid>
  )
}

//Language Section//

export function LanguageFinal({ lang }) {
  // let arr = []
  // let c1 = {
  //   language: 'English',
  //   level: 'Intermediate'

  // };
  // let c2 = {
  //   language: 'Hindi',
  //   level: 'Intermediate'
  // }

  // for (let i = 0; i < 1; i++) {
  //   arr.push(c1);
  //   arr.push(c2);
  // }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
        
          backgroundColor="#c8eadf"
          textAlign="center"
          width="100%"
          fontSize='18px' fontWeight='bold' fontFamily='Nunito' color='black'
        >
          <b>LANGUAGE</b>
        </Typography>
      </Grid>
      {lang?.map((val, index) => {
        return (
          <Language key={index} language={val.title} level={val.Emptyp} />
        );
      })}
    </Grid>
  )
}

export function Language(props) {
  return (
    <Grid container>

      <Grid item xs={7}>
        <Typography
        fontSize='16px' fontWeight='bold' fontFamily='Nunito'  color='#d1d1d1'
        >
          <b>{props.language}</b>
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Typography
         fontSize='16px' fontWeight='bold' fontFamily='Nunito'  color='#d1d1d1'
        >
          <i>{props.level}</i>
        </Typography>
      </Grid>

    </Grid>
  )
}

//Hobbies Section//

export function HobbiesFinal({ hob }) {
  // let arr = []
  // let c1 = {
  //   hobby: 'Sports',

  // };
  // let c2 = {
  //   hobby: 'Sketching',
  // };

  // for (let i = 0; i < 1; i++) {
  //   arr.push(c1);
  //   arr.push(c2);
  // }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
       
          backgroundColor="#c8eadf"
          textAlign="center"
          width="100%"
          fontSize='18px' fontWeight='bold' fontFamily='Nunito' color='black'
        >
          <b>HOBBIES</b>
        </Typography>
      </Grid>
      {hob?.map((val, index) => {
        return (
          <Hobbies key={index} hobby={val.title} />
        );
      })}
    </Grid>
  )
}

export function Hobbies(props) {
  return (
    <Grid container>

      <Grid item xs={7}>
        <Typography
        fontSize='16px' fontWeight='bold' fontFamily='Nunito'  color='#d1d1d1'
        >
          <b>{props.hobby}</b>
        </Typography>
      </Grid>


    </Grid>
  )
}
