import {
    Grid,
    Box,
    Typography,
    IconButton,
    Button,
    Modal,
    FormControlLabel,
    Checkbox,
    TextField,
    FormLabel,
    Input,
    InputLabel,
    Select,
    MenuItem,
  } from "@mui/material";
  import AddIcon from "@mui/icons-material/Add";
  
  import React, { useContext, useState } from "react";
  import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
  import AdapterDateFns from "@mui/lab/AdapterDateFns";
  import LocalizationProvider from "@mui/lab/LocalizationProvider";
  import CloseIcon from "@mui/icons-material/Close";
  import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import { ResumeContext } from 'ResumeContext';
  import ClearIcon from '@mui/icons-material/Clear';
  import axios from 'axios';
  
  
  const style = {
    bgcolor: "background.paper",
  
    boxShadow: 24,
    p: 4,
  
    marginTop: "30px",
  };
  
  export default function Skills({ hc, index }) {
    // const [arr, setArr] = useState([
    //   {
    //     title: "English",
    //     Emptyp: "Intermediate",
  
    //   },
    // ]);
  
    // const [languageArr, setLanguageArr] = useContext(ResumeContext)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [start, setstart] = useState("");
    const { data, setData } = useContext(ResumeContext)
    const onNext = () => {
      
      axios({
        method: "post",
        url: "https://cms.keewesolutions.com/opencase/put",
        data: {
          ...data
        }
      }).then((response) => {
        hc("", index + 1);
      })
    }
    const handleChange = (newValue) => {
      setstart(
        newValue.getDate() +
        "/" +
        newValue.getMonth() +
        "/" +
        newValue.getFullYear()
      );
    };
    const [end, setend] = useState("");
  
  
    const [title, settitle] = useState("");
    const [Emptyp, setEmptyp] = useState("");
  
  
    return (
      <Grid container>
        <Grid item xs={12} marginBottom="1%">
          <Typography
            variant="h5"
            fontFamily="poppins"
            color="#25d366"
            marginBottom="2%"
            fontWeight="bold"
          >
            Skills
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom="3%">
          <Typography variant="h7" fontFamily="poppins" color="#4a4a4a">
            Add your technical skills. 
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ marginTop: "20px" }}>
          {data.SkillArr?.map((item, index) => {
            return (
              <Box sx={{ border: "1px solid #177acc", width: "90%", marginBottom: '2%', paddingBottom: '2%' }}>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "10px",
                  }}
                >
                  <Typography sx={{ marginTop: "7px", fontFamily: "poppins" }}>
                    <b>{item.title}</b>
                  </Typography>
                  <IconButton
                    onClick={() => {
                      let newarr = [...data.SkillArr];
                      newarr.splice(index, 1);
                      setData({ ...data, SkillArr: newarr });
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid marginLeft={"10px"}>
  
                  <Typography fontFamily="poppins">
                    Proficiency Level:&nbsp;<b>{item.Emptyp}</b>,
                  </Typography>
  
                </Grid>
  
              </Box>
            );
          })}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={12} lg={6} sx={{ marginTop: "20px", marginBottom: '2%' }}>
          <Box sx={{ border: "1px solid #177acc", width: "90%" }}>
            <Grid sx={{ display: "flex", cursor: 'pointer' }} 
            onClick={handleOpen}>
              <Typography sx={{ fontFamily: "poppins" }}>
                <IconButton sx={{ color: "#177acc" }}>
                  <AddIcon />
                </IconButton>
                Add Skills 
              </Typography>
            </Grid>
          </Box>
        </Grid>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowX: "scroll", overflowY: "scroll" }}
        >
          <Grid container>
            <Grid item xs={1} lg={3.5} />
            <Grid item xs={10} lg={5}>
              <Box sx={style}>
                <Grid sx={{ justifyContent: "space-between", display: "flex" }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ fontFamily: "poppins" }}
                  >
                    Add SKills
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
  
                <Grid marginTop={"10px"}>
                  <FormLabel>
                    <InputLabel sx={{ fontFamily: "poppins" }}>
                      Title:
                      <br />
                      <TextField
                        type="text"
                        label="Eg: HTML or React JS"
                        sx={{ marginTop: "10px", width: "100%" }}
                        value={title}
                        onChange={(e) => settitle(e.target.value)}
                      />
                    </InputLabel>
                    <InputLabel sx={{ marginTop: "10px", fontFamily: "poppins" }}>
                      Proficiency Level:
                      <br />
                      <Select
                        sx={{ width: "100%", marginTop: "10px" }}
                        value={Emptyp}
                        onChange={(e) => setEmptyp(e.target.value)}
                        placeholder="Select"
                      >
                        <MenuItem value="Beginner">
                          Beginner
                        </MenuItem>
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Advanced">Advanced</MenuItem>
  
                      </Select>
                    </InputLabel>
                  </FormLabel>
                </Grid>
                <Button
                  variant="contained"
                  style={{
                    marginTop: "10px",
                    borderRadius: "40px",
                    width: "100px",
                  }}
                  onClick={() => {
                    if (title.length != 0 && Emptyp.length != 0) {
                      let newArr = [...data.SkillArr];
                      let newObj = {
                        title: title,
                        Emptyp: Emptyp,
  
                      };
  
                      newArr.push(newObj);
                      setData({ ...data, SkillArr: newArr });
                    }
                    else {
                      return null
                    }
                  }}
                >
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Modal>
        <Grid item lg={3} />
        <Grid item xs={6} lg={5} md={9} sm={9} display="flex" marginTop='1.5%'>
          <Typography fontFamily="poppins" marginTop="1%" onClick={(e) => hc(e, index - 1)} sx={{ cursor: 'pointer' }}>
            <IconButton>
              <ArrowBackIcon />
            </IconButton>
            Previous
          </Typography>
        </Grid>
        <Grid item xs={6} lg={4} md={3} sm={3} marginTop='2%'>
          <Typography fontFamily="poppins"
            // onClick={(e) =>{ if(languageArr.length != 0)  hc(e, index + 1)}} 
            sx={{ cursor: 'pointer' }}>
            Save & Next
            <IconButton onClick={onNext}>
              <ArrowForwardIcon />
            </IconButton>
          </Typography>
        </Grid>
      </Grid>
    );
  }
  