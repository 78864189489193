import Footer from '../Deepak/Footer';
import { Grid } from '@mui/material';
import Nav from '../Deepak/Nav';
import SocialMedia from '../Rohan/SocialMedia';
import Perks from '../Vikram/Perks';
import Stripe from '../Rohan/Stripe';
import Similarjobs from '../Deepak/Similarjobs';
import Description from '../NehaBhagat1/Description';
import { useLocation } from 'react-router-dom'
import { useEffect,useState } from 'react';



export default function CrestBelldescription() {
    
    // const data=(location.state.item);
    const location = useLocation()
     const  jobdetails =location.state.jobdetails
     const companyId = location.state.companyId
     const company = location.state.company
    const jobid = location.state.jobid
      
    return (
        <Grid>
            {/* <Nav/> */}
            <SocialMedia jd={jobdetails} cd={company} id={companyId} jid={jobid} />
            {/* jobTitle={data.jobTitle}
            location={data.location}
            employmentType={data.employmentType} */}
            <Description jd={jobdetails} cd={company} id={companyId} jid={jobid}/>
            {/* description={data.jobDescription}
            salary={data.salary} */}
            {/* <Perks/> */}
            <Stripe jd={jobdetails} cd={company} id={companyId} jid={jobid}/>
            {/* <Similarjobs/> */}
            
        </Grid>
    )
}